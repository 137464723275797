import inArray from './inArray';

export default function (html, imgStr) {
    var convertFunc, div = document.createElement("div");

    convertFunc = function(em) {
        var child = em.firstChild, textNode;
        while (child) {
            // process this node if it is element node
            if (child.nodeType == 1) {
                if (child.tagName == "IMG") {
                    textNode = document.createTextNode(imgStr);
                    child.parentNode.replaceChild(textNode, child);
                    child = textNode;
                }
                else if (child.tagName == "HR") {
                    textNode = document.createTextNode("\n-------------------------------\n");
                    child.parentNode.replaceChild(textNode, child);
                    child = textNode;
                }
                else {
                    convertFunc(child);
                    // finished this child node? if it is something look like a block element,
                    // add line feed as last node of child
                    if (inArray(child.tagName, ["P", "DIV", "TR", "BR", "H", "UL", "OL"])) {
                        textNode = document.createTextNode("\n");
                        child.appendChild(textNode);
                    } else if (inArray(child.tagName, ["TD", "SPAN",])) {
                        textNode = document.createTextNode("");
                        child.appendChild(textNode);
                    }
                }
            }
            child = child.nextSibling;
        }
    };
    
    div.innerHTML = html;
    convertFunc(div);
    return div.textContent || div.innerText || "";
};


