
/**
 *  animation class
 */

import callParentConstructor from './callParentConstructor';
import object from './object';
import customEvent from './customEvent';
import inherits from './inherits';
import util from './util';
import dom from './dom/index';

var animation = function (props) {
    callParentConstructor(object, this);

	if (props) {
		var tp = this.properties, UD = "undefined";
        tp["numRun"] = (typeof props["numRun"] == UD) ? 10 : props["numRun"];
        tp["durationPerRun"] = (typeof props["durationPerRun"] == UD) ? 200 : props["durationPerRun"]; // ms
        tp["animation"] = (typeof props["animation"] == UD) ? [] : props["animation"];
            // eg.
            // [
            //	{type:"relX", start:-30, end:0, easing:"easein"},		// start and end are pixel. left style must be specified
            //	{type:"relY", start:0, end:-30, easing:"easein"},		// start and end are pixel. top style must be specified
            //	{type:"relWidth", start:0.5, end:1, easing:"easein"},	// start and end are 0 to 1, relatively to original width. width style must be specified
            //	{type:"relHeight", start:0.5, end:1, easing:"easein"},	// start and end are 0 to 1, relatively to original height. height style must be specified
            //	{type:"x", start:0, end:100, easing:"easein"},
            //	{type:"y", start:0, end:100, easing:"easeout"},
            //	{type:"width", start:0, end:100, easing:"easeboth"},
            //	{type:"height", start:0, end:100, easing:"linear"},
            //	{type:"alpha", start:0, end:1, easing:"easein"}			// start and end are 0 to 1
            //	{type:"color", start:[255,0,0], end:[0,255,0], easing:"easein"}
            //	{type:"backgroundColor", start:[255,0,0], end:[0,255,0], easing:"easein"}
            //	{type:"borderColor", start:[255,0,0], end:[0,255,0], easing:"easein"}
            // ]
        tp["curRun"] = 0;
        tp["loop"] = (typeof props["loop"] == UD) ? false : props["loop"];

        // p: progress (float) 0 - 1
        // e: easing (string) linear, easein, easeout, easeboth, cycle
        this.getProgressValue = function (p, e) {
        	var pi=Math.PI;
            switch (e) {
            case "easein":
                return (1 - Math.sin(pi / 2 * (1 - p)));
            case "easeout":
                return  Math.sin(pi / 2 * p);
            case "easeboth":
                if (p < 0.5) {
                    return (1 - Math.sin(pi / 2 * (1 - p * 2))) * 0.5;
                }
                else {
                    return 0.5 + Math.sin(pi / 2 * (p - 0.5) * 2) * 0.5;
                }
            case "cycle":
                if (p < 0.5) {
                    return p * 2.0;
                }
                else {
                    return (1.0 - p) * 2.0;
                }
            }
            return p;
        }

        this._elements = [];
        this._elementValues = null;
        this._timerId = -1;
        this._startTime = null;

		if (customEvent) {
        	this.completionEvent = new customEvent({});
        }
	}
}
inherits(object, animation);

(function (){
	var tp = animation.prototype;
	tp.start = function (elem) {
		var i, e;
		this.stop();
		this._elements = PS$(elem);
		if (!util.isArray(this._elements)) {
			this._elements = [this._elements];
		}
		// get some starting values for relX, relY, relWidth, relHeight animation
		this._elementValues = [];
		for (i = 0; i < this._elements.length; i ++) {
			e = this._elements[i];
			this._elementValues[i] = e ? {x:e.style.left, y:e.style.top, w:e.style.width, h:e.style.height} : null;
		}
		this._startTime = this.getTime();
		this.properties["curRun"] = 0;
		this.animate();
	};

	tp.stop = function () {
		this.properties["curRun"] = this.properties["numRun"] + 1;
		if (this._timerId >= 0) {
			clearTimeout(this._timerId);
			this._timerId = -1;
		}
	};

	tp.getTime = function () {
		var dateObj = new Date();
		return dateObj.getTime();
	}

	tp.animate = function () {
		// ended already. just quit.
		var tp = this.properties,
			anim = tp["animation"],
			i, j, em, ems, evs;

		for (j = 0; j < this._elements.length; j ++) {
			em = this._elements[j];
			evs = this._elementValues[j];
			if (em) {
				ems = em.style;
				if (tp["curRun"] > tp["numRun"]) {
					return;
				}

				this._timerId = -1;
				for (i = 0; i < anim.length; i ++) {
					var V = this.getProgressValue(tp["curRun"] / tp["numRun"], anim[i].easing), ov;
			        if (util.isArray(anim[i].start)) { // for color animation
			            var temp = [], j;
			            for (j = 0; j < anim[i].start.length; j ++) {
			                temp[j] = parseInt(V * parseFloat(anim[i].end[j] - anim[i].start[j]) + anim[i].start[j]);
			            }
			            V = "rgb(" + temp.join(",") + ")";
			        }
			        else {
			    		V = V * parseFloat(anim[i].end - anim[i].start) + anim[i].start;
			        }
					switch (anim[i].type) {
					case "x":
						ems.left = parseInt(V) + "px";
						break;
					case "y":
						ems.top = parseInt(V) + "px";
						break;
					case "width":
						ems.width = parseInt(V) + "px";
						break;
					case "height":
						ems.height = parseInt(V) + "px";
						break;
					case "scrollLeft":
						em.scrollLeft = parseInt(V);
						break;
					case "scrollTop":
						em.scrollTop = parseInt(V);
						break;
					case "relX":
						ov = evs.x;
						ems.left = (parseInt(ov.substring(0, ov.length - 2)) + parseInt(V)) + "px";
						break;
					case "relY":
						ov = evs.y;
						ems.top = (parseInt(ov.substring(0, ov.length - 2)) + parseInt(V)) + "px";
						break;
					case "relWidth":
						ov = evs.w;
						ems.width = (parseInt(ov.substring(0, ov.length - 2) * (1 + V))) + "px";
						break;
					case "relHeight":
						ov = evs.h;
						ems.height = (parseInt(ov.substring(0, ov.length - 2) * (1 + V))) + "px";
						break;
					case "alpha":
						dom.setStyle(em, "opacity", V);
						break;
					case "backgroundColor":
					case "color":
					case "borderColor":
						dom.setStyle(em, anim[i].type, V);
						break;
					}
				}
			}
		}

		tp["curRun"] ++;
	    if (tp["curRun"] > tp["numRun"] && tp["loop"]) {
	        tp["curRun"] = 0;
	    }

		if (tp["curRun"] <= tp["numRun"]) {
			var o = this;
			this._timerId = setTimeout(
				function () {o.animate();},
				Math.max(1, tp["curRun"] * tp["durationPerRun"] - this.getTime() + this._startTime));
		}
		else if (this.completionEvent) {
	        this.completionEvent.fireEvent();
		}
	};

	tp.isPlaying = function () {
	    return (this._timerId >= 0);
	};

	// for old code compatibility
	tp.getElement = function () {
		return this._elements[0];
	};

	tp.getElements = function () {
		return this._elements;
	};

})();

export default animation;