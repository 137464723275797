import isArray from './isArray';

export default function(args) {
    var params = [], i, ENC = encodeURIComponent, temp;
    if (isArray(args)) {
        for(i = 0; i < args.length; i++) {
            if (isArray(args[i]) && args[i].length > 0) {
                temp = ENC(args[i][0]);
                if (args[i][1]) {
                    temp = temp + "=" + ENC(args[i][1]);
                }
                params.push(temp);
            }
            else if (args[i].key) {
                temp = ENC(args[i].key);
                if (args[i].value !== undefined) {
                    temp = temp + "=" + ENC(args[i].value);
                }
                params.push(temp);
            }
        }
    }
    else {
        for(i in args) {
            params.push(ENC(i) + "=" + ENC(args[i]));
        }
    }
    return params.join("&");
};