export default function(callback, arg) {
    if (typeof callback == "object") {
        var func = callback.callback,
            scope = callback.context ? callback.context : callback.scope;
        if (func) {
            if (scope) {
                return func.call(scope, callback.data, arg);
            }
            else {
                return func(callback.data, arg);
            }
        }
    }
};