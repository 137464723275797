/**
 * Adds soft-hyphens to a string over a certain length
 *
 * str - The string you wish soft-hyphenated
 * args - optional arguments list
 *
 *  maxLength - the maximum length of a string before the default regexp hyphenation kicks in (default: 10)
 *  htmlAware - whether you want to take into account HTML tags and not hyphenate them (default: true)
 *  regeExp   - customize the regexp string rule used to match what strings are broken up (default: /([^\s]{maxLength-1,maxLength})/
 *  softHyphen - the character inserted to implement soft-hyphenation (default: &shy;)
 */

import dom from '../dom/index';
import htmlSpecialChars from './htmlSpecialChars';

const softHyphen = (function() {
    var ToDomElements = function(str) {
        var div = global.document.createElement('div');
        try {
            try {
                dom.safeSetHtml(div, str);
            } catch(ei) {
                div.innerHTML = str;
            }
        } catch(e) {
        }
        return div;
    },
    AddSoftHyphens = function(text, args) {
        var replace = "$1"+escape(args.softHyphen),
            trailingRegexp = new RegExp(escape(args.softHyphen)+"$"),
            newText = (text)? text.replace(args.regExp,replace) : "";

        return newText.replace(trailingRegexp,'');
    },
    ProcessNode = function(node, args) {
        var resultNode = node.cloneNode(false),
            textNode = null,
            childNode = null,
            childNodes = null,
            text = null,
            processedResult = null;

        try {
            try {
                dom.safeSetHtml(resultNode, '');
            } catch(ei) {
                resultNode.innerHTML = '';
            }
        } catch(e) {
        }

        try {
            childNodes = node.childNodes;
        } catch(e) {
        }

        for(var n = 0; n < childNodes.length; n++) {
            childNode = childNodes[n];

            // This is text - process it
            if (typeof(childNode.tagName) == "undefined") {
                text = AddSoftHyphens(childNode.nodeValue, args);

                if (text.length > 0) {
                    textNode = document.createTextNode(text);
                    resultNode.appendChild(textNode);
                }
            }
            // This is another element
            else {
                processedResult = ProcessNode(childNode, args);
                resultNode.appendChild(processedResult);
            }
        }
        return resultNode;
    };
    return function (str, args) {
        args = (typeof(args) != "object")? {} : args;
        args.maxLength = (typeof(args.maxLength) != "number")? 10 : ((args.maxLength > 0)? args.maxLength : 10);
        args.htmlAware = (typeof(args.htmlAware) != "boolean")? true : args.htmlAware;
        args.regExp = (typeof(args.regExp) == "undefined")? new RegExp("([^\\s\\uD800-\\uDBFF\\uDC00-\\uDFFF]{"+(args.maxLength)+"})","g") : args.regExp; // Updated regexp to not clobber emojis
        args.softHyphen = (typeof(args.softHyphen) == "undefined")? "&shy;" : args.softHyphen;

        try {
            if (args.htmlAware) {
                var dom = ToDomElements(str),
                    node = ProcessNode(dom, args);
                return unescape(node.innerHTML);
            }
            else {
                return unescape(AddSoftHyphens(htmlSpecialChars(str), args));
            }
        } catch(e) {
            return str;
        }
    };
})();

export default softHyphen;