export default function(dateObj) {
    if(!dateObj || typeof (dateObj) != 'object') {
        return null;
    }
    var year = dateObj.getFullYear(),
        month = ('0'+(dateObj.getMonth()+1)).slice(-2), // date obj month starts at 0, add trailing 0 if only one digit
        day = ('0'+dateObj.getDate()).slice(-2),
        hour = ('0'+dateObj.getHours()).slice(-2),
        min = ('0'+dateObj.getMinutes()).slice(-2),
        sec = ('0'+dateObj.getSeconds()).slice(-2),
        offset = parseFloat(dateObj.getTimezoneOffset() / 60).toFixed(2), // offset is given in minutes
        sign = offset < 0 ? "+" : "-";

    // calculate hour and minutes offset from decimal to minutes
    offset = (offset + "").split(".");
    var offsetHour = ('0'+Math.abs(offset[0])).slice(-2);
    var offsetMinutes = offset[1] == undefined ? 0 : 60 / (100/offset[1]);

    // add trailing 0 for one digit result
    offsetMinutes = ('0'+offsetMinutes).slice(-2); 

    return year+'-'+month+'-'+day+'T'+hour+':'+min+':'+sec+sign+offsetHour+':'+offsetMinutes;
};