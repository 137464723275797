// copied and modified from lodash

import isObject from './isObject';
import _debounce from './_debounce';

var _throttle = function(func, wait, options) {
    var leading = true,
    trailing = true;

    if (isObject(options)) {
        leading = 'leading' in options ? !!options.leading : leading;
        trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    return _debounce(func, wait, {
        'leading': leading,
        'maxWait': wait,
        'trailing': trailing
    });
};

export default _throttle;