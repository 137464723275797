// set session cookie: setCookie(name, value);
// delete cookie: setCookie(name, "", -3650);
// set a persistent cookie with 10yrs expire time: setCookie(name, value, 3650);
// set a persistent cookie with specific domain and path: setCookie(name, value, 3650, "/", ".pollstream.com");

export default function (cname, cvalue, exdays, path, domain) {
    var expires = "", c;

    if (!path) path = "/";
    if (exdays) {
        exdays = +exdays;//make it a number
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires="+d.toUTCString()+"; ";
    }
    c = cname + "=" + cvalue + "; " + expires + ((domain)? "domain=" + domain + "; " : "") + "path=" + path;
    //console.log(c);
    document.cookie = c;
};