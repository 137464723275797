var uniqueArray = function(array) {
    var i, j,
        a = array.concat();
    for(i=0; i<a.length; ++i) {
        for(j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
};

export default uniqueArray;