

// this shouldn't be here, but we don't have that generic "pslib_plugins" file
// and I don't care to create an additional file right now

/*
	this one handles single textarea resizing.
	to use:

		growTextarea(textarea, 2, 10, "direct");

	algo - either copy or direct, this specifies the algorithm to use when doing resize operations
*/

import attr from './attr';
import customEvent from './customEvent';
import event from './event/index';
import dom from './dom/index';

const growTextarea = function(el, minRows, maxRows, algo) {
    var that = this;
    // We support "direct" or "copy" algorithms right now
    algo = (algo !== "copy")? "direct" : algo;

    minRows = minRows || attr(el, "data-min-rows") || undefined;
    maxRows = maxRows || attr(el, "data-max-rows") || undefined;

    el.onResize = new customEvent();
    this.resizeEvent = el.onResize;

    this.resize = function() {
        growTextarea.resizeHeight(el, undefined, minRows, maxRows, undefined, algo);
    }

	event.bind(el, "keyup", function(e) {
        that.resize();
	});

    this.resize();
};

/*
	this one handles resizing of textareas under single element.
	textareas require resizing should have classname "ps-grow-textarea" and
	attributes "data-min-rows" and "data-max-rows"

	to use:
		obj = new growTextareas(element);
		obj.resizeEvent.subscribe({
			callback: function(data) {
				// this event fired when any of textareas resized
				..
			}
		});
*/

export const growTextareas = function(el) {
	// update height to all elements in scope
	var customEventInstance = new customEvent();

	this.resizeEvent = customEventInstance;

	this.resize = function(target) {
		growTextarea.resizeHeight(
			target,
			undefined,
			attr(target, "data-min-rows"),
			attr(target, "data-max-rows"),
			customEventInstance);
	};

	this.init = function() {
		var els = PS$$(".ps-grow-textarea", el),
			i,
			target;
		for (i = 0; i < els.length; i ++) {
			target = els[i];
			this.resize(target);
		}
	};

	// resize textareas in scope
	this.init();

	// bind the events
	event.bind(
		el, "keyup",
		function(e) {
			var target = e.target;
			if (dom.hasClass(target, "ps-grow-textarea")) {
				growTextarea.resizeHeight(
					target,
					e,
					attr(target, "data-min-rows"),
					attr(target, "data-max-rows"),
					customEventInstance);
			}
		}
	);

	event.bind(
		el, "mouseup",
		function(e) {
			var target = e.target;
			if (dom.hasClass(target, "ps-grow-textarea")) {
				growTextarea.resizeHeight(
					target,
					undefined,
					attr(target, "data-min-rows"),
					attr(target, "data-max-rows"),
					customEventInstance);
			}
		}
	);
}

/* Allows us to call the function statically if we choose to */
growTextarea.resizeHeight = function(el, e, minRows, maxRows, customEventInstance, algo) {
    // We support "direct" or "copy" algorithms right now
    algo = (algo !== "copy")? "direct" : algo;

    if (!el.PSLIB_growTextarea_updateHeight) {
        minRows = (typeof minRows == "undefined" ? 1 : minRows);
        maxRows = (typeof maxRows == "undefined" ? 5 : maxRows);

		maxRows = Math.max(minRows, maxRows);

        var rowHeight, minHeight, maxHeight,
        paddingTop = parseInt(dom.getComputedStyle(el, "paddingTop")),
        paddingBottom= parseInt(dom.getComputedStyle(el, "paddingBottom")),
        borderTopWidth = parseInt(dom.getComputedStyle(el, "borderTopWidth")),
        borderBottomWidth = parseInt(dom.getComputedStyle(el, "borderBottomWidth")),
        stylingHeight  = 0;

        stylingHeight = (!isNaN(paddingTop))? stylingHeight + paddingTop : stylingHeight;
        stylingHeight = (!isNaN(paddingBottom))? stylingHeight + paddingBottom : stylingHeight;
        stylingHeight = (!isNaN(borderTopWidth))? stylingHeight + borderTopWidth : stylingHeight;
        stylingHeight = (!isNaN(borderBottomWidth))? stylingHeight + borderBottomWidth : stylingHeight;

        // first calculate the height of 1 row
        attr(el, "rows", "1");
        rowHeight = el.clientHeight;
        attr(el, "rows", "2");
        rowHeight = el.clientHeight - rowHeight;
        // Element probably not visible - try using line-height...
        if (rowHeight <= 0) {
            try {
                rowHeight = parseInt(dom.getComputedStyle(el, "lineHeight"));

                // Set something logical - like the bodys lineheight
                if (isNaN(rowHeight) || rowHeight <= 0) {
					rowHeight = parseInt(dom.getComputedStyle(document.body, "lineHeight"));

					// IE8 and 9 may return something like "1.25" for
					// dom.getComputedStyle(document.body, "lineHeight"), not actualy pixel values.
					// so, if something small returned, just use "14" as row height
					if (isNaN(rowHeight) || rowHeight <= 3) {
						rowHeight = 14;
					}
                }
            } catch(e) {
                rowHeight = 14;
            }
        }

        minHeight = (rowHeight * minRows) + stylingHeight;
        maxHeight = (rowHeight * maxRows) + stylingHeight;

        // now set the minimum height
        attr(el, "rows", "");

        dom.setStyle(el, 'height', minHeight + "px");

        if (algo === "direct") {

        // Direct resize algorithm
        // Uses the existing textarea to determine the current scrollHeight
        el.PSLIB_growTextarea_updateHeight = function() {
            var scrollHeight, height, origHeight = parseInt(this.style.height);

            height = minHeight;

            this.style.height = "" + height + "px";
            scrollHeight = this.scrollHeight;

            if (Math.abs(this.clientHeight - scrollHeight) >= 1) {
                height = Math.min(Math.max(scrollHeight, minHeight), maxHeight);
                this.style.height = "" + height + "px";
            }
            this.style.overflow = (scrollHeight > height ? "auto" : "hidden");
            if (height != origHeight) {
				if (typeof(el.onResize) != "undefined") {
					this.onResize.fireEvent(el);
				}
				if (typeof(customEventInstance) != "undefined") {
					customEventInstance.fireEvent({target: el});
				}
            }
        };

        }
        else {

        // Copy resize algorithm
        // Creates a copy of the textarea to determine the current scrollheight
        el.PSLIB_growTextarea_updateHeight = function() {
            var scrollHeight, height, origHeight = parseInt(this.style.height),
            copy = this.cloneNode();
            copy.value = this.value;

            // Absolute positining
            copy.style.position = 'absolute';
            this.parentNode.insertBefore(copy, this);

            height = minHeight;

            copy.style.height = "" + height + "px";
            scrollHeight = copy.scrollHeight;

            this.parentNode.removeChild(copy);

            if (Math.abs(copy.clientHeight - scrollHeight) >= 1) {
                height = Math.min(Math.max(scrollHeight, minHeight), maxHeight);
                this.style.height = "" + height + "px";
            }
            this.style.overflow = (scrollHeight > height ? "auto" : "hidden");

            if (height != origHeight) {
				if (typeof(el.onResize) != "undefined") {
					this.onResize.fireEvent(el);
				}
				if (typeof(customEventInstance) != "undefined") {
					customEventInstance.fireEvent({target: el});
				}
            }
        };

        }
    }

    if (typeof(e) != "undefined") {
		if (e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40 ||
			e.keyCode == 16 || e.keyCode == 17 || e.keyCode == 20) {
			// arrows, shift, ctrl and cap lock keys
			return;
		}
    }
    el.PSLIB_growTextarea_updateHeight.apply(el);
};

export default growTextarea;