export default function(v, a) {
    var r=false;
    for(var i=0;i<a.length;i++){
        if(v == a[i]){
            r=true;
            break;
        }

    }
    return r;
};