// For IE - which doesn't have the array filter function...

var arrayFilter = function(arr, fun /*, thisp */) {
    "use strict";
    var res = [];

    if (arr === void 0 || arr === null) {
        throw new TypeError();
    }

    var t = Object(arr);
    var len = t.length >>> 0;
    if (typeof fun !== "function") {
        throw new TypeError();
    }

    var thisp = arguments[2];
    for (var i = 0; i < len; i++) {
        if (i in t) {
            var val = t[i]; // in case fun mutates this
            if (fun.call(thisp, val, i, t)) {
                res.push(val);
            }
        }
    }

    return res;
};

export default arrayFilter;