// for FF3.5+, Opera10+, Chrome1+, Safari3.2+, IE8+ that support querySelectorAll,
// it will be used instead. Otherwise, internal implementation is used instead but
// it supports limited features. It currently suports
//		part1,part2,part3
//		parts could be any of the followings
//			#ID
//			tag
//			.className
//			tag.className
//			tag[attr]
//			tag[attr=value]
//			tag[name^=value]
//			tag[name$=value]
//			tag[name*=value]
//			tag[name~=value]
//			tag.className[name=value]
//          tag.class1.class2[name1=value1][name2=value2]
// 		value could be NONQUOTED_NONSPACE_STRING or 'string'
//		(seems like FF querySelectorALl accepts quoted string only)
// regex checking is very strict and the internal implementation may return
// incorrect result for incorrect syntax.
//
// todo
//   E > F	Matches any F element that is a child of an element E.
//   E + F	Matches any F element immediately preceded by a sibling element E.

import dom from './dom/index';
import trim from './util/trim';
import mergeArray from './util/mergeArray';
import globalsettings from './globalsettings';

export default function(selector, anchor) {
	var D = global.document,
		str = trim(selector),
		str2,
		idCheck = /^#([\w\d\-\_]+)/,
		classAttrCheck = /^(\w*)((\.[\w\-\_]+)*)((\[([\w\-]+)((\=|\^\=|\$\=|\*\=|\~\=)([^\]']+|'[^\']*'|"[^\"]*"))?\])*)/, // this matches exactly tag.class[attr=value] or tag.class[attr='value']. some components could be optional
		attrCheck = /^\[([\w\-]+)((\=|\^\=|\$\=|\*\=|\~\=)([^\]']+|'[^\']*'|"[^\"]*"))?\]/,
		parts = [],
		curPart = [],
		matches, matches2, matches3,
		e,
		A = ((typeof anchor == "undefined" || !anchor) ? D : anchor),
		i,
		validPart,
		part,
		nodes = [],
		// this one handles "part1 part2 part3", such as "tr td.class span[name='test']"
		func2 = function(parts, anchor) {
			var i, j, matches, tmp, tmp2, nodes = null, newNodes;

			// anchor must not be array
			for (i = 0; i < parts.length; i ++) {
				matches = parts[i];

				// match #id
				if (matches.type == "hash") {
					if (!anchor && i == 0) {
						tmp = D.getElementById(matches.value);
						if (tmp) {
							nodes = [tmp];
						}
					}
				}
				else {
					if (i == 0) {
						nodes = [A];
					}
					newNodes = [];

					for (j = 0; j < nodes.length; j ++) {
						tmp = dom.getElementsByAttributes(
							matches.attrs,
							matches.tag,
							nodes[j],
							matches.classes,
							false
							);

						if (tmp) {
							newNodes = mergeArray(newNodes, tmp);
						}
					}

					nodes = newNodes;
				}

				if (!nodes) {
					return [];
				}

				if (nodes.length == 0) {
					return [];
				}

			}

			return nodes;
		},
		func = function(parts, anchor) {
			var nodes = func2(parts, anchor), nodes2;

			// 9352 & 10959
			if (anchor &&
				parts.length > 1 &&
				parts[0] != "hash" &&
				(parts[0].tag.length == 0 || parts[0].tag.toUpperCase() == anchor.tagName) &&
				(parts[0].classes.length == 0 || dom.hasClass(anchor, parts[0].classes)) &&
				(parts[0].attrs.length == 0 || dom.elementHasAttributes(anchor, parts[0].attrs))
				) {
				parts.shift();
				nodes = mergeArray(nodes, func2(parts, anchor));
			}
			return nodes;
		};

	// FF3.5+, Opera10+, Chrome1+, Safari3.2+, IE11 support querySelectorAll
	// however, this one didnt support *= matching

	if (!globalsettings.useFallbackSelector) {
		if (A && A.querySelectorAll && !selector.match(/\*\=/)) {
			try {
				return A.querySelectorAll(selector);
			}
			catch(e){return[];}
		}
	}

	if (A && !A.getElementsByTagName) {
		if (typeof console != "undefined") {
			if (typeof console.log != "undefined") {
				console.log("Argument root is not an element");
				console.log(A);
				return [];
			}
		}
	}

	// we want to match "part1 part2, part3 part4" into
	// [ [part1, part2], [part3, part4] ]
	// basically we are matching the parts, reduce the input string until whole string consumed
	while (str.length > 0) {
		validPart = true;

		// is it #ID?
	    matches = str.match(idCheck);
	    if (matches) {
			part = {type:"hash", value:matches[1], match:matches[0]};
		}
		else {

			// match "tag.class[attr]"
			matches = str.match(classAttrCheck);
			// unable to match anything to reduce the string. just give up.
			if (!matches) {
			    return [];
			}
			else {
				// we need to match at least something!
				if (matches[0].length == 0) {
					return [];
				}

				part = {type:"part",match:matches[0],tag:matches[1],classes:"",attrs:[]};

				// convert ".class1.class2" to " class1 class2" for getElementByClassName()
				if (matches[2]) {
					part.classes = matches[2].replace(/\./g,' ').replace(/\s+/, ' ');
				}

				// split "[test3='test4'][test5=test6]"
				if (matches[4]) {
					str2 = matches[4];
					while (str2.length) {
						matches2 = str2.match(attrCheck);
						if (matches2) {
							if (matches2[2]) {
								matches3 = matches2[4].match(/^'([^\']*)'|"([^\"]*)"$/);
								part.attrs.push({
									name:matches2[1],
									op:matches2[3],
									value:matches3?(matches3[1]?matches3[1]:matches3[2]):matches2[4]
								});
							}
							else {
								part.attrs.push({
									name:matches2[1],
									op:"has_attribute"
								});
							}
							str2 = str2.substring(matches2[0].length);
						}
						// something wrong?
						else {
							return [];
						}
					}
				}
			}
		}

		if (validPart) {
			// here are the parts we will resolve
			curPart.push(part);
		}
		else {
			// something invalid and wont be able to match anything.
			// eg. let say we have match "table somethinginvalid td"
			// the whole chain will not match anything
			curPart = [];
		}
		// actually reduce the string with what we matches
		str = str.substring(part.match.length);

		// match "," if any
		matches = str.match(/^\s*,\s*/);
		if (matches) {
			// push only if something valid
			if (curPart.length > 0) {
				parts.push(curPart);
			}
			curPart = [];
			str = str.substring(matches[0].length);
		}
		else {
			// match spaces, it is something like "table tr"
			matches = str.match(/^\s+/);
			if (matches) {
				str = str.substring(matches[0].length);
			}
		}
	}

	if (curPart.length > 0) {
		parts.push(curPart);
	}

	// handle "part1, part2, part3"
	for (i = 0; i < parts.length; i ++) {
		nodes = mergeArray(nodes, func(parts[i], anchor));
	}

	return nodes;
}
