/**
 * Converts the newlines properly from plainText -> markdown plaintext w/ proper zero width spaces
 *
 * @param String    Text to convert
 */

 const plainTextToMarkdown = function(text) {
    text = (text || "");
    var SOFT_BREAK_PLACEHOLDER = '\r',
    ZERO_WIDTH_SPACE = '\u200B',
    LINE_BREAKS = /(\r\n|\r|\n)/g;

    text = text.replace(/\\/g , '\\\\'); // backslash

    text = text.replace(/\`/g , '\\`'); // backtick
    text = text.replace(/\*/g , '\\*');    // asterisk
    text = text.replace(/\_/g , '\\_');  // underscore
    text = text.replace(/\}/g , '\\}');  // curly braces
    text = text.replace(/\{/g , '\\{');  // curly braces
    text = text.replace(/\]/g , '\\]');  // square brackets
    text = text.replace(/\[/g , '\\[');  // square brackets
    text = text.replace(/\)/g , '\\)');  // parentheses
    text = text.replace(/\(/g , '\\(');  // parentheses
    text = text.replace(/\#/g , '\\#');   // hash mark
    text = text.replace(/\+/g , '\\+');  // plus sign
    text = text.replace(/\-/g , '\\-');   // minus sign (hyphen)
    text = text.replace(/\./g , '\\.');   // dot
    text = text.replace(/\!/g , '\\!');   // exclamation mark
    text = text.replace(/\</g , '\&lt;');   // html escape
    text = text.replace(/\>/g , '\&gt;');   // html escape

    text = text.replace(LINE_BREAKS, '\n');
    text = text.split(ZERO_WIDTH_SPACE).join(SOFT_BREAK_PLACEHOLDER);
    text = text.split("\n");

    for (var i = 0; i < text.length; i++) {
        if (text[i] === '') {
            text[i] = ZERO_WIDTH_SPACE;
        }
    }

    return text.join('\n\n');
};

export default plainTextToMarkdown;