import appdata from '../appdata';

import isArray from './isArray';
import parseUri from './parseUri';
import constructUrl from './constructUrl';

const loadScripts = function(scripts, callback) {
    callback = (typeof(callback) != "function")? function() {} : callback;
    var load = [], count = 0, url;
    if (scripts) {
        // Hash of key->url pairs passed
        if (!isArray(scripts)) {
            for(var k in scripts) {
                load.push({
                    key: k,
                    url: scripts[k]
                });
            }
        }
        else {
            for(var k = 0; k < scripts.length; k++) {
                load.push({
                    key: scripts[k],
                    url: loadScripts.BuildUrl(scripts[k], true)
                });
            }
        }

        if (load.length > 0) {
            for(var i = 0; i < load.length ;i++) {
                (function(i, load) {
                    loadScripts.Load(load[i].url, function() {
                        appdata.loadedLibs[load[i].key] = true;
                        count++;
                        // All scripts loaded
                        if (load.length == count) {
                            callback();
                        }
                    });
                })(i, load);
            }

            return true;
        }
    }

    callback();
    // No scripts loaded
    return false;
};
loadScripts.loadedScripts = {};

loadScripts.BuildUrl = function(key, skipCacheBuster) {
    skipCacheBuster = (typeof(skipCacheBuster) != "boolean") ? false : skipCacheBuster;

    // Key is a url - use it
    if (key.match(/^http[s]?:\/\//)) {
        return key;
    }

    if (key.match(/^\/\//)) {
        return key;
    }

    var parts = new String(key).split("?"), params = {};
    if (parts.length == 2) {
        key = parts[0];
        params = global.PSLIB.Request.deparam(parts[1]);
    }
    if (appdata.config.debugMode && !skipCacheBuster) {
        params["_"] = new Date().getTime();
    }

    var config = appdata.config, src,
        parts = key.split(/[\/]/),
        end = parts.pop(),
        host = config.homesite;

    // If we should serve a minified file, and we haven't already requests a minified file
    if (key.match(/^(pslib|integration|hive|ce|poll|misc|application)\/.*\.js$/) &&
        !key.match(/-min.js$/)) {
        if (!config.debugMode) {
            end = end.replace(/\.js$/,'-min.js');
        }

        // Host must be homesite - apache will ensure auto-minification
        host = config.homesite;
    }

    parts.unshift(((config.useGzip)? "javascriptz" : "javascript"));
    if (config.realVersion) {
        parts.push("v"+config.realVersion);
    }

    parts.push(end);

    var hasParams = false;
    for (var k in params) {
        hasParams = true;
    }

    return host+"/"+parts.join("/")+ ((hasParams)? "?"+global.PSLIB.Request.param(params) : "");
};
loadScripts.LoadScript = function(url, onLoad, win) {
    // Allow us to load scripts in different global (e.g. iframe)
    win = ((typeof(win) != "undefined")? win : global);
    onLoad = (typeof(onLoad) != "function") ? function() {} : onLoad;
    var script = win.document.createElement('script'),
    loadFired = false,
    key = url;

    // Debug mode - dont cache a key /w cachebuster
    if (!appdata || !appdata.config || appdata.config.debugMode) {
        var parsed = parseUri(key);
        // Remove cachebuster from key
        delete parsed.queryKey["_"];
        key = ((parsed.protocol && parsed.protocol.length > 0)? parsed.protocol+":" : "")+"//"+parsed.host+parsed.path;
        var queryStr = [];

        for (var k in parsed.queryKey) {
            // Ensure we dont have {key}=
            queryStr.push(k+ ((parsed.queryKey[k].length > 0)? "=" : "")+parsed.queryKey[k]);
        }
        queryStr = queryStr.join("&");
        if (queryStr.length > 0) {
            key = constructUrl(key, queryStr);
        }
    }

    if (!loadScripts.loadedScripts[key]) {
        loadScripts.loadedScripts[key] = { callbacks: [] };
    }

    script.setAttribute('src', url);
    script.setAttribute('charset', "UTF-8");
    script.onload = function() {
        if (!loadFired) {
            if (loadScripts.loadedScripts[key]) {
                loadScripts.loadedScripts[key].loading = false;
                loadScripts.loadedScripts[key].loaded = true;
            }

            loadScripts.LoadComplete(key);
            onLoad(key);
        }
        loadFired = true;
    };
    script.onreadystatechange = function() {
        if(/loaded|complete/.test(script.readyState)) {
            if (!loadFired) {
                loadScripts.loadedScripts[key].loading = false;
                loadScripts.loadedScripts[key].loaded = true;
                loadScripts.LoadComplete(key);
                onLoad(key);
            }
            loadFired = true;
        }
    };
    if (win.document.head) {
        win.document.head.appendChild(script);
    }
    else {
        win.document.body.appendChild(script);
    }
};
loadScripts.LoadComplete = function(url) {
    if (loadScripts.loadedScripts[url]) {
        while(loadScripts.loadedScripts[url].callbacks.length > 0) {
            loadScripts.loadedScripts[url].callbacks.shift()();
        }
    }
};
loadScripts.Load = function(url, callback) {
    if (typeof(loadScripts.loadedScripts[url]) != "object") {
        loadScripts.loadedScripts[url] = {
            loading: false,
            loaded: false,
            callbacks: []
        }
    }
    loadScripts.loadedScripts[url].callbacks.push(callback);
    if (loadScripts.loadedScripts[url].loaded) {
        loadScripts.LoadComplete(url);
    }
    else if (!loadScripts.loadedScripts[url].loading) {
        loadScripts.loadedScripts[url].loading = true;
        loadScripts.LoadScript(url);
    }
};

export default loadScripts;