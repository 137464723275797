
// description:
//   implements queue structure but also allow fetching object by name.

import callParentConstructor from './callParentConstructor';
import inherits from './inherits';
import object from './object';
import util from './util';

const namedQueue = function () {
    callParentConstructor(object);
	this._queue = []; 		// array of keys. act as the queue
	this._storage = {}; 	// stores actual key => data
	this._uniqId = 1;		// construct unique name if user does not provide one
};
inherits(object, namedQueue);

namedQueue.prototype.isEmpty = function () {
	return this._queue.length == 0;
};

namedQueue.prototype.getLength = function () {
	return this._queue.length;
};

namedQueue.prototype._addElementSub = function (name) {
	var myname = "";
	if (util.isString(name)) {
		if (name.length > 0) {
			myname = name;
		}
	}
	if (myname.length == 0) {
		myname = util.getHash("NamedQueue_" + this._uniqId);
		this._uniqId ++;
	}
	return (typeof this._storage[myname] == "undefined") ? myname : null;
};

namedQueue.prototype.addToHead = function (name, value) {
	var myname = this._addElementSub(name);
	if (myname) {
		this._storage[myname] = value;
		this._queue.unshift(myname);
		return true;
	}
	return false;
};

namedQueue.prototype.addToTail = function (name, value) {
	var myname = this._addElementSub(name);
	if (myname) {
		this._storage[myname] = value;
		this._queue.push(myname);
		return true;
	}
	return false;
};

namedQueue.prototype.getHead = function () {
	return this.getAt(0);
};

namedQueue.prototype.getTail = function () {
	return this.getAt(this._queue.length - 1);
};

namedQueue.prototype.getByName = function (name) {
	var i = util.getArrayIndexByValue(this._queue, name);
	return this.getAt(i);
};

namedQueue.prototype.getAt = function (i) {
	if (i < 0 || i >= this._queue.length) {
		return null;
	}
	else {
		return {
			name: this._queue[i],
			index: i,
			value: this._storage[this._queue[i]]
		};
	}
};

namedQueue.prototype.removeAt = function (i) {
	if (i < 0 || i >= this._queue.length) {
		return false;
	}
	delete this._storage[this._queue[i]];
	this._queue.splice(i, 1);
	return true;
};

namedQueue.prototype.removeHead = function () {
	return this.removeAt(0);
};

namedQueue.prototype.removeTail = function () {
	return this.removeAt(this._queue.length - 1);
};

namedQueue.prototype.removeByName = function (name) {
	var i = util.getArrayIndexByValue(this._queue, name);
	return this.removeAt(i);
};

namedQueue.prototype.removeAll = function () {
	this._queue = [];
	this._storage = {};
};

export default namedQueue;