import isNull from '../util/isNull';
import isObject from '../util/isObject';
import isArray from '../util/isArray';
import get from './get';

// set the style of an element or an array of elements
var setStyle = function(elem, property, val) {
    var o = get(elem), objS, i;
    if (!o) {
        return;
    }
    if (isArray(o)) {
        for (i = 0; i < o.length; i ++) {
            setStyle(o[i], property, val);
        }
    }
    // is it this format setStyle(em, {'width':'100px','opacity':0.5});
    else if (isObject(property)) {
        for (i in property) {
            setStyle(elem, i, property[i]);
        }
    }
    // old format setStyle(em, 'width', '100px');
    else {
        objS = o.style;
        switch(property) {
        // provides a standard way to update the opacity property
        case 'opacity' :
            // for IE6-8. IE9 has opacity property
            if (typeof objS.filter != "undefined" && typeof objS.opacity == "undefined") {
                if (isNull(val) || val >= 1) {
                    // objS.filter = "" does not work and it still disables cleartype for any text within the div.
                    // so, we update cssText instead to get rid of filter completely.
                    var str = new String(objS.cssText);
                    str = str.replace(/filter\s*:[^;]*;?/i, "");
                    objS.cssText = str;
                }
                else {
                    objS.filter = 'alpha(opacity=' + val * 100 + ')';
                    if (!o.currentStyle || !o.currentStyle.hasLayout) {
                        objS.zoom = 1; // when no layout or cant tell
                    }
                }
            }
            else {
                objS.opacity = val;
                objS['-moz-opacity'] = val;
                objS['-khtml-opacity'] = val;
            }
            break;
        default :
            try {
                objS[property] = val;
            } catch(e) {
            }
            break;
        }
    }
};

export default setStyle;