import isArray from './isArray';

export default function (a, v) {
    if (isArray(a)) {
        for (var i = 0; i < a.length; i ++) {
            if (a[i] == v) {
                return i;
            }
        }
    }
    return -1;
};