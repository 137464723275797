import util from './util';

let loadedScriptsCache = {};

const loadScript = function(key, win) {
	win = ((typeof(win) != "undefined")? win : global);
	key = util.loadScripts.BuildUrl(key,true);

	// Being loaded by utils script loader already
	if (util.loadScripts.loadedScripts[key] && !loadedScriptsCache[key]) {
		loadedScriptsCache[key] = global.PSLIB.Promise.defer();

		// Already loaded - resolve immediately
		if (util.loadScripts.loadedScripts[key].loaded) {
			loadedScriptsCache[key].resolve();
		}
		// Currently loading, push into callback chain
		else {
			util.loadScripts.loadedScripts[key].callbacks.push(function() {
				loadedScriptsCache[key].resolve();
			});
		}
	}

	if (loadedScriptsCache[key]) {
		return loadedScriptsCache[key].promise;
	}

	loadedScriptsCache[key] = global.PSLIB.Promise.defer();

	var timeout = setTimeout(function() {
		// At least try to reload these later
		util.loadScripts.loadedScripts[key] = null;
		loadedScriptsCache[key] = null;

		loadedScriptsCache[key].reject(key);
	}, 60000);

	util.loadScripts.LoadScript(util.loadScripts.BuildUrl(key), function() {
		clearTimeout(timeout);
		loadedScriptsCache[key].resolve(key);
	}, win);

	return loadedScriptsCache[key].promise;
};

export default loadScript;