import arrayIndexOf from './arrayIndexOf';
import arrayFilter from './arrayFilter';


var arrayDiff = function(array1, array2)  {
    // If filter function available - use it (it's faster)
    if (typeof(array1.filter) == "function" && typeof(array2.filter) == "function") {
        return array1.filter(function(i) {
            return !(array2.indexOf(i) > -1);
        });
    }

    // Use equivilant filter function for browers that don't implement native
    // commented out since not working for IE7
    return arrayFilter(array1, function(element, index, array) {
        if (typeof(array2.indexOf) == "function") {
            return !(array2.indexOf(element) > -1);
        }
        return !(arrayIndexOf(array2, element) > -1);
    });
};

export default arrayDiff;