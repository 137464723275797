// Determine the internal JavaScript [[Class]] of an object.
// See full docs http://api.jquery.com/jquery.type/

var type = (function() {
    var class2type = (function() {
        var result = {}, objs = ["Boolean","Number","String","Function","Array","Date","RegExp","Object"];
        for(var i = 0; i < objs.length; i++) {
            result[ "[object " + objs[i] + "]" ] = objs[i].toLowerCase();
        }
        return result;
    })();

    return function( obj ) {
        return obj == null ? String(obj) : class2type[ Object.prototype.toString.call(obj) ] || "object";
    };
})();

export default type;