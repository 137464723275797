import util from './util/index';
import object from './object';
import inherits from './inherits';
import callParentConstructor from './callParentConstructor';

const customEvent = function (p) {
    callParentConstructor(object, this);

    this.chained = false;
    this._listeners = [];
    if (p) {
        if (p.chained) {
            this.chained = true;
        }
    }
}
inherits(object, customEvent);

customEvent.prototype.numberOfSubscribers = function () {
    var count = 0;
    if( typeof(this._listeners.length) != "undefined" ) {
        return this._listeners.length;
    }
    else {
        for(var i in this._listeners) {
            if( typeof(this._listeners[i]) != "function" ) {
                count++;
            }
        }
    }
    return count;
}

// listener: object has properties
//   callback (callback function),
//   context/scope/obj (callback will be under this scope)
//   data (parameter that will be passed to callback)
customEvent.prototype.subscribe = function (listener) {
    if (util.isObject(listener)) {
        var id = this._listeners.length;
        this._listeners[id] = listener;
        return id;
    }
    else {
        return -1;
    }
}

customEvent.prototype.unsubscribe = function (id) {
    var arr = this._listeners;
    if (arr[id]) {
        delete arr[id];
        arr[id] = null;
    }
}

customEvent.prototype.fireEvent = function (handlerData) {
    var arr = this._listeners, i;
    if (this.chained) {
        if (arr.length > 0) {
            this.callHandler(
                arr[0],
                {
                    event: this,
                    data: (typeof handlerData == "undefined" ? null : handlerData),
                    index: 0
                });
        }
    }
    else {
        for (i = 0; i < arr.length; i ++) {
            this.callHandler(arr[i], typeof handlerData == "undefined" ? null : handlerData);
        }
    }
}

customEvent.prototype.callNextHandler = function (data) {
    var arr = this._listeners;
    data.index ++;
    if (data.index < arr.length) {
        this.callHandler(arr[data.index], data);
    }
}

// duplicating the one in event .. so, this class does not need to rely on event
customEvent.prototype.callHandler = function(callback, handlerData) {
    if (util.isFunction(callback)) {
        return callback(handlerData);
    }
    else if (util.isObject(callback)) {
        if (callback.callback) {
            var scope = callback.scope ? callback.scope : (callback.context ? callback.context : (callback.obj ? callback.obj : null));
            if (util.isNull(handlerData)) {
                handlerData = typeof callback.data == "undefined" ? null : callback.data;
            }
            else {
                if (typeof handlerData == "undefined") {
                    handlerData = {};
                }
                if (util.isObject(handlerData) && callback.data) {
                    handlerData.data = callback.data;
                }
            }

            if (scope) {
                return callback.callback.call(scope, handlerData);
            }
            else {
                return callback.callback(handlerData);
            }
        }
    }
    return false;
}

export default customEvent;