/**
 * Iterate over a NodeList and return an array of each element.
 * Not all browsers (e.g. IE8) support this method of converting
 * NodeList to Array: Array.prototype.slice.call(nodeList)
 */

export default function(nodeList) {
    var arr = [];
    if (nodeList.length) {
        for (var i = 0; i < nodeList.length; i++) {
            arr.push(nodeList[i]);
        }
    }
    return arr;
};