var absUrl = function (str, win) {
    var loc, re, returnValue = "";
    str=new String(str);
    if (typeof(win) == "undefined" || !win || !win.location) {
        win = window;
    }
    loc=win.location;

    // is it a abs url?
    re=/^(http|https|ftp):\/\//i;
    if(re.test(str)){
        // return str;
        returnValue = str;
    }
    else {
        // started with backslash?
        // use only protocol and hostname to construct abs url
        if (str.charAt(0) == "/") {
            // return
            //      loc.protocol+"//"
            //     +loc.hostname
            //     +(loc.protocol!="http:"&&loc.port!=80?":"+loc.port:"")
            //     +str;
            returnValue =
                loc.protocol+"//"
                +loc.hostname
                +(loc.protocol!="http:"&&loc.port!=80?":"+loc.port:"")
                +str;
        }
        // use existing location url and remove the file name,
        // then append file to its end
        else {
            // return loc.href.substr(0,loc.href.lastIndexOf("/"))+"/"+str;
            returnValue = loc.href.substr(0,loc.href.lastIndexOf("/"))+"/"+str;
        }
    }

    return returnValue;
};

export default absUrl;