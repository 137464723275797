// Left pads a string with a pad
// Arguments:
//		str - string to pad
//		maxLength - max length to pad string to
//		chars - chars to use for the pad

export default function(str, maxLength, chars) {
    var pad_char = ((typeof(chars) !== 'undefined') ? chars : '0'),
    pad = new Array(1 + maxLength).join(pad_char);

    return (pad + str).slice(-pad.length);
};