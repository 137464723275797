// Description:
//      encode characters used in HTML syntax to html entities
// Arguments:
//      str - string to be encoded
//      flag -
//          undefined or 0 - escape double quote
//          1 - do not escape double quote
//          2 - quote single quote too
// Return:
//      encoded string

export default function (str, flag) {
    var m = new String(str), re;
    if (typeof(flag) == "undefined") {
        flag = 0;
    }
    m=m.replace(/&/g,"&amp;");
    if (flag != 1) {
        m=m.replace(/\"/g,"&quot;");
    }
    if (flag == 2) {
        m=m.replace(/\'/g,"&#039;");
    }
    m=m.replace(/</g,"&lt;");
    m=m.replace(/>/g,"&gt;");
    // non-breakable space
    re=new RegExp(String.fromCharCode(160), "g")
    m=m.replace(re,"&nbsp;");
    return m;
};