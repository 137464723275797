import isObject from '../util/isObject';
import type from '../util/type';
import attr from '../attr';

var getAll = function(elem) {
    if ( typeof elem.getElementsByTagName !== "undefined" ) {
        return elem.getElementsByTagName( "*" );
    } else if ( typeof elem.querySelectorAll !== "undefined" ) {
        return elem.querySelectorAll( "*" );
    } else {
        return [];
    }
};

// Takes a root DOM object, and converts it to an array
//	root - root DOm Element
//	attr - optional attribute to use as key for arrays (default: name)
//
// See full docs for output result: http://api.jquery.com/serializearray/

var serializeArray = function(root, attribute, args) {
    attribute = (typeof(attribute) == "undefined")? "name" : attribute;
    args = isObject(args) ? args : {};
    args.include_disabled = typeof(args.include_disabled) !== "boolean" ? false : args.include_disabled;

    var val, elem,
    rselectTextarea = /^(?:select|textarea)/i,
    rbracket = /\[\]$/,
    rCRLF = /\r?\n/g,
    rinput = /^(?:color|date|datetime|datetime-local|email|hidden|month|number|password|range|search|tel|text|time|url|week)$/i,
    elements = getAll(root),
    filteredElements = [],
    data = [];

    // Filter only active elements with the attr property
    for(var i = 0; i < elements.length; i++) {
        if (elements[i].wysiwyg ||
            (attr(elements[i], attribute) &&
            (args.include_disabled || !elements[i].disabled) &&
            (elements[i].checked || rselectTextarea.test(elements[i].nodeName) || rinput.test(elements[i].type)))) {
            filteredElements.push(elements[i]);
        }
    }

    for(var i = 0; i < filteredElements.length; i++) {
        elem = (filteredElements[i].wysiwyg || filteredElements[i]);
        val = attr(elem, "value");

        if (val == null) {
            return null;
        }
        else {
            if (type(val) === "array") {
                for(var j = 0; j < val.length; i++) {
                    data.push({
                        name: attr(elem, attribute), value: val[j].replace( rCRLF, "\r\n" )
                    });
                }
            }
            else {
                data.push({
                    name: attr(elem, attribute), value: val.replace( rCRLF, "\r\n" )
                });
            }
        }
    }

    return data;
};

export default serializeArray;