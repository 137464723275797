import event from './event/index';
import animation from './animation';

const fx = (function() {
	var queues = {},
	objs = {},
	idcounter = 0,
	UD = "undefined";

	return {
		interval:50,
		duration:500,
		off:false,

		completed:function(obj) {
            var id;

            if (obj.queue != "default") {
                queues[obj.queue].shift();
            }

			// completed already. call complete handler and remove the animation object from queue.
			if (typeof obj.options.complete != UD) {
				event.callHandler(obj.options.complete, {queue:obj.queue});
			}

			// start animation if available
			if (typeof queues[obj.queue] != "undefined" && queues[obj.queue].length > 0) {
				id = queues[obj.queue][0];
				objs[id].animation.start(objs[id].elem);
			}

            // cleanup completed object
			delete objs[obj.id];
        },

		clearQueue:function(queue) {
			var id;
			if (typeof queue == UD) {
				queue = "default";
			}
            // clear all
            if (queue == "default") {
                for (id in objs) {
					objs[id].animation.stop();
					delete objs[id];
                }
                queues = {};
            }
            // clear one queue
			else if (typeof queues[queue] != UD) {
				while (queues[queue].length > 0) {
					id = queues[queue].shift();
					objs[id].animation.stop();
					delete objs[id];
				}
			}
		},

		delay:function(duration, queue) {
			if (typeof queue == UD) {
				queue = "default";
			}
			this.animate(null, [], {duration:duration,queue:queue});
		},

		animate:function(elem, animations, options) {
			var id = "_" + (idcounter ++),animationInstance;
			if (typeof options == UD) {
				options = {};
			}
			if (typeof options.queue == UD) {
				options.queue = "default";
			}
			if (typeof options.duration == UD) {
				options.duration = this.duration;
			}

			animationInstance = new animation(
				{
					numRun:this.off?1:parseInt(options.duration/this.interval),
					durationPerRun:this.off?1:this.interval,
					animation:animations
				}
			);

			objs[id] = {
				id:id,
				elem:elem,
				options:options,
				queue:options.queue,
				animation:animationInstance
			};
			animationInstance.completionEvent.subscribe({context:this,callback:this.completed,data:objs[id]});

			if (options.queue != "default") {
                if (typeof queues[options.queue] == UD) {
                    queues[options.queue] = [id];
                }
                else {
                    queues[options.queue].push(id);
                }
            }

            // animation in default queue should start right away
            // otherwise, animation should start if the queue is originally empty
			if (options.queue == "default" ||
                queues[options.queue].length == 1) {
				objs[id].animation.start(elem);
			}
		},

		fadeIn:function(elem, queue) {
			this.animate(elem, [{type:"alpha",start:0,end:1,easing:"easeout"}], {queue:(typeof queue != "undefined" ? queue : "default")});
		},

		fadeOut:function(elem, queue) {
			this.animate(elem, [{type:"alpha",start:1,end:0,easing:"easeout"}], {queue:(typeof queue != "undefined" ? queue : "default")});
		}
	};
})();

export default fx;