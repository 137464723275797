
// depends on pslib, pslib_utils, pslib_dom, pslib_event

/*
	provide placeholder like support for input elements.
	this class bind listeners to each input elements.
	this class does not use browser placeholder support.
	new code should use placeholder
*/

import dom from './dom/index';
import attr from './attr';
import event from './event';

const captionlessInput = {
	onFocus : function(evt) {
		var e = evt.registeredTarget;
		if (!dom.hasClass(e, "captionless-input-has-content")) {
			e.value = "";
		}
		dom.addClass(e, "captionless-input-editing");
	},
	onBlur : function(evt) {
		var e = evt.registeredTarget;
		this.onChange(evt);
		if (e.value.length == 0) {
			e.value = e.title;
		}
		dom.removeClass(e, "captionless-input-editing");
	},
	onChange : function(evt) {
		var e = evt.registeredTarget, c = "captionless-input-has-content", dom = dom;
		if (dom.hasClass(e, "captionless-input-editing")) {
			if (e.value.length == 0) {
				dom.removeClass(e, c);
			}
			else {
				dom.addClass(e, c);
			}
		}
	},
	getValue : function(e) {
		if (dom.hasClass(e, "captionless-input-inited")) {
			return dom.hasClass(e, "captionless-input-has-content") ? e.value : "";
		}
		else {
			return e.value;
		}
	},
	setValue : function(e, value) {
		if (dom.hasClass(e, "captionless-input-inited")) {
			if (value.length > 0) {
				e.value = value;
				dom.addClass(e, "captionless-input-has-content");
			}
			else {
				// Don't do anything until init has fired
				if (dom.hasClass(e, "captionless-input-inited")) {
					e.value = e.title;
				}
				dom.removeClass(e, "captionless-input-has-content");
			}
		}
		else {
			e.value = value;
		}
	},
	init : function(fields) {
		var i, f, v;
		if (typeof fields == "undefined") {
			fields = PS$$(".captionless-input");
		}
		for (i = 0; i < fields.length; i ++) {
			f = fields[i];
            v = attr(f, "value")+"";
			if (!dom.hasClass(f, "captionless-input-inited")) {
				event.bind(f, {
					keyup: {scope:this, callback:this.onChange},
					change: {scope:this, callback:this.onChange},
					focus: {scope:this, callback:this.onFocus},
					blur: {scope:this, callback:this.onBlur}
					});
				f.value = f.title;
				dom.addClass(f, "captionless-input-inited");
                if (v && v != '') {
                    captionlessInput.setValue(f, v);
                }
			}
		}
	}
};

export default captionlessInput;