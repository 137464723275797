// get a 16 characters hash
// adapted from JSHash()

import number2hex from './number2hex';

export default function (s) {
    var ms = "libmagicstr", hash1, hash2, i, s1 = ms + s + ms, s2 = ms + s + ms;
    hash2 = hash1 = 1315423911;
    for (i = 0; i < s1.length; i ++) {
        hash1 ^= (hash1 << 5) + s1.charCodeAt(i) + (hash1 >> 2);
    }
    for (i = s2.length - 1; i >= 0; i --) {
        hash2 ^= (hash2 << 5) + s2.charCodeAt(i) + (hash2 >> 2);
    }
    return number2hex(hash1) + number2hex(hash2);
};