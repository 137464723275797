
/*
	A better version of captionlessinput to provide fallback supported of placeholder
	Here a few benefits:
	- if browser supports placeholder, it will be used and otherwise fallback code similar to captionlessinput will be used.
	- captionlessinput bind each element. placeholder fallback code bind document.body once
	Notes:
	- captionlessinput and placeholder fallback code uses similar classes "captionless-input*".
	- placeholder.init() should be called for any new input elements added to dom.
	- Tested on IE8, IE9, IE11, FF and Chrome
*/

import dom from './dom/index';
import util from './util';
import attr from './attr';

const placeholder = (function() {
	var binded = false,
		placeholderIsSupported = (function () {
			var test = (global.document)? global.document.createElement('input') : null;
			return test && ('placeholder' in test);
		})(),
		obj = {
			onFocus : function(evt) {
				var e = evt.srcElement ? evt.srcElement : evt.target;
				// this handler must be fast since listening for multiple elements.
				// check whether they are what we care right away
				if (dom.hasClass(e, "ps-placeholder-input-inited")) {   // only listen on what bind with placeholder
					if (!dom.hasClass(e, "captionless-input-has-content")) {
						e.value = "";
					}
					dom.addClass(e, "captionless-input-editing");
				}
			},
			onBlur : function(evt) {
				var e = evt.srcElement ? evt.srcElement : evt.target;
				// this handler must be fast since listening for multiple elements.
				// check whether they are what we care right away
				if (dom.hasClass(e, "ps-placeholder-input-inited")) {   // only listen on what bind with placeholder
					obj.onChange(evt);
					if (e.value.length == 0) {
						e.value = attr(e, "placeholder") ? attr(e, "placeholder") : e.title;
					}
					dom.removeClass(e, "captionless-input-editing");
				}
			},
			onChange : function(evt) {
				var e = evt.srcElement ? evt.srcElement : evt.target,
					c = "captionless-input-has-content";
				// this handler must be fast since listening for multiple elements.
				// check whether they are what we care right away
				if (dom.hasClass(e, "ps-placeholder-input-inited")) {   // only listen on what bind with placeholder
					if (dom.hasClass(e, "captionless-input-editing")) {
						if (e.value.length == 0) {
							dom.removeClass(e, c);
						}
						else {
							dom.addClass(e, c);
						}
					}
				}
			},
			getValue : function(e) {
				if (dom.hasClass(e, "captionless-input-inited")) {
					// either class exist when editing.
					return (dom.hasClass(e, "captionless-input-editing") || dom.hasClass(e, "captionless-input-has-content") ? e.value : "");
				}
				else {
					return e.value;
				}
			},
			setValue : function(e, value) {
				if (dom.hasClass(e, "captionless-input-inited")) {
					if (value.length > 0) {
						e.value = value;
						dom.addClass(e, "captionless-input-has-content");
					}
					else {
						// Don't do anything until init has fired
						if (dom.hasClass(e, "captionless-input-inited")) {
							e.value = attr(e, "placeholder") ? attr(e, "placeholder") : e.title;
						}
						dom.removeClass(e, "captionless-input-has-content");
					}
				}
				else {
					e.value = value;
				}
			},
			init : function(scope, forcePolyFill) {
                var fields = PS$$("input.captionless-input,textarea.captionless-input", scope);

                for (var i = 0; i < fields.length; i ++) {
                    // Common interface for WYSIWYG implementation
                    fields[i].clear = function() {
                        return placeholder.setValue(this, "");
                    };
                    fields[i].getMarkdown = function() {
                        var val = placeholder.getValue(this);
                        return util.plainTextToMarkdown(val);
                    };
                    fields[i].setMarkdown = function(val) {
                        val = util.markdownToPlainText(val);
                        return placeholder.setValue(this, val);
                    };
                    fields[i].getValue = function() {
                        return placeholder.getValue(this);
                    };
                }

				if (!forcePolyFill && placeholderIsSupported) {
					// checked and browser supports placeholder.
					// copy title attr to placeholder attr for old code
					var i, f, v, textarea;
					for (i = 0; i < fields.length; i ++) {
						f = fields[i];
						v = attr(f, "placeholder")+"";
						if (v.length == 0) {
							attr(f, "placeholder", attr(f, "title"));
						}
					}
					return;
				}

				if (!binded) {
					// PSLIB focus, blur bubbling fallback handling not working properly
					// bind the events directly
					// basically, change, blur and focus wont bubble and we need some ways to
					// get around. for change, keyup and mouseup are used instead.
					// if addEventLister() is available, event capturing is used instead for focus and blur
					// otherwise, browsers should be IE and focusin and focusout are used instead (they bubbles)

					// whatever supports addEventListener
					// most probably IE9 may use this code (IE9 supports addEventListener but not placeholder)
					if (document.body.addEventListener) {
						document.body.addEventListener(
							"change",
							this.onChange,
							true
						);

						document.body.addEventListener(
							"focus",
							this.onFocus,
							true		// focus not bubbling. use capture
						);

						document.body.addEventListener(
							"blur",
							this.onBlur,
							true		// focus not bubbling. use capture
						);
					}
					// IE < 9
					else if (document.body.attachEvent) {
						document.body.attachEvent(
							"onkeyup",
							this.onChange
						);

						document.body.attachEvent(
							"onmouseup",
							this.onChange
						);

						document.body.attachEvent(
							"onfocusin",
							this.onFocus
						);

						document.body.attachEvent(
							"onfocusout",
							this.onBlur
						);
					}

					binded = true;
				}

				// need our fallback implementation.
				// following code goes through all elements we care and not inited yet.
				var i, f, v;
				for (i = 0; i < fields.length; i ++) {
					f = fields[i];
					if (!dom.hasClass(f, "captionless-input-inited")) {
						v = attr(f, "value")+"";
						f.value = attr(f, "placeholder") ? attr(f, "placeholder") : f.title;
						dom.addClass(f, "captionless-input-inited");        // the old way to tell this element is bind
						dom.addClass(f, "ps-placeholder-input-inited");     // the new way (and listeners look for this class)
						if (v && v != '') {
							this.setValue(f, v);
						}
					}
				}
			}
		};
	return obj;
})();

export default placeholder;