// deeply merges an array

import isArray from './isArray';

var deepMerge = function(a,b) {
    // Leave arrays as-is
    if (isArray(b)) {
        return b;
    }
    var m = {}, o = "object";
    for (var i in b) {
        if (a.hasOwnProperty(i) && b.hasOwnProperty(i)) {
            if (typeof(a[i]) == o && typeof(b[i]) == o) {
                m[i] = deepMerge(a[i], b[i]);
            } else {
                m[i] = b[i];
            }
        } else if(b.hasOwnProperty(i)) {
            m[i] = b[i];
        }
    }
    for (var i in a) {
        if (!(i in b) && a.hasOwnProperty(i)) {
            m[i] = a[i];
        }
    }
    return m;
};

export default deepMerge;