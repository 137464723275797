// Description:
//      returns a string with backslashes before characters that need to be quoted
//      in javascript string
// Arguments:
//      str - string to be encoded
// Return:
//      string

var addSlashes = function (str) {
    var m = new String(str);
    m=m.replace(/\\/g,"\\\\");
    m=m.replace(/\"/g,"\\\"");
    m=m.replace(/\'/g,"\\\'");
    m=m.replace(/\t/g,"\\t");
    m=m.replace(/\r/g,"\\r");
    m=m.replace(/\n/g,"\\n");
    return m;
};

export default addSlashes;