export default function(url) {
    url = (typeof(url) == "string")? url : "";
    var matches, url_params, param_parts, params = [], temp;
    matches = url.match(/^(.*?)(\?(.*?))?(#(.*))?$/); // match the query string but not the hash part
    if (typeof matches[3] == "string") {
        url_params = matches[3].split("&");
        for(var i = 0; i < url_params.length; i++) {
            param_parts = url_params[i].split("=");
            temp = {
                key: decodeURIComponent(param_parts.shift())
            };
            if (param_parts.length > 0) {
                temp["value"] = decodeURIComponent(param_parts.join("="));
            }
            params.push(temp);
        }
    }
    return params;
};