import browser from './browser';

const copyToClipboard = (function() {
    var that = this, execCommandFlag = false,

    _copyToClipboard = function (element) {
        // select text
        try {
            element.select();
        } catch(e) {
            //try to select the text
            try {
                element.setSelectionRange(0, element.value.length);
            }
            catch (e) {}
        }

        //if ie9 || ie10 || ie7 - return false
        //because execCommand will return true even though the user doesn't allow access to clipboard
        if (browser.browser == "msie" && browser.version == "9.0" ||
        browser.browser == "msie" && browser.version == "10.0" ||
        browser.browser == "msie" && browser.version == "7.0") {
            return false;
        }

        try {
            // copy text
            execCommandFlag = document.execCommand('copy');
            element.blur();
        }
        catch (err) {
            return false;
        }

        return execCommandFlag;
    },

    _canIuse = function () {
        //if ie9 || ie10 || ie7 - return false
        //because execCommand will return true even though the user doesn't allow access to clipboard
        if (browser.browser == "msie" && browser.version == "9.0" ||
        browser.browser == "msie" && browser.version == "10.0" ||
        browser.browser == "msie" && browser.version == "7.0") {
            return false;
        }

        try {
            execCommandFlag = document.execCommand('copy');
        } catch(e) {
            return false;
        }
        return execCommandFlag;
    };

    return {
        copy: _copyToClipboard,
        canIuse: _canIuse
    };
})();

export default copyToClipboard;