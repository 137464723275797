var cloneObj = function (obj) {
    var o, prop;
    if (!obj || typeof (obj) != 'object') {
        return null;
    }
    if (obj.constructor == Array) {
        o = obj.concat();
    }
    else {
        o = {};
        for (prop in obj) {
            o[prop] = typeof (obj[prop]) == 'object' ? cloneObj(obj[prop]) : obj[prop];
        }
    }
    return o;
};

export default cloneObj;