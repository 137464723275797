import attr from '../attr';

import isObject from '../util/isObject';
import type from '../util/type';
import trim from '../util/trim';
import extendObject from '../util/extendObject';
import isString from '../util/isString';
import isArray from '../util/isArray';
import mergeArray from '../util/mergeArray';
import isNull from '../util/isNull';
import escapeRegExp from '../util/escapeRegExp';

import get from './get';
import createScriptTag from './createScriptTag';
import setStyle from './setStyle';
import toJson from './toJson';
import serializeArray from './serializeArray';

var
ua = ((typeof(global) !== "undefined" && typeof(global.navigator) !== "undefined")? global.navigator.userAgent.toLowerCase() : ""),
isOpera = (global.opera?true:false),
isSafari = (ua.indexOf('safari') > -1),
isIE = (global.ActiveXObject),
id_counter = 0;

const dom = {
	// Takes a root DOM object, and converts it to an array
	//	root - root DOm Element
	//	attr - optional attribute to use as key for arrays (default: name)
	//
	// See full docs for output result: http://api.jquery.com/serializearray/
	serializeArray: serializeArray,
	// Converts all the child form elements of a root node to a JSON object
	//      root - the root node
	//      attribute - the attr property to use on the elements when building the array
	//		args - additional arguments
	//			trimEmpty - Will apply trim to see if value is actually empty
	// E.g. dom.toJson(global.document.body) on the http://demo.polldev.com/tests/comment_engines/Testing%3A Polls%2C CE %26 THv1 - Sparkplug2.php page
	//
	// -> {"charset":"utf-8","doctype":"HTML 4.01 Strict","x_ua_compatible":"","article_id":"05645","article_url":"","language_name":"English","category_id":"10","reply_id":"38307269","description":"","search":{"filter":["ideas","replies","votes"]}}
	toJson: toJson,
	getHtml : function(obj) {
		var c = global.document.createElement('div');

		switch(obj.tagName) {
			case 'TR':
				c = global.document.createElement('tbody');
				break;
			case 'TD':
				c = global.document.createElement('tr');
				break;
		}

		c.appendChild(obj);

		return c.innerHTML;
	},

	getElement: function(str) {
		str = (str+"").replace(/^[\n\s]*/g, '').replace(/[\n\s]*$/g, '');

		var obj = null, objs = [],
			trMatch = str.match(/^[\s]*<tr[^>]*>.*<\/tr>[\s]*$/),
			tdMatch = str.match(/^[\s]*<td[^>]*>.*<\/td>[\s]*$/),
			bodyMatch = str.match(/^[\s]*<tbody[^>]*>.*<\/tbody>[\s]*$/);

		obj = global.document.createElement('div');

		if( tdMatch ) {
			obj.innerHTML = "<table><tr>"+str+"</tr></table>";
			obj = obj.getElementsByTagName("td")[0];
		}
		else if( trMatch || bodyMatch ) {
			obj.innerHTML = "<table>"+str+"</table>";
			obj = obj.getElementsByTagName("tr")[0];
		}
		else {
			obj.innerHTML = str;
		}

		if(trMatch || tdMatch) {
			return obj;
		}

		if( obj.childNodes.length == 1 ) {
			obj = obj.firstChild.cloneNode(true);

			if( tdMatch && obj.tagName != "TD" ) {
				var td = global.document.createElement("td");
				td.appendChild(obj);

				obj = td;
			}

			return obj;
		}

		for(var o = 0; o < obj.childNodes.length; o++) {
			objs.push( obj.childNodes[o].cloneNode(true) );
		}

		return objs;
	},

	getWindowSize: function() {
		var winW = 630, winH = 460;
		if (global.document.body && global.document.body.offsetWidth) {
			winW = global.document.body.offsetWidth;
			winH = global.document.body.offsetHeight;
		}
		if (global.document.compatMode=='CSS1Compat' &&
			global.document.documentElement &&
			global.document.documentElement.offsetWidth ) {
				winW = global.document.documentElement.offsetWidth;
				winH = global.document.documentElement.offsetHeight;
		}
		if (global.innerWidth && global.innerHeight) {
			winW = global.innerWidth;
			winH = global.innerHeight;
		}

		return { width : winW, height : winH };
	},

	scrollIntoView: function(el) {
		var topOfPage = global.pageYOffset || global.document.documentElement.scrollTop || global.document.body.scrollTop;
		var heightOfPage = global.innerHeight || global.document.documentElement.clientHeight || global.document.body.clientHeight;
		var elY = 0;
		var elH = 0;
		if (global.document.layers) { // NS4
			elY = el.y;
			elH = el.height;
		}
		else {
			for(var p=el; p&&p.tagName!='BODY'; p=p.offsetParent){
				elY += p.offsetTop;
			}
			elH = el.offsetHeight;
		}
		if ((topOfPage + heightOfPage) < (elY + elH)) {
			el.scrollIntoView(false);
		}
		else if (elY < topOfPage) {
			el.scrollIntoView(true);
		}
	},

	get: get,

	// get HTML elements that match criteria
	// testfunc - an element will be passed to testfunc. the element will be included in result iff testfunc return true.
	// tag (optional) - return elements with tagName 'tag'
	// root (optional) - root element to start searching from
	// return - array of elements
	// note: for performance reason, specifies tag and root if possible.
	getElementsBy : function(testfunc, tag, root) {
		var res = [],elems,i,D= global.document;
		if (tag && root && root.getElementsByTagName) {
			elems = root.getElementsByTagName(tag);
		}
		else if (tag) {
			elems = D.getElementsByTagName(tag);
		}
		else {
			elems = D.getElementsByTagName("*");
		}

		for (i = 0; i < elems.length; i ++) {
			if (testfunc(elems[i])) {
				res[res.length] = elems[i];
			}
		}

		return res;
	},

	// get HTML elements that has class 'className'
	// className - return element that has this className specified by this argument. multiple classes separated by spaces allowed.
	// tag (optional) - return elements with tagName 'tag'
	// root (optional) - root element to start searching from
	// includeRoot (optional) - whether include root element for checking
	// return - Array like structure of elements
	// NOTE: for performance reason, specifies tag and root if possible.
	getElementsByClassName : function(className, tag, root, includeRoot) {
		// Firefox 3+, IE9+ supports getElementsByClassName. use it if it is available and tag is not specified
		var D = global.document, ret;

		if (!tag && className && D.getElementsByClassName) {
			ret = (root ? root : D).getElementsByClassName(className);
			if (includeRoot && dom.hasClass(root, className)) {
				ret = mergeArray([root], ret);
			}
			return ret;
		}
		else {
			var tag = tag || "*",
				elm = root || D,
				elms = (tag == "*" && elm.all)? elm.all : elm.getElementsByTagName(tag),
				i, j,
				names,
				regexps = [],
				match;
			ret = [];

			if (includeRoot && (tag.toUpperCase() == root.tagName || tag == "*")) {
				elms = mergeArray([elm], elms);
			}
			if (!className || className == "") {
				return elms;
			}
			else {
				/* optimizing following code
				for (i=0; i<elms.length; i++){
					if (dom.hasClass(elms[i], className)) {
						ret.push(elms[i]);
					}
				}
				*/

				// setting up regexp
				names = trim(className).split(/\s+/);
				for (i = 0; i < names.length; i ++) {
					regexps.push(new RegExp('(?:^|\\s+)' + escapeRegExp(names[i]) + '(?:\\s+|$)'));
				}

				// start the matching now
				for (i = 0; i < elms.length; i ++) {
					match = true;
					for (j = 0; j < regexps.length; j ++) {
						if (!regexps[j].test(elms[i].className)) {
							match = false;
							break;
						}
					}
					if (match) {
						ret.push(elms[i]);
					}
				}

				return ret;
			}
		}
	},

	// get HTML elements by attributes
	// attrname - attribute name
	// op - operators on matching value of attrname, support =, ^=, $=, *= and ~=
	// attrvalue - matching value
	// tag, root, classname - will fetch elements with matching tag, root and classname first and then match the attrvalue
	// return array of elements
	getElementsByAttribute : function (attrname, op, attrvalue, tag, root, classname) {
		var attrs = [];
		if (attrname && op) {
			attrs.push({name:attrname,op:op,value:attrvalue});
		}
		return dom.getElementsByAttributes(attrs, tag, root, classname);
	},

	// return whether an element has an attribute or not
	hasAttribute : function (em, attrname) {
		if (!em) {
			return false;
		}

		// new browser has this one
		if (em.hasAttribute) {
			return em.hasAttribute(attrname);
		}
		// this one for older browser
		else if (em.getAttributeNode) {
			var attribute = em.getAttributeNode(attrname);
			if (!attribute) {
				return false;
			}
			return attribute.specified;
		}

		return false;
	},

	__getAttrsOpFuncs: (function() {
		var esc = escapeRegExp,
			opfuncs = {
				"=":	function(val1, val2) {return val1 == val2},
				"^=":	function(val1, val2) {var re = new RegExp("^"+esc(val1));return re.test(val2)},
				"$=":	function(val1, val2) {var re = new RegExp(esc(val1)+"$");return re.test(val2)},
				"*=":	function(val1, val2) {var re = new RegExp(esc(val1));return re.test(val2)},
				"~=":	function(val1, val2) {var re = new RegExp('(^|\\s+)'+esc(val1)+'(\\s+|$)');return re.test(val2)}
			};
		return function() {
			return opfuncs;
		}
	})(),

	elementHasAttributes : function (o, attrs) {
		var opfunc, opfuncs = dom.__getAttrsOpFuncs(), j;
		for (j = 0; j < attrs.length; j ++) {
			if (attrs[j].op == "has_attribute") {
				if (!dom.hasAttribute(o, attrs[j].name)) {
					return false;
				}
			}
			else {
				opfunc = opfuncs[attrs[j].op];
				if (!opfunc || typeof attrs[j].name == "undefined") {
					return false;
				}
				if (!dom.hasAttribute(o, attrs[j].name) ||
					!opfunc(attrs[j].value, attr(o, attrs[j].name))) {
					return false;
				}
			}
		}
		return true;
	},

	// get HTML elements by attributes
	// attrs - [{name:"", op:"", value:""}, ...]
	// tag, root, classname - will fetch elements with matching tag, root and classname first and then match the attrvalue
	// return array of elements
	getElementsByAttributes : function (attrs, tag, root, classname, includeRoot) {
		attrs = attrs || [];
		var elms = dom.getElementsByClassName(classname, tag, root, includeRoot);
		if (attrs.length == 0) {
			return elms;
		}

		var ret = [],
			o, i, j,
			opfunc,
			opfuncs = dom.__getAttrsOpFuncs(),
			ok;

		for (i = 0; i < elms.length; i ++) {
			o = elms[i];
			ok = true;
			for (j = 0; j < attrs.length; j ++) {
				if (attrs[j].op == "has_attribute") {
					if (!dom.hasAttribute(o, attrs[j].name)) {
						ok = false;
						break;
					}
				}
				else {
					opfunc = opfuncs[attrs[j].op];
					if (!opfunc || typeof attrs[j].name == "undefined") {
						return [];
					}
					if (!dom.hasAttribute(o, attrs[j].name) ||
						!opfunc(attrs[j].value, attr(o, attrs[j].name))) {
						ok = false;
						break;
					}
				}
			}
			if (ok) {
				ret.push(o);
			}
		}
		return ret;
	},

	// Poor mans implementation of jQuerys closest function
	// Note: selectors limitations the same as PS$$
	closest: function(selectors, scope) {
		var whitespace = "[\\x20\\t\\r\\n\\f]",
		context = undefined,
		docElem = global.document.documentElement,
		rattributeQuotes = new RegExp( "=" + whitespace + "*([^\\]'\"]*?)" + whitespace + "*\\]", "g" ),
		matchesSelector = function(node, selector) {
			var nodeList = PS$$(selector, node.parentNode),
				length = nodeList.length,
				i = 0;

			while (i < length) {
				if (nodeList[i] == node) return true;
				++i;
			}
			return false;
		};

		// Make sure that attribute selectors are quoted
		selectors = selectors.replace(rattributeQuotes, "='$1']");

		var cur = scope,
		matched = [];

		while (cur = cur.parentNode) {
			// Always skip global.document fragments
			if (cur.nodeType < 11) {
				if (cur.nodeType === 1 && matchesSelector(cur, selectors)) {
					return cur;
				}
			}
		}

		return null;
	},

	// return ancestor element that has matching tagName and className
	// className - class name to match or dont need to match class name, just pass null
	// tagName - tag name to match or dont need to match tag name, just pass null
	// child - search parent from this node.
	// stop - stop searching if reaching this node. if passed null or not passed, this method will keep searching until no parent
	// includeSelf - this method used to start searching from parent node. if true is passed, start searching from "child"
	getAncestor: function(className, tagName, child, stop, includeSelf) {
		var node = dom.get(child);
		if (!node) return false;
		node = includeSelf ? node : node.parentNode;
		tagName = (!tagName || tagName == "")? null : tagName.toLowerCase();
		className = (!className || className == "")? null : className;

		while (node && node != stop) {
			var nodeTagName = new String(node.tagName);
			if (
				(
					className &&
					tagName &&
					dom.hasClass(node, className) &&
					tagName == nodeTagName.toLowerCase()
				)
				||
				(
					!className &&
					tagName &&
					tagName == nodeTagName.toLowerCase()
				)
				||
				(
					className &&
					!tagName &&
					dom.hasClass(node, className)
				)
			) {
				return node;
			}

			node = node.parentNode;
		}

		if (node == stop) {
			return null;
		}

		return node;
	},

	nextSibling : function(n) {
		do n = n.nextSibling;
		while (n && n.nodeType != 1);
		return n;
	},

	previousSibling : function(p) {
		do p = p.previousSibling;
		while (p && p.nodeType != 1);
		return p;
	},

	prependChild: function(parentNode, childNode) {
		return parentNode.insertBefore(childNode, parentNode.firstChild);
	},

	// get the style property (not the computed one) of an elemnt
	// elem - element or name of element
	// property - css property to retrieve
	// return string
	getStyle: function(elem, property) {
		var obj = dom.get(elem);
		if (!obj) {
			return null;
		}
		return obj.style[property];
	},

	// get the computed style property of an elemnt
	getComputedStyle: function(elem, property) {
		var dv = global.document.defaultView,obj = dom.get(elem),val,computed;
		if (!obj) {
			return null;
		}
		if (isIE && obj.currentStyle && obj.currentStyle[property]) { // isIE to workaround broken Opera 9 currentStyle
			val = obj.currentStyle[property];
		}
		else if (dv && dv.getComputedStyle) {
			computed = dv.getComputedStyle(obj, '');
			if (computed && computed[property]) {
				val = computed[property];
			}
		}
		else if (obj.style[property]) {
			val = obj.style[property];
		}
		return val;
	},

	setStyle: setStyle,

	wrapInner: function(el, elType, options) {
		var wrap = global.document.createElement(elType);
		for (var k in options) {
			if (k == "className") {
				dom.addClass(wrap, options[k]);
			}
			else {
				attr(wrap, k, options[k]);
			}
		}

		while(el.childNodes.length > 0) {
			wrap.appendChild(el.childNodes[el.childNodes.length-1]);
		}
		el.appendChild(wrap);

		return wrap;
	},

	cssSupports: (function() {
		var div = global.document? global.document.createElement('div') : null,
		vendors = 'Khtml Ms ms O Moz Webkit'.split(' '), // IE10 has "msFlex" instead of "MsFlex". anyway, checking for both version
		len = vendors.length,
		prop_support = {};

		return function(prop) {
			var curProp;

			if (typeof(prop_support[prop]) != "undefined") {
				return prop_support[prop];
			}

			if (prop in div.style) {
				prop_support[prop] = true;
				return prop_support[prop];
			};

			// uppercase first char, eg. "transition" => "Transition"
			curProp = prop.replace(/^[a-z]/, function(val) {
				return val.toUpperCase();
			});

			// check whether any browser specific variant exists, eg "MsTransition", "MozTransition", etc
			while(len--) {
				if ( vendors[len] + curProp in div.style ) {
					prop_support[prop] = true;
					return prop_support[prop];
				}
			}

			prop_support[prop] = false;
			return prop_support[prop];
		};
	})(),

	// get the position of an element in page coordinates. this method does not take into account any scrolling layer.
	// elem: string ID or element reference
	// return: array [x,y]
	getXY: function(elem, win) {
		if (!win) {
			win = global;
		}

		var em = dom.get(elem),mOL,mOT,mOP,e,D=win.document;
		if (!em || !dom.inDocument(elem, win)) {
			return null;
		}

		// bounding client rect implemented? use it. it is less buggy in IE comparing to offset** method
		if (elem.getBoundingClientRect) {
			var box = elem.getBoundingClientRect(),
				scrollTop = Math.max(D.documentElement.scrollTop, D.body.scrollTop),
				scrollLeft = Math.max(D.documentElement.scrollLeft, D.body.scrollLeft);
			return [box.left + scrollLeft, box.top + scrollTop];
		}

		try {
			mOL=em.offsetLeft;
			mOT=em.offsetTop;
			mOP=em.offsetParent;

			while(mOP){
				mOL+=mOP.offsetLeft
				mOT+=mOP.offsetTop;
				mOP=mOP.offsetParent;
			}
			return [mOL,mOT];
		}
		catch (e) {
			return null;
		}
	},

	// get the x-coordinate of an element in page coordinates
	getX: function(el) {
		return this.getXY(el)[0];
	},

	// get the y-coordinate of an element in page coordinates
	getY: function(el) {
		return this.getXY(el)[1];
	},

	// set the xy coordinate of an abs positioned element
	setXY: function(elem, pos) {
		var em = dom.get(elem);
		if (!em) {
			return null;
		}
		if (!isNull(pos[0])) {
			this.setStyle(em, "left", pos[0]+"px");
		}
		if (!isNull(pos[1])) {
			this.setStyle(em, "top", pos[1]+"px");
		}
	},

	// set the x coordinate of an abs positioned element
	setX: function(el, x) {
		this.setXY(el, [x, null]);
	},

	// set the y coordinate of an abs positioned element
	setY: function(el, y) {
		this.setXY(el, [null, y]);
	},

	// returns whether element has all classes specified
	// elem: string ID, HTML element or array like structure that contains string ID or HTML element
	// className: string with classNames, such as "class1 class2 class3"
	// return: boolean (true - hasClass, false otherwise)
	hasClass: function(elem, className) {
		var obj = dom.get(elem),
			re,
			i,
			names;

		if (!obj) {
			return false;
		}
		// multiple elements? call this method with each of the array item.
		else if (isArray(obj)) {
			for (i = 0; i < obj.length; i ++) {
				if (!this.hasClass(obj[i], className)) {
					return false;
				}
			}
			return true;
		}

		// check whether the name has all names specified in argument
		names = trim(className).split(/\s+/);
		for (i = 0; i < names.length; i ++) {
			re = new RegExp('(?:^|\\s+)' + escapeRegExp(names[i]) + '(?:\\s+|$)');
			if (!re.test(attr(obj, 'className'))) {
				return false;
			}
		}
		return true;
	},

	addClass: function(em, className) {
		var obj = dom.get(em), cls, i;
		if (isArray(obj)) {
			for (i in obj) {
				this.addClass(obj[i], className);
			}
		}
		else {
			if (!obj||this.hasClass(em, className)) {
				return;
			}
			// concatenate className if there is something set.
			cls = "" + attr(obj, "className");
			attr(obj, "className", (cls.length > 0) ? cls + " " + className : className);
		}
	},

	removeClass: function(em, className) {
		var o=dom.get(em), reg = new RegExp('(\\s|^)'+className+'(\\s|$)'), i;
		if (isArray(o)) {
			for (i in o) {
				this.removeClass(o[i], className);
			}
		}
		else {
			if (this.hasClass(em,className)) {
				attr(o, "className", trim(attr(o, "className").replace(reg,' ')));
			}
		}
	},

	toggleClass: function(em, className) {
		if( dom.hasClass(em,className) ) {
			dom.removeClass(em,className);
		} else {
			dom.addClass(em,className);
		}
	},

	replaceClass: function(em, oldClassName, newClassName) {
		this.removeClass(em, oldClassName);
		this.addClass(em, newClassName);
	},

	// set an ID to the specified element if none exists
	generateId: function(elem, prefix) {
		prefix = prefix || 'PSLIB-gen-';
		var obj = dom.get(elem);
		if (obj) {
			if (!obj.id) {
				obj.id = prefix + this.id_counter;
				this.id_counter ++;
			}
			return obj.id
		}
		return null;
	},

	isAncestor: function(H, needle, win) {
		if (!win) {
			win = global;
		}

		H = dom.get(H, win);
		needle = dom.get(needle, win);

		if (!H || !needle) {
			return false;
		}

		if (this.isIE && H.contains) {
			return H.contains(needle);
		}
		else {
			var P = needle.parentNode;
			while (P) {
				if (P == H) {
					return true;
				}
				P = P.parentNode;
			}
			return false;
		}
	},

	inDocument: function(elem, win) {
		if (!win) {
			win = global;
		}
		return this.isAncestor(win.document.documentElement, elem, win);
	},

	// Returns the height of the global.document.
	// @return {Int} The height of the actual global.document (which includes the body and its margin).
	getDocumentHeight: function() {
		var D= global.document,
			scrollHeight=-1,globalHeight=-1,bodyHeight=-1,mode = D.compatMode,
			marginTop = parseInt(dom.getStyle(D.body, 'marginTop'), 10),
			marginBottom,h;
		if (isNaN(marginTop)) {
			marginTop = 0;
		}
		marginBottom = parseInt(dom.getStyle(D.body, 'marginBottom'), 10);
		if (isNaN(marginBottom)) {
			marginBottom = 0;
		}

		if ( (mode || isIE) && !isOpera ) { // (IE, Gecko)
			switch (mode) {
				case 'CSS1Compat': // Standards mode
					scrollHeight = ((global.innerHeight && global.scrollMaxY) ?  global.innerHeight+global.scrollMaxY : -1);
					globalHeight = [D.documentElement.clientHeight,self.innerHeight||-1].sort(function(a, b){return(a-b);})[1];
					bodyHeight = D.body.offsetHeight + marginTop + marginBottom;
					break;

				default: // Quirks
					scrollHeight = D.body.scrollHeight;
					bodyHeight = D.body.clientHeight;
			}
		}
		else { // Safari & Opera
			scrollHeight = D.documentElement.scrollHeight;
			globalHeight = self.innerHeight;
			bodyHeight = D.documentElement.clientHeight;
		}

		h = [scrollHeight,globalHeight,bodyHeight].sort(function(a, b){return(a-b);});
		return h[2];
	},

	// Returns the width of the global.document.
	// @return {Int} The width of the actual global.document (which includes the body and its margin).
	getDocumentWidth: function() {
		var D= global.document,
			docWidth=-1,bodyWidth=-1,winWidth=-1,mode = D.compatMode,
			marginRight = parseInt(dom.getStyle(D.body, 'marginRight'), 10),
			marginLeft, w;
		if (isNaN(marginRight)) {
			marginRight = 0;
		}
		marginLeft = parseInt(dom.getStyle(D.body, 'marginLeft'), 10);
		if (isNaN(marginLeft)) {
			marginLeft = 0;
		}

		if (mode || isIE) { // (IE, Gecko, Opera)
			switch (mode) {
				case 'CSS1Compat': // Standards mode
					docWidth = D.documentElement.clientWidth;
					bodyWidth = D.body.offsetWidth + marginLeft + marginRight;
					winWidth = self.innerWidth || -1;
					break;

				default: // Quirks
					bodyWidth = D.body.clientWidth;
					winWidth = D.body.scrollWidth;
					break;
			}
		}
		else { // Safari
			docWidth = D.documentElement.clientWidth;
			bodyWidth = D.body.offsetWidth + marginLeft + marginRight;
			winWidth = self.innerWidth;
		}

		w = [docWidth,bodyWidth,winWidth].sort(function(a, b){return(a-b);});
		return w[2];
	},

	// other reference
	// http://www.howtocreate.co.uk/tutorials/javascript/browserglobal

	// Returns the current height of the viewport.
	// @return {Int} The height of the viewable area of the page (excludes scrollbars).
	getViewportHeight: function(win) {
		if (!win) {
			win = global;
		}
		var h = -1, D=win.document, mode = D.compatMode;

		if ( (mode || isIE) && !isOpera ) {
			switch (mode) { // (IE, Gecko)
				case 'CSS1Compat': // Standards mode
					h = D.documentElement.clientHeight;
					break;

				default: // Quirks
					h = D.body.clientHeight;
			}
		}
		else { // Safari, Opera
			h = win.innerHeight;
		}

		return h;
	},

	// Returns the current width of the viewport.
	// @return {Int} The width of the viewable area of the page (excludes scrollbars).
	getViewportWidth: function(win) {
		if (!win) {
			win = global;
		}
		var w = -1,D=win.document,mode = D.compatMode;

		if (mode || isIE) { // (IE, Gecko, Opera)
			switch (mode) {
			case 'CSS1Compat': // Standards mode
				w = D.documentElement.clientWidth;
				break;

			default: // Quirks
				w = D.body.clientWidth;
			}
		}
		else { // Safari
			w = win.innerWidth;
		}
		return w;
	},

	getScrollXY: function(win) {
		if (!win) {
			win = global;
		}
		var sx = 0, sy = 0, D=win.document, W=win;
		if( typeof( W.pageYOffset ) == 'number' ) {
			//Netscape compliant
			sy = W.pageYOffset;
			sx = W.pageXOffset;
		}
		else if( D.body && ( D.body.scrollLeft || D.body.scrollTop ) ) {
			//DOM compliant
			sy = D.body.scrollTop;
			sx = D.body.scrollLeft;
		}
		else if( D.documentElement && ( D.documentElement.scrollLeft || D.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			sy = D.documentElement.scrollTop;
			sx = D.documentElement.scrollLeft;
		}
		return [sx, sy];
	},

	createInvisibleDiv: function (id) {
		var em, emS, D= global.document;
		if (id) {
			em = dom.get(id);
			if (em) {
				return em;
			}
		}
		em = D.createElement("div");
		if (id) {
			em.id = id;
		}
		emS = em.style;
		emS.position = "absolute";
		emS.visibility = "hidden";
		emS.top = "-5000px";
		emS.left = "0px";
		emS.width = "50px";
		emS.height = "50px";
		emS.overflow = "hidden";
		D.body.appendChild(em);
		return em;
	},

	preloadImage: function (url) {
		// create the image and put it in a hidden div
		var
			em = this.createInvisibleDiv("PSLIB_preload_div"),
			img = global.document.createElement("img");
		img.src = url;
		em.appendChild(img);
	},

	createScriptTag: createScriptTag,

	// loading external stylesheet
	createStyleSheet: function (url, id, D) {
		D = (typeof(D) == "undefined") ? global.document : D; // Replace the style
		var em,h,e,done=false;

		// If id is 0, it should make the id=0
		if (id !== undefined) {
			if (D.getElementById(id)) {
				return;
			}
		}
		// IE way
		try {
			if (D.createStyleSheet) {
				D.createStyleSheet(url);
				done = true;
				// create dummy style element if id is specified
				if (id !== undefined) {
					em = D.createElement("style");
					em.id = id;
				}
			}
		} catch (e) {};
		if (!done) {
			em = D.createElement("link");
			em.href = url;
			em.rel = "stylesheet";
			em.type = "text/css";
			if (id !== undefined) {
				em.id = id;
			}
		}

		if (em) {
			// tried to attach stylesheet to head
			h = D.getElementsByTagName("head")[0];
			if (!h) {
				h = D.documentElement;
			}
			if (h) {
				h.appendChild(em);
			}
		}
	},

	/**
	 *  Add style to existing style sheet or create a new one.
	 *  You should add style to existing one instead of keep adding style sheet since
	 *  you can create up to 31 stylesheet with createStyleSheet method.
	 *  @param string cssText eg. body {font-size: 10pt}
	 *  @param string id if omitted, a new stylesheet will be created
	 */
	addCssStyle: function(cssText, id, replace, doc) {
		replace = (typeof(replace) != "boolean") ? false : replace; // Replace the style
		doc = (typeof(doc) == "undefined") ? global.document : doc; // Replace the style

		// ID not passed - we'll never append to this stylesheet - so make this way
		var existingStyle = ((typeof(id) != "undefined") ? doc.getElementById(id) : null),
		head = doc.getElementsByTagName('head')[0],
		style = ((existingStyle) ? existingStyle : doc.createElement("style"));

		if (!existingStyle) {
			attr(style, "type", "text/css");
			if (typeof(id) != "undefined") {
				attr(style, "id", id);
			}
		}

		if (style.styleSheet) {
			// If we are not fully replacing the style
			if (!replace) {
				// We can't seem to re-access this data for appending, so we're going to instead keep track of it on a data attribute
				cssText = (attr(style, "data-css-text")) ? attr(style, "data-css-text")+cssText : cssText;
				attr(style, "data-css-text", cssText);
			}

			style.styleSheet.cssText = doc.createTextNode(cssText).nodeValue;
		}
		else {
			// Replace, remove the existing node then recall
			if (replace && style.parentNode) {
				style.parentNode.removeChild(style);
				dom.addCssStyle(cssText, id, false, doc);
			}
			else {
				style.appendChild(doc.createTextNode(cssText));
			}
		}

		if (head && !existingStyle) {
			head.appendChild(style);
		}
	},

	// cross platform way to show/hide TR
	showTr: function (em, visible) {
		dom.get(em).style.display = visible ? (global.document.all ? "block" : ""): "none";
	},

	// set innerHTML of an element. IE6 and firefox have troubles to set
	// innerHTML for elements which is in a form within another form.
	safeSetHtml: function (elem, html) {
		// clean everything in elem
		while (elem.firstChild) {
			elem.removeChild(elem.firstChild);
		}

		// set the content in a new DIV, then move children to destination
		if (html && html.length > 0) {
			var div = global.document.createElement("DIV"), node;
			div.innerHTML = html;
			while (div.firstChild) {
				node = div.firstChild;
				div.removeChild(div.firstChild);
				elem.appendChild(node);
			}
		}
	},

	/**
	 *	form post with target=winname.
	 *	quick helper to post without actual form.
	 *	no automated test.
	 *	but it could be tested in console directly on paget that loads pslib/core.js:
	 *	PSLIB.dom.postToTarget("https://vote.pollstream.com/blank.html", {"dummy":"value"}, "_blank");
	 *	note: popup block may complain .. this method should be used in click event context.
	 *	@param string url
	 *	@param object postdata key-value data
	 *	@param string target eg. "_blank"
	 */
	postToTarget: function (url, postdata, target) {
		var form = document.createElement("form"), input, i;
		form.setAttribute("method", "post");
		form.setAttribute("action", url);
		form.setAttribute("target", target);

		for (i in postdata) {
			if (postdata.hasOwnProperty(i)) {
				input = document.createElement('input');
				input.setAttribute("type", "hidden");
				input.setAttribute("name", i);
				input.setAttribute("value", postdata[i]);
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
};

export default dom;