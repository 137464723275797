
// getting element attribute value or setting element attribute value
// if attr_value is undefined, this function is a getter
// otherwise, it is a setter

import setStyle from './dom/setStyle';

var attr = function(dom_obj, attr_name, attr_value) {
	var UD = "undefined", i;

    if (!dom_obj) {
        return null;
    }

	if (typeof attr_value == UD) { // Getter
        switch (attr_name) {
			case "checked":
				return dom_obj.checked;
			case "selectedIndex":
					return dom_obj.selectedIndex;
			case "textContent":
			case "innerText":
				return dom_obj.innerText || dom_obj.textContent;
			case "class":
				return dom_obj.className;
			default:
				var val = "", tagName = (dom_obj.tagName || "").toLowerCase();
				// We always want to try getAttribute first
				if (!(tagName == "input" && attr_name == "value") && typeof(dom_obj.getAttribute) === "function") {
					val = dom_obj.getAttribute(attr_name);
				}
				// Nothing found from getAttribute, try direct val
				if (!val && typeof dom_obj[attr_name] != UD) {
					val = dom_obj[attr_name];
				}

				return val;
        }
	}
	else { // Setter

		// If we're setting style, and an associative array is passed, collapse the array
		if(attr_name == "style" && typeof(attr_value) == "object" ) {
			for (i in attr_value) {
				setStyle(dom_obj, i, attr_value[i]);
			}
		}
		else if (attr_name == "class") {
		    dom_obj.className = attr_value;
		}
		else if (attr_name == "textContent" || attr_name == "innerText") {
			if (dom_obj && typeof dom_obj.textContent != "undefined") {
				dom_obj.textContent = attr_value;
			}
			else if (dom_obj && typeof dom_obj.innerText != "undefined") {
				dom_obj.innerText = attr_value;
			}
		}
		else if(attr_name == "innerFBML" ) {

			var div = global.document.createElement("DIV"), node;
			div.innerHTML = attr_value;
			while (div.firstChild) {
				node = div.firstChild;
				div.removeChild(div.firstChild);
				dom_obj.appendChild(node);
			}

		}
		else if(attr_name == "selectedIndex" ) {
			dom_obj.selectedIndex = attr_value;
		}
		else if(attr_name == "src" ) {
			dom_obj.src = attr_value;
		}
		else if (typeof dom_obj.setAttribute != UD &&
				attr_name != "checked" && // dont want to handle here since mobile safari has troubles with e.setAttribute('checked', value)
				typeof dom_obj[attr_name] != "string") {
			dom_obj.setAttribute(attr_name, attr_value);
		}
		else if(typeof dom_obj[attr_name] != UD ) {
			dom_obj[attr_name] = attr_value;
		}

	}

	return false;
};

export default attr;