import stopPropagation from './event/stopPropagation';
import preventDefault from './event/preventDefault';


export default function(registeredTarget, dE) {
    // browser event object
    this.domEvent=dE;
    this.registeredTarget=registeredTarget;
    if (dE) {
        this.target=(dE.target)?dE.target:dE.srcElement;
        if (dE['simulatedTarget']) {
            this.target = dE['simulatedTarget'];
        }
        this.type=dE.type;

        // mouse specific
        this.clientX=dE.clientX;
        this.clientY=dE.clientY;
        this.button=dE.button;

        // keyboard specific

        // more for the keyCode, charCode madness on KeyPress event
        // ref: http://unixpapa.com/js/key.html
        // following logic works only for keypress
        if (dE.which == null) {
            this.ch=String.fromCharCode(dE.keyCode);    // IE
        }
        else if (dE.which != 0 && dE.charCode != 0) {
            this.ch=String.fromCharCode(dE.which);	  // All others
        }
        else {
            // special key
            this.ch=null;
        }

        this.keyCode=dE.keyCode;
        this.altKey=dE.altKey;
        this.ctrlKey=dE.ctrlKey;
        this.shiftKey=dE.shiftKey;
    }
    else {
        this.target=registeredTarget;
    }

    this.stopAll = function() {
        stopPropagation(this);
        preventDefault(this);
    }
};