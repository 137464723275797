var isDomElement = function(o) {
    var result = false;
    if (typeof(global.HTMLElement) === "object") {
        // HTMLElement is a function from window object - could be from another documents window object!
        result = (o instanceof global.HTMLElement);
    }

    return result || (o && typeof(o) === "object" && o.nodeType === 1 && typeof o.nodeName==="string");
};

export default isDomElement;