// cloning PHP wordwrap but pCut is not respected now

export default function (pStr, pWidth, pBreak, pCut) {
    var out = "", tmp = new String(pStr);
    do {
        if (out.length > 0) {
            out += pBreak;
        }
        if (tmp.length > pWidth) {
            out += tmp.substring(0, pWidth);
            tmp = tmp.substring(pWidth);
        }
        else {
            out += tmp;
            tmp = "";
        }
    } while (tmp.length > 0);
    return out;
};