var browserUtils = {};

browserUtils.parseUserAgent = (function(agt) {
	/**
	 * [_pslib_browser]: 
	 * Returns an object containing the following information about a browser
	 * - browser 		(Browser name)
	 * - version 		(Browser version)
	 * - os 			(Browser operating system)
	 * - mobile 		(Is it a mobile?)
	 * - gecko 			(Is this a gecko engine)
	 * - geckoVersion 	(engine's version)
	 * 
	 * NOTE 1: BROWSER SNIFFING CALCULATIONS AREN'T RELIABE!
	 * PLEASE READ: https://developer.mozilla.org/en-US/docs/Archive/Using_Web_Standards_in_your_Web_Pages/Developing_cross-browser_and_cross-platform_pages
	 * 
	 * NOTE 2: THIS OBJECT FOLLOWS THE MODULAR PATTERN TO ALLOW CODE REUSE (very useful for individual tests)
	 */
	var _pslib_browser = (function(){

		// Regular expressions for match search against:
		var collRegexp = { 
			// Browsers names and versions  
			browsers: /(chrome\/\w+.\w+|firefox\/\w+.\w+|safari\/\w+.\w+|opera\/\w+.\w+|edge\/\w+.\w+|konqueror\/\w+.\w+|msie \w+\.\w|browser7\/\w+\.\w|iemobile\/\w+\.\w)/,
			// Engines names and versions
			engines: /(gecko\/\d+|trident\/\d+|applewebkit\/\d+|blackberry\/\d+)/,
			// Operating systems names
			os: /(macintosh|ipad|ipod|iphone|windows nt|windows 98|windows phone|linux|blackberry)/,
			// Mobile Environments
			mobileEnv: /(iphone|ipod|ipad|mobile\ssafari|blackberry|opera mini|opera mobi|windows phone|windows ce|nokia|symbian|nintendo|playstation portable|webos|palmos|palmsource|midp|mobile)/,
			// Empty space
			emptySpace: / /gi
		};


		// Utility functions for internal use only
		var _private = {

			// getVersion: function
			/**
			 * [Helper function]
			 * Helps match a regular expression against a string
			 * @param {*} str string in which the search is executed
			 * @param {*} regExp Regular expression containing the match
			 * @param {*} resultInd Index of the result to be returned
			 */
			pslibRegexpSearch: function (str, regExp, resultInd) {
				var collResults = regExp.exec(str);
				return collResults ? collResults[resultInd] : false;
			}, // [end] pslibRegexpSearch


			// Find out:
			// Browser engine's name and version (if there is any)
			getEngineInfo: function(str){
				var engineAndVersion = _private.pslibRegexpSearch(str, collRegexp.engines, 0);
				var ename = false;
				var eversion = false;
				var _nonGeckoName = false;

				if (engineAndVersion) {
					if (engineAndVersion.split('/')[0] === 'gecko') {
						ename = true;
					} else {
						// Findout the name of the engine (we know it's not Gecko)
						_nonGeckoName = engineAndVersion.split('/')[0];
					}
					eversion = engineAndVersion.split('/')[1];
				}
				
				return { gecko:ename, geckoVersion:eversion, otherEngineName:_nonGeckoName };
			}, // [end] getEngineInfo

			
			// Find out:
			// Browser's 'name and version (if there is any)
			getAdditionalInfo: function(str){
				// Browser engine's name and version (if there is any)
				var browserAndVersion = _private.pslibRegexpSearch(str, collRegexp.browsers, 0);
				var _bname 		= 'other';
				var _bversion 	= '';
				var _os 		= '';
				var _mobile	 	= '';

				if(browserAndVersion){ // Extract name and version
					browserAndVersion = browserAndVersion.replace(collRegexp.emptySpace, '/'); // (for IE, replace ' ' by '/')
					var bname1 = browserAndVersion.split('/')[0],
						bversion1 = browserAndVersion.split('/')[1];
						_bname = bname1;
						_bversion = bversion1; 
				} // [end] browserAndVersion
				// If you can't find the name, try at least the version
				else { 
					var bVersion = _private.pslibRegexpSearch(str, /:\w+.\w+/, 0);
					if(bVersion) {
						_bversion = bVersion.replace(':',''); 
					}
				}
			
				_os 	= _private.pslibRegexpSearch(str, collRegexp.os, 0);
				_mobile = _private.pslibRegexpSearch(str, collRegexp.mobileEnv, 0) ? true : false;

				return { browser:_bname, version:_bversion, os:_os, mobile:_mobile };
			}, // [end] getEngineInfo

			/**
			 * Operate final corrections and concatenate "engine info" and "additional info" objects into one
			 */
			getFinalObject: function(str, objEngine, objAddInfo) {

				//-> Corrections for: "non Gecko browsers"
				if(objEngine.otherEngineName) {
					// Label browser as IE if (engine is "trident")
					if(objEngine.otherEngineName==='trident') {
						objAddInfo.browser = 'msie';
					}
				}

				//-> Corrections for: "OS"
				// All "ipad", "ipod", "iphone" are to be considered "macintosh"
				if((/(ipad|ipod|iphone)/).exec(objAddInfo.os)) {
					objAddInfo.os = 'macintosh';
				}
				// All "windows **" are to be considered "win32"
				else if((/(windows nt|windows 98|windows phone)/).exec(objAddInfo.os)) {
					objAddInfo.os = 'win32';
				}
				// All false are to be considered "other"
				else if((/false/).exec(objAddInfo.os) ) {
					objAddInfo.os = 'other';
				}

				//-> Corrections for: "Browser name"
				// Restore browser name as "edge" if listed in the "user agent string"
				var test_bn_edge = (/edge\/\w+.\w+/).exec(str);
				if(test_bn_edge) {
					objAddInfo.browser = 'edge';
					objAddInfo.version = test_bn_edge[0].split('/')[1];
					//Tuncate number to 1 decimal without rounding
					objAddInfo.version = objAddInfo.version.match(/^-?\d+(?:\.\d{0,1})?/)[0];
				}

				objAddInfo.otherEngineName = objEngine.otherEngineName;
				objAddInfo.gecko = objEngine.gecko;
				objAddInfo.geckoVersion = objEngine.geckoVersion;

				return objAddInfo;
			}

		}; // [end] private


		// External functions
		var _public = {
			getUserAgentInfo: function(strUserAgent){
				var _strUserAgent 	= strUserAgent.toLowerCase();
				var engineInfo 		= _private.getEngineInfo(_strUserAgent);
				var addInfo 		= _private.getAdditionalInfo(_strUserAgent);

				return _private.getFinalObject(_strUserAgent, engineInfo, addInfo);
			}
		}; // [end] public

		return _public;
	})(); // [end] _pslib_browser
	return _pslib_browser.getUserAgentInfo(agt);
});

export default browserUtils;