import trim from './util/trim';

const validate = {
    isEmail: function (value) {
        if (value) {
            value = trim(value);
            if (value.length !== 0) {
                // NOTE: when modifying these regular expressions, remember to update the following locations:
                // javascript/pslib/formutils2.js PSLIB.formUtils2.validateEmail()
                // node_packages/pslib-core/src/validate.js validate.isEmail()
                var emailReg1, emailReg2;
                //got these regexp from formutils.js
                emailReg1=/(@.*@)|(\.\.)|(@\.)|(\.@)|(^\.)/; // not valid
                emailReg2=/^[a-zA-Z0-9\_\-\.\+']+\@(\[?)[a-zA-Z0-9\-\.]+\.([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/; // valid
                if ((!emailReg1.test(value) && emailReg2.test(value))) {// if syntax is valid
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }else{
            return false;
        }
    }
};

export default validate;