// array_keys in php
import isArray from './isArray';

export default function(obj) {
    if (Object.keys) {
        return Object.keys(obj);
    }
    else if (isArray(obj)) {
        return [];
    }
    else {
        var a = [], i;
        if (typeof a == "object") {
            for (i in obj) {
                //if (obj.hasOwnProperty(i)) {
                    a.push(i);
                
            }
        }
        return a;
    }
};