import layer from './layer';

import inherits from '../inherits';
import customEvent from '../customEvent';
import callParentConstructor from '../callParentConstructor';
import fx from '../fx';
import dom from '../dom/index';
import browser from '../browser';
import _global from '../_global/index';

const panel = function(id, prop) {
	var F = false, UD = "undefined";

	if (typeof prop == UD) {
		prop = {};
	}

	prop.zIndex = (typeof prop.zIndex != UD ? prop.zIndex : _global.widget.panel.constant.panelZIndex);
	this._visible = F;

	// call constructor
    callParentConstructor(layer, this, id, prop);

	this.hideEvent = new customEvent({});
	this.showEvent = new customEvent({});
	this.centerEvent = new customEvent({});

	this.properties.centerOnViewport = (typeof prop.centerOnViewport != UD ? prop.centerOnViewport : F);
	this.properties.modal = (typeof prop.modal != UD ? prop.modal : F);
	this.properties.showCloseBtn = (typeof prop.showCloseBtn != UD ? prop.showCloseBtn : F);
	this.properties.slidePanel = (typeof prop.slidePanel != UD ? prop.slidePanel : true);
};
inherits(layer, panel);

panel.prototype.setProperty = function(name, value) {
	var panel = _global.widget.panel, // global object for lightbox handling
		UD = "undefined";

	if (!this.element) {
		return false;
	}

	switch (name) {
	case "visible":
		value = value ? true : false;
		if (this._visible !== value) {
			if (this._("modal")) {
				if (value) {
					panel.numModalPanel ++;
				}
				else {
					panel.numModalPanel --;
				}
			}
			if (value) {
				// need to set DIV to visible first before some manipulation
				layer.prototype.setProperty.call(this, name, value);  // we are calling parent method
				this.showEvent.fireEvent();
				if (this._("centerOnViewport")) {
					this.center();
				}
				if (typeof fx != UD && !panel.constant.animationOff) {
					this._fadeIn();
				}
			}
			else {
				if (typeof fx != UD && !panel.constant.animationOff) {
					this._fadeOut();
				}
				else {
					layer.prototype.setProperty.call(this, name, value); // we are calling parent method
					this.hideEvent.fireEvent();
				}
			}
			this._visible = value;
			panel.updateLightBoxDiv();
		}
		break;
	default:
		layer.prototype.setProperty.call(this, name, value);
		break;
	}
};

panel.prototype._fadeIn = function() {
	var anim = [{type:"alpha", start:0, end:1, easing:"easeout"}];
	// the sliding animation cause troubles under IE6
	if (this.properties.slidePanel && !(browser.browser == "msie" && browser.version <= 6)) {
		anim.push({type:"relY", start:-30, end:0, easing:"easeout"});
	}

	fx.animate(
		this.getElement(),
		anim,
		{
			duration: _global.widget.panel.constant.animationDuration,
			queue: "panel_" + this._("id")
		});
}

panel.prototype._fadeOut = function() {
	var y = this._("y"), that = this,
		anim = [{type:"alpha", start:1, end:0, easing:"easeout"}];
	// the sliding animation cause troubles under IE6
	if (this.properties.slidePanel && !(browser.browser == "msie" && browser.version <= 6)) {
		anim.push({type:"relY", start:0, end:-30, easing:"easeout"});
	}
	fx.animate(
		this.getElement(),
		anim,
		{
			duration: _global.widget.panel.constant.animationDuration,
			complete: function() {
				that._s("y", y);
				layer.prototype.setProperty.call(that, "visible", false); // we are calling parent method
				that.hideEvent.fireEvent();
			},
			queue: "panel_" + this._("id")
		});
}

/**
 *	Center the panel in the viewport. Processing is slightly different for mobile browser.
 *
 *	Also, current global may be an iframe and the center function can take care of it
 *	the panel will be horizontally center in iframe but trying to vertical align in parent viewport.
 *	assume no scrolling in iframe
 *
 */
panel.prototype.center = function() {
	var vpW,
		vpH,
		xy,
		scrollXY,
		viewport,
		divW = this.element.offsetWidth,
		divH = this.element.offsetHeight,
		UD = "undefined",
		posX = 0, posY = 0,
		pObj = _global.widget.panel,
		pWin,
		iframePosY,
		iframeH,
		useParent = false,
		centerOnParent = function() {
		    posX = parseInt((dom.getViewportWidth() - divW) / 2 + scrollXY[0]);
		    posX = (posX > 0)? posX : 0;
		    posX = Math.max(posX, 0);

			iframePosY = xy[1];
		    iframeH = dom.getViewportHeight();
		    posY = parseInt(viewport[1] / 2 - iframePosY + scrollXY[1] - divH / 2);
		    if (posY + divH > iframeH) {
				posY = iframeH - divH - 10;
		    }
		    posY = Math.max(posY, 10);
		};

    try {
        useParent = (parent && parent.global.document.getElementById(pObj.constant.centerUseParentFrameId));
    } catch(e) {
    }

	// this one access parent directly to obtain parent info
	if (useParent) {
		// if our widget is in an iframe, centering will take account of it and listen for some parent events
		if (!pObj.hasListenersOnParent) {
			pObj.addResizeScrollListener(parent.global);
			pObj.hasListenersOnParent = true;
		}

		pWin = parent.global;

		scrollXY = dom.getScrollXY(pWin);
		xy = dom.getXY(pWin.document.getElementById(pObj.constant.centerUseParentFrameId), pWin);
		viewport = [dom.getViewportWidth(pWin), dom.getViewportHeight(pWin)];

		centerOnParent();
	}
	// this one use postMesage to communicate with parent. check xdm_sp13.js
	else if (pObj.constant.centerUseMessage && pObj.constant.parentDimension) {
		// someone should call this one, top level publish script?
		// xdm.parentRPC({"func":"ps_bind_resize_and_scroll","src":location.href});

		scrollXY = pObj.constant.parentDimension.scrollXY;
		xy = pObj.constant.parentDimension.xy;
		viewport = pObj.constant.parentDimension.viewport;

		centerOnParent();
	}
	else {
		scrollXY = dom.getScrollXY();

	    // The mobile viewport needs to be calculated differently
	    if (browser.mobile &&
	        typeof(global.innerWidth) != UD &&
	        typeof(global.innerHeight) != UD) {

	        vpW = global.innerWidth;
	        vpH = global.innerHeight;
	    }
	    else {
			vpW = dom.getViewportWidth();
			vpH = dom.getViewportHeight();
	    }

	    posX = parseInt((vpW - divW) / 2 + scrollXY[0]);
	    posY = parseInt((vpH - divH) / 2 + scrollXY[1]);

	    // Don't center to negative values
	    posX = (posX > 0)? posX : 0;
	    posY = (posY > 0)? posY : 0;
	}

    this._s("x", posX);
	// panel is set not to center Y?
	if (!_global.widget.panel.constant.centerXOnly) {
		this._s("y", posY);
	}
	// no. but at least make sure panel in viewport
	else {
		vpH = dom.getViewportHeight();
		if (this._("y") + divH > vpH) {
			this._s("y", vpH - divH - 10);
		}
	}

    this.centerEvent.fireEvent();
};

panel.prototype.moveTo = function(x,y) {
	this._s("x", x);
	this._s("y", y);
};

panel.prototype.show = function() {
	dom.addClass(this.element, 'yui-panel');
	dom.setStyle(
		this.element,
		'display',
		'block'
	);
	dom.setStyle(
		this.element,
		'visibility',
		'visible'
	);
	this.center();
};

panel.prototype.hide = function() {
	dom.setStyle(
		this.element,
		'display',
		'none'
	);
};

panel.prototype.render = function() {
	// call parent
	layer.prototype.render.call(this);

	if (!this.element) {
		return false;
	}

	_global.widget.panel.objects[this._("id")] = this;

	// create the close button
	var header = this.getHeader(),
		that = this;

	this._visible = dom.getStyle(this.element, "display") == "block";

	if (this._("showCloseBtn") && header) {
		var elem = global.document.createElement("DIV");
		elem.className = "psCloseBtn";
		elem.onclick = function() {that._s('visible', false)};
		if (header.firstChild) {
			header.insertBefore(elem, header.firstChild);
		}
		else {
			header.appendChild(elem);
		}
	}
};

export default panel;