var createScriptTag = function (src, id, charset, callback) {
    var D = global.document,
    elem,
    pN,
    loaded = false,
    onloadFunc = function() {
        if (!loaded) {
            loaded = true;
            setTimeout(callback, 1);
        }
    },
    addScriptFunc = function(setSrcFirst) {
        elem = D.createElement("script");
        if (setSrcFirst) {
            elem.src = src;
        }
        if (id) {
            elem.id = id;
        }
        if (charset) {
            elem.setAttribute("charset", charset);
        }
        // set up callback function if any
        if (typeof callback != "undefined") {
            elem.onload = function() { onloadFunc(); };
            elem.onreadystatechange = function() { 
                if ((this.readyState == "loaded") || (this.readyState == "complete")) {
                    onloadFunc();
                }
            };
        }
        // tried to attach to head but malformed page does not have it. put it in body instead.
        pN = D.getElementsByTagName("head")[0];
        if (!pN) {
            pN = D.body;
        }
        pN.appendChild(elem);
        if (!setSrcFirst) {
            elem.src = src;
        }
    };

    if (id) {
        if (D.getElementById(id)) {
            return;
        }
    }

    addScriptFunc(true);

    try {
        // there are situations where the src is not (cannot be) set until the element is appended
        if (elem.src.replace(/^http[s]?:/, "") != src.replace(/^http[s]?:/, "")) {
            // remove the tag
            elem.parentNode.removeChild(elem);
            // create it again but set src afterward
            addScriptFunc(false);
        }
    } 
    catch (e) {
    };
};

export default createScriptTag;