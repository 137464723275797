/** Description:
*      returns a modified q string depending on the following situation:
*      1. q string contains double quotes => remove them
*      2. q string contains space character or colon character => quote the string
*
*      Used in escaping search clauses in Recognition Search (Gallery User Search / Achievement)
* Arguments:
*      str - search string
* Return:
*      string
*/

export default function(str) {
    var s = new String(str);

    // check for double quotes = remove them
    s = s.replace(/"/g, '');

    // check for spaces and colons = quote them
    if (/[:\s]/g.test(s)) {
        s = '"' + s + '"';
    }
    return s;
};