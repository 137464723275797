import serializeArray from './serializeArray';

import attr from '../attr';
import extendObject from '../util/extendObject';
import isObject from '../util/isObject';
import trim from '../util/trim';

// Converts all the child form elements of a root node to a JSON object
//      root - the root node
//      attribute - the attr property to use on the elements when building the array
//		args - additional arguments
//			trimEmpty - Will apply trim to see if value is actually empty
// E.g. dom.toJson(global.document.body) on the http://demo.polldev.com/tests/comment_engines/Testing%3A Polls%2C CE %26 THv1 - Sparkplug2.php page
//
// -> {"charset":"utf-8","doctype":"HTML 4.01 Strict","x_ua_compatible":"","article_id":"05645","article_url":"","language_name":"English","category_id":"10","reply_id":"38307269","description":"","search":{"filter":["ideas","replies","votes"]}}

var patterns = {
    "validate": /^[^\[\]][^\[\]]*(?:\[(?:\d*|[^\[\]]+)\])*$/,
    "key":      /[^\[\]]+|(?=\[\])/g,
    "push":     /^$/,
    "fixed":    /^\d+$/,
    "named":    /^[^\[\]]+$/
};
var build = function(base, key, value){
    base[key] = value;
    return base;
};

var toJson = function(root, attribute, args) {
    var json = {},
    push_counters = {},
    push_counter = function(key){
        if(push_counters[key] === undefined){
            push_counters[key] = 0;
        }
        return push_counters[key]++;
    };

    args = isObject(args) ? args : {};
    args.trimEmpty = (typeof(args.trimEmpty) === "undefined") ? false : args.trimEmpty;

    var serialized = serializeArray(root, attribute, args), obj, name;

    for(var i = 0; i < serialized.length; i++) {
        obj = serialized[i];
        name = (attr(obj, "name") || "");

        // skip invalid keys
        if(!patterns.validate.test(name)){
            // return;
            continue;
        }
        var k,
            keys = name.match(patterns.key),
            merge = attr(obj, "value"),
            reverse_key = obj.name;

        // We are trimming to see if something is actually empty (just newlines, returns, or zero-width space)
        if (args.trimEmpty && trim(merge) === "") {
            merge = "";
        }

        while((k = (keys || []).pop()) !== undefined) {
            // adjust reverse_key
            reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), '');
            // push
            if(k.match(patterns.push)) {
                merge = build([], push_counter(reverse_key), merge);
            }
            // fixed
            else if(k.match(patterns.fixed)) {
                merge = build([], k, merge);
            }
            // named
            else if(k.match(patterns.named)) {
                merge = build({}, k, merge);
            }
        }
        json = extendObject(true, json, merge);
    }
    return json;
};

export default toJson;