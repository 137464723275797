import arrayIndexOf from './arrayIndexOf';

export default function(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arrayIndexOf(arr,what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
};