import loadScript from './loadScript';

const loadScripts = function(srcs, win) {
	win = ((typeof(win) != "undefined")? win : window);
	var stack = [];
	for(var i = 0; i < srcs.length; i++) {
		stack.push(loadScript(srcs[i]), win);
	}
	return global.PSLIB.Promise.all(stack);
};

export default loadScripts;