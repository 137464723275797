/**
 *  Takes markdown and converts it back to proper plaintext (proper newlines, no zero width spaces)
 *
 * @param String    Text to convert
 */

export default function(text) {
    text = (text || "");
    var SOFT_BREAK_PLACEHOLDER = /\r/g,
    ZERO_WIDTH_SPACE = new RegExp('\u200B', "g");

    text = text.replace(SOFT_BREAK_PLACEHOLDER, '');
    text = text.replace(ZERO_WIDTH_SPACE, '');
    text = text.replace(/\n\n/g, '\n');

    text = text.replace(/\\\`/g , '`'); // backtick
    text = text.replace(/\\\*/g , '*');    // asterisk
    text = text.replace(/\\\_/g , '_');  // underscore
    text = text.replace(/\\\}/g , '}');  // curly braces
    text = text.replace(/\\\{/g , '{');  // curly braces
    text = text.replace(/\\\]/g , ']');  // square brackets
    text = text.replace(/\\\[/g , '[');  // square brackets
    text = text.replace(/\\\)/g , ')');  // parentheses
    text = text.replace(/\\\(/g , '(');  // parentheses
    text = text.replace(/\\\#/g , '#');   // hash mark
    text = text.replace(/\\\+/g , '+');  // plus sign
    text = text.replace(/\\\-/g , '-');   // minus sign (hyphen)
    text = text.replace(/\\\./g , '.');   // dot
    text = text.replace(/\\\!/g , '!');   // exclamation mark
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');

    text = text.replace(/\\\\/g , '\\'); // backslash

    return text;
};