export default function(node) {
    var div;
    if (typeof(node) == "string") {
        div = global.document.createElement("div");
        div.innerHTML = node;
        node = div;
    }
    if (typeof(node.textContent) == "string") {
        return node.textContent;
    }
    if (typeof(node.innerText) == "string") {
        return node.innerText;
    }

    return node.innerHTML;
};