import namespace from './namespace.js';

// child copies parent hierarchy
export default (function() {
    var inheritanceData = [];

    var fn = function(p,c,m) {
        if (typeof p == "string") {
            p = namespace(p);
        }
        if (typeof c == "string") {
            c = namespace(c);
        }

        // if the parent file loaded yet. if so, p should be an function.
        // however, parent may not be inherited yet. p.prototype._ is for this checking
        if (typeof p == "function" && p.prototype._) {
            c.prototype = new p();
            c.prototype.constructor = c;
            if (m) {
                for (var j in m) {
                    c.prototype[j] = m[j];
                }
            }
            return true;
        }
        return false;
    };

    return function(parent, child, methods) {
        var newdata = [],
        done = true,
        data = inheritanceData;

        // setting up the inheritance
        if (fn(parent,child,methods)) {
            // okay? try to re-initialize previous one
            while (done && data.length > 0) {
                done = false;
                newdata = [];
                for (var i = 0; i < data.length; i ++) {
                    if (fn(data[i].parent,data[i].child,data[i].methods)) {
                        done = true;
                    }
                    else {
                        newdata.push(data[i]);
                    }
                }
                inheritanceData = newdata;
                data = inheritanceData;
            }
        }
        else {
            // parent is not available yet. queue it.
            data.push({
                parent:parent,
                child:child,
                methods:methods
            });
        }
    };
})();