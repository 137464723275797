// convert unit timestamp to mysql date string (eg. '2008-04-16 13:10:01')

export default function (str) {
    var d = new Date(parseInt(str)*1000);

    var mon = (d.getMonth()+1)+"",
        day = d.getDate()+"",
        hr = d.getHours()+"",
        min = d.getMinutes()+"",
        sec = d.getSeconds()+"";
        mon = (mon.length == 1)? "0"+mon : mon;
        day = (day.length == 1)? "0"+day : day;
        hr = (hr.length == 1)? "0"+hr : hr;
        min = (min.length == 1)? "0"+min : min;
        sec = (sec.length == 1)? "0"+sec : sec;

    var date = d.getFullYear()+"-"+mon+"-"+day+" "+hr+":"+min+":"+sec

    return date;
};