/**
 * @class Provides helper methods for accessibility.
 */

import attr from './attr';
import dom from './dom/index';
import util from './util';
import PS$$ from './PS$$';

const accessibility = function() {
	var liveElem = null,
	getLiveElem = function() {
		// gets existing live region or creates a new one
		if (!liveElem) {
			// get an existing element or create a new one
			if (PS$$("#ps-live-region").length) {
				// found an existing one
				liveElem = PS$$("#ps-live-region")[0];
			}
			else {
				// create a new one
				var div = global.document.createElement("DIV");
				div.id = "ps-live-region";
				div.style.cssText = "clip: rect(0 0 0 0); overflow: hidden; position: absolute; height: 1px; width: 1px;";
				document.body.appendChild(div);
				attr(div, "aria-live", "assertive");
				liveElem = div;
			}
		}
		return liveElem;
	};

	return {
		// focus on an element without scrolling the global
		focusWithoutScrolling: function(elem) {
			if (elem === undefined) {
				// don't need to focus if the element doesn't exist
				return;
			}
			var xy = dom.getScrollXY();
			elem.focus();
			global.scrollTo(xy[0], xy[1]);
		},
		// add a live message
		liveMsg: function(msg) {
			if (msg == "" && getLiveElem.call(this).innerHTML == "") {
				return;
			}
			getLiveElem.call(this).innerHTML = util.htmlSpecialChars(msg);
		}
	}
}();

export default accessibility;