// Merge the contents of two or more objects together into the first object.
//
// extendObject( target [, object1] [, objectN] )
//  target - An object that will receive the new properties if additional objects are passed in or that will extend the jQuery namespace if it is the sole argument.
//  object1 - An object containing additional properties to merge in.
// objectN - Additional objects containing properties to merge in.
//
// extend.extendObject( [deep], target, object1 [, objectN] )
//  deep - If true, the merge becomes recursive (aka. deep copy).
//  target - The object to extend. It will receive the new properties.
//  object1 - An object containing additional properties to merge in.
//  objectN - Additional objects containing properties to merge in.
//
//  See full docs: http://api.jquery.com/jquery.extend/

import type from './type';
import isPlainObject from './isPlainObject';

var extendObject =  function() {
    var options, name, src, copy, copyIsArray, clone,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length,
        deep = false;
    // Handle a deep copy situation
    if ( typeof target === "boolean" ) {
        deep = target;
        target = arguments[1] || {};
        // skip the boolean and the target
        i = 2;
    }
    // Handle case when target is a string or something (possible in deep copy)
    if ( typeof target !== "object" && !(type(target) === "function")) {
        target = {};
    }
    // extend itself if only one argument is passed
    if ( length === i ) {
        target = this;
        --i;
    }
    for ( ; i < length; i++ ) {
        // Only deal with non-null/undefined values
        if ( (options = arguments[ i ]) != null ) {
            // Extend the base object
            for ( name in options ) {
                src = target[ name ];
                copy = options[ name ];
                // Prevent never-ending loop
                if ( target === copy ) {
                    continue;
                }
                // Recurse if we're merging plain objects or arrays
                if (deep && copy && ( isPlainObject(copy) || (copyIsArray = (type(copy) === "array")) ) ) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && (type(src) === "array") ? src : [];
                    } else {
                        clone = src && isPlainObject(src) ? src : {};
                    }
                    // Never move original objects, clone them
                    target[ name ] = extendObject( deep, clone, copy );
                // Don't bring in undefined values
                } else if ( copy !== undefined ) {
                    target[ name ] = copy;
                }
            }
        }
    }
    // Return the modified object
    return target;
};

export default extendObject;