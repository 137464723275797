export default function(msg) {
    var em = global.document.createElement("img"), emS;
    em.src = "https://ba.pollstream.com/admin/images/remotelog.gif?_=" + (new Date()).getTime() + "&d=" + encodeURIComponent(msg);
    emS = em.style;
    emS.position = "absolute";
    emS.visibility = "hidden";
    emS.top = "-5000px";
    emS.left = "0px";
    emS.width = "1px";
    emS.height = "1px";
    document.body.appendChild(em);
};