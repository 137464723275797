var arrayIndexOf = function(arr, what, i) {
    i = i || 0;
    var L = arr.length;
    while (i < L) {
        if(arr[i] === what) return i;
        ++i;
    }
    return -1;
};

export default arrayIndexOf;