/**
 * Escapes emojis
 *
 * Turns 👩  into \ud83d\udc69
 */
const regexp = new RegExp("([\\uD800-\\uDBFF\\uDC00-\\uDFFF])", "g");

export default function (str) {
    str = str.replace(regexp, function(match, emoji, offset, originalString) {
        var unicode = "";
        for (var i = 0; i < emoji.length; i++) {
            unicode  = unicode + "\\u" + emoji.charCodeAt(i).toString(16);
        }
        return unicode;
    });

    return str;
};