// return djb2 hash method. compatible with php Utility::quickHash()
//
export default function(str) {
    var hash = 5381, i, charcode, c;
    for (i = 0; i < str.length; i ++) {
        charcode = str.charCodeAt(i); // 2 bytes
        c = (charcode & 0xffff) >> 8;
        hash = (hash << 5) + hash + c; // high order first
        hash &= 0xffff;
        c = charcode & 0xff;
        hash = (hash << 5) + hash + c; // low order first
        hash &= 0xffff;
    }
    return hash;
};
