var applyIf = function(object, config) {
    var property;

    if (object) {
        for (property in config) {
            if (object[property] === undefined) {
                object[property] = config[property];
            }
        }
    }

    return object;
};

export default applyIf;