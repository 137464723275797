import isObject from './util/isObject';
import isBoolean from './util/isBoolean';
import deparam from './util/deparam';
import param from './util/param';
import parseUri from './util/parseUri';
import attr from './attr';
import globalsettings from './globalsettings';
import browser from './browser';

// Remove pssession from queryString
var sanitizePSSessionQueryString = function(args) {
	// Mantis #27729 - remove pssession from queryArgs
	if (args.url) {
		var parsed_uri = parseUri(args.url),
		queryArgs = deparam(parsed_uri.query);

		// Remove this
		if (queryArgs.pssession) {
			args.data = args.data || {};

			var oldType = args.type || "get";
			args.type = "post";

			//args.headers["Authorization"] = "PSSession "+queryArgs.pssession;
			args.data["pssession"] = queryArgs.pssession;
			args.data["_method"] = oldType;

			delete(queryArgs.pssession);
			args.url = ((parsed_uri.protocol && parsed_uri.protocol.length > 0) ? parsed_uri.protocol+"://"+parsed_uri.host : "")+parsed_uri.path;
			// Manually strip out pssession, because jive uses filter=&filter= which can't be easily parsed and reconstructed
			var query = (typeof(parsed_uri.query) === "string"? parsed_uri.query : "");
			query = query.replace(/^pssession\=[^\&]+\&/, "");
			query = query.replace(/^pssession\=[^\&]+$/, "");
			query = query.replace(/([\&\?]+)pssession\=[^\&]+\&/, "$1");
			query = query.replace(/([\&\?]+)pssession\=[^\&]+$/, "$1");

			if (query && query.length > 0) {
				args.url = args.url + "?"+query;
			}
		}
	}

	return args;
};

var xsQuery = (function() {
	//Returns true if it is a DOM element

	// private variables and methods that we would like to hide
	var requests = {},
		prefix = "PSXSQ_",
		id = (new Date()).getTime() % 1357913, // initialize starting ID number

		// helper functions. trying to reduce xsQuery dependency
		isDOMElement = function(o) {
			var result = false;
			if (typeof(HTMLElement) === "object") {
				// HTMLElement is a function from global object - could be from another documents global object!
				result = (o instanceof HTMLElement);
			}

			return result || (o && typeof(o) === "object" && o.nodeType === 1 && typeof o.nodeName==="string");
		},

		querySelectorAll = function(selector, em) {
			em = isDOMElement(em) ? em : document;
			return (em.querySelectorAll ? em.querySelectorAll(selector) : PS$$(selector, em));
		},

		// execute callback function.
		// private method (probably not execute under context of "this" class but
		// able to access any internal variables.
		// callback - object contains
		//     scope - context of callback function. or in simpler term, when calling the function,
		//             "this" within the function will be equal to scope.
		//     successFunc - function that will be called if "success" argument is true
		//     failureFunc - function that will be called if "success" argument is false
		//     data - if response is an object, data will be added to response.data and response
		//            will be passed to callback function
		// success - indicates which function to call
		// requestId - if response is an object, data will be added to response.requestId and response
		//            will be passed to callback function
		execCallback = function(callback, success, requestId, response) {
			if (callback) {
				var func = success ? callback.successFunc : callback.failureFunc,
					scope = callback.context ? callback.context : callback.scope;
				if (response && typeof callback.data != "undefined" && typeof response == "object") {
					response.data = callback.data;
					response.requestId = requestId;
				}
				if (func) {
					if (scope) {
						func.call(scope, response);
					}
					else {
						func(response);
					}
				}
			}
		},

		// get a requestId (private method)
		getId = function() {
			var requestId = prefix + id;

			// just want to increment the requestId with some randomness
			id += Math.floor(Math.random() * 1000) + 500;
			return requestId;
		},

		// create script tag to query information. (private method)
		createScriptTag = function (src, id) {
			var D= global.document,elem,pN;
			elem = D.createElement("script");
			elem.src = src;
			elem.id = id;
			elem.setAttribute("charset", "utf-8");
			// tried to attach to head but malformed page does not have it. put it in body instead.
			pN=D.getElementsByTagName("head")[0];
			if (!pN) {
				pN = D.body;
			}
			pN.appendChild(elem);
			try {
				// there are situations where the src is not (cannot be) set until the element is appended
				if (elem.src.replace(/^http[s]?:/, "") != src.replace(/^http[s]?:/, "")) {
					// remove the tag
					elem.parentNode.removeChild(elem);
					// create it again
					elem = D.createElement("script");
					elem.id = id;
					elem.setAttribute("charset", "utf-8");
					pN.appendChild(elem);
					elem.src = src;
				}

			} catch(e) {};
		},

		// failing a request (call the failureFunc, cleanup, etc)
		failedRequest = function(requestId, error) {
			if (typeof requests[requestId] != "undefined") {
				requests[requestId].canceled = true;
				execCallback(requests[requestId].callback, false, requestId, {requestId:requestId, error: error});
				xsQuery.cleanUpRequest(requestId);
			}
		},
		createCORSRequest = function(method, url) {
			var xhr = new XMLHttpRequest();

			// Supports latest and greatest XMLHttpRequest object
			if (typeof(xhr.withCredentials) !== "undefined" && typeof(xhr.addEventListener) === "function") {
				xhr.open(method, url, true);
			}
			else {
				// Otherwise, CORS is not supported by the browser.
				xhr = null;
			}

			return xhr;
		},

		createForm = function(o, formAction, target, ssid) {
			var formElem, elem, D = global.document, isChecked = null, selectedIndex = 0,
			data = o.postData || {};

			// This is to support IE6, only way to create a multipart form
			try {
				formElem = D.createElement("<form accept-charset='UTF-8' action='"+formAction+"' target='"+target+"' method='POST' enctype='multipart/form-data'>");
			}
			catch(err) {
				formElem = D.createElement("form");
				formElem.target = target;
				formElem.method = "post";
				formElem.enctype = "multipart/form-data";

				// serverName is available? we are using serversession (ie. coming from post() method)
				// if not, coming from postOnly() method.
				formElem.action = formAction;
				formElem.acceptCharset = "UTF-8";
			}
			formElem.style.position = "absolute";
			formElem.style.width = "0px";
			formElem.style.height = "0px";
			formElem.style.top = "-1000px";

			// If this is a DOM element, just manually move all of its form elements
			if (isDOMElement(data)) {
				var inputs = PS$$("input,textarea,select", data);
				for (var i = 0; i < inputs.length; i++) {
					selectedIndex = 0;
					// Copy the node before moving, so the form doesn't jump around
					try {
						if (inputs[i].tagName.toLowerCase() == "input" &&
							(inputs[i].type == "radio" ||
							inputs[i].type == "checkbox")) {
							isChecked = inputs[i].checked;
						}
						else if (inputs[i].tagName.toLowerCase() == "select") {
							for(var j = 0; j < inputs[i].options.length; j++) {
								if (inputs[i].options[j].selected) {
									selectedIndex = j;
								}
							}
						}
						// Cloning node because type=file does not copy the value, must move
						elem = inputs[i].cloneNode(true);
						inputs[i].parentNode.insertBefore(elem, inputs[i]);
						attr(elem, 'value', attr(inputs[i],'value'));

						if (elem.tagName.toLowerCase() == "input" &&
							(elem.type == "radio" ||
							elem.type == "checkbox")) {
							elem.checked = isChecked;
						}
					} catch(err) {
					}

					// File inputs are special and need to be moved and not copied (you can't copy the file selected!)
					if (attr(inputs[i], 'type') == "file") {
						formElem.appendChild(inputs[i]);
					}
					else {
						formElem.appendChild(elem);
					}
					if (inputs[i].tagName.toLowerCase() == "input" &&
						(inputs[i].type == "radio" ||
						inputs[i].type == "checkbox")) {
						inputs[i].checked = isChecked;
					}
					else if (inputs[i].tagName.toLowerCase() == "select") {
						for(var j = 0; j < inputs[i].options.length; j++) {
							if (j == selectedIndex) {
								inputs[i].options[j].selected = true;
							}
						}
					}
				}
				elem = global.document.createElement("input");
				elem.type = "hidden";
				elem.name = "xs-content-type";
				elem.value = "text/html";
				formElem.appendChild(elem);

				if (ssid) {
					elem = global.document.createElement("input");
					elem.type = "hidden";
					elem.name = "ss_id";
					elem.value = ssid;
					formElem.appendChild(elem);

					elem = global.document.createElement("input");
					elem.type = "hidden";
					elem.name = "_charset_";
					elem.value = "";
					formElem.appendChild(elem);
				}
			}
			// Support not mangling array data
			// Don't want to add things to core/xsquery if they're not used... so I've put it in Request
			else if (global.PSLIB.Request && o.options.wwwFormDataFormat) {
				if (ssid) {
					data.ss_id = ssid;
				}
				data["xs-content-type"] = "text/html";

				var elements = global.PSLIB.Request.dataToInputs(data);
				for(var i = 0; i < elements.length; i++) {
					formElem.appendChild(elements[i]);
				}
			}
			else {
				if (ssid) {
					data.ss_id = ssid;
				}
				data["xs-content-type"] = "text/html";
				data._charset_ = ""; // IE way for charset detection

				// create the hidden elements for form submission
				// checkbox questions with array of data need to be treated differently
				for (name in data) {
					if (name.indexOf("[]") == -1 || typeof data[name] != "object") {
						elem = D.createElement("input");
						elem.type = "hidden";
						elem.name = name;
						elem.value = data[name];
						formElem.appendChild(elem);
					}
					else {
						// checkbox, data[name] is an array
						for (var i = 0; i < data[name].length; i++) {
							elem = D.createElement("input");
							elem.type = "hidden";
							elem.name = name;
							elem.value = data[name][i];
							formElem.appendChild(elem);
						}
					}
				}
			}

			return formElem;
		},
		// post stuffs
		postGetSsidSuccess = function(response) {
			var requestId = response.data,
				o, // hold the request data object
				i,
				// serverName and ssid are available if called by post(), not postOnly()
				serverName = ((typeof response.server_name != "undefined") ? response.server_name : null),
				ssid = ((typeof response.ssid) != "undefined" ? response.ssid : null),

				target = requestId + "_iframe",
				formElem,
				uri,
				elem,
				name,
				data,
				iframe,
				D= global.document,
				div,
				iframeInited = false,
				postLoaded = false,
				// blank pages onload handler
				iframeOnLoad = function() {
					if (!iframeInited) {
						iframeInited = true;
						iframe.onload = postOnLoad;
						iframe.onreadystatechange = iePostOnLoad;
						formElem.submit();
					}

					if (global.event) {
						global.event.returnValue = false;
						global.event.cancelBubble = true;
					}
				},
				ieIframeOnLoad = function() {
					if (this.readyState=="complete") {
						iframeOnLoad();
					}

					global.event.returnValue = false;
					global.event.cancelBubble = true;
				},
				// onload handler for form submission. called when submission done.
				postOnLoad = function() {
					var url,
					o = requests[requestId];
					// IE9 fires both onload and readystatechange but older browser fires only readystatechange.
					// need to check not to execute twice
					if (!postLoaded) {
						postLoaded = true;
						if (ssid) {
							// trying to fetch result of submission
							url = o.protocol + serverName + "/get.php?res=SS_XS&op=get&ss_id="+encodeURIComponent(ssid);
							xsQuery.get(url, {
								scope: this,
								successFunc: postGetDataSuccess,
								failureFunc: postGetDataFailed
							}, {
								timeout: o.options.timeout
							});
						}
						else {
							// from postonly. return now
							if (!o.canceled) {
								execCallback(o.callback, true, requestId, {});
							}
							postCleanUp();
							xsQuery.cleanUpRequest(requestId);
						}
					}

					if (global.event) {
						global.event.returnValue = false;
						global.event.cancelBubble = true;
					}
				},
				iePostOnLoad = function() {
					if (this.readyState=="complete") {
						postOnLoad();
					}
					global.event.returnValue = false;
					global.event.cancelBubble = true;
				},
				// clean up form element and iframe created for submission
				postCleanUp = function() {
					// need a timeout to cleanup and otherwise FF49+ may fail, because cleaning up too fast.
					var f = formElem, d = div;
					setTimeout(function() {
						if (f) {
							f.parentNode.removeChild(f);
						}
						if (div) {
							d.parentNode.removeChild(d);
						}
					}, 100);
				},
				// call back for getData call
				postGetDataSuccess = function(response) {
					if (ssid) {
						response.ssid = ssid;
					}
					if (!o.canceled) {
						execCallback(o.callback, true, requestId, response);
					}
					postCleanUp();
					xsQuery.cleanUpRequest(requestId);
				},
				postGetDataFailed = function() {
					if (!o.canceled) {
						execCallback(o.callback, false, requestId, requestId);
					}
					postCleanUp();
					xsQuery.cleanUpRequest(requestId);
				}
				;

			// unable to locate the request object? nothing more to do. return now.
			if (typeof requests[requestId] == "undefined") {
				return;
			}

			o = requests[requestId];

			// creating the form. form needed before iframe since iframeOnLoad may be called
			// right after the iframe is created.

			// serverName is available? we are using serversession (ie. coming from post() method)
			// if not, coming from postOnly() method.
			var formAction = (serverName ? o.protocol + serverName + o.uri : o.url),
			formElem = createForm(o, formAction, target, ssid);

			D.body.appendChild(formElem);

			// creating the iframe for posting data
			try {
				// IE need to create iframe in this way for target to work
				iframe = D.createElement('<iframe name="'+target+'" encoding="multipart/form-data" enctype="multipart/form-data">');
			}
			catch (_ex) {
				iframe = D.createElement('iframe');
				iframe.encoding = 'multipart/form-data';
				iframe.enctype = 'multipart/form-data';
			}
			iframe.name = target;

			iframe.onload = iframeOnLoad;
			iframe.onreadystatechange = ieIframeOnLoad;
			iframe.src = globalsettings.iframeUrl;

			// ie6 has problems attaching iframe directly to body (some rending problem in some cases, eg. under the Hive)
			// create a div, position it and attach the iframe to this div instead
			div = D.createElement("div");
			div.style.cssText = "width:1px;height:1px;visibility:hidden;position:absolute;overflow:hidden;top:-1000px;left:0px";
			div.appendChild(iframe);

			D.body.appendChild(div);

			// FF2 may not fire onload event for a cached page.
			// use timer event as backup.
			setTimeout(iframeOnLoad, 500);
		},
		postGetSsidFailed = function(requestId) {
			var ssidRequest = requests[requestId],
				postRequest;
			if (typeof ssidRequest != "undefined") {
				postRequest = requests[ssidRequest.callback.data];
				if (typeof postRequest != "undefined") {
					failedRequest(ssidRequest.callback.data);
				}
			}
		};

	return {
		// url to retrieve data.
		// callback: object with
		//     {
		//        successFunc,   // called when request completed successfully
		//        failureFunc,   // called when request failed
		//        progressFunc,  // called when progress is reported
		//        context,       // value passed to callback function
		//		  data			 // data returned in the response object
		//     }
		// options:				 // {
		//                              return_object_always: true // def false
		//                          }
		// return:
		//   if return_object_always in options is not set, result is passed to callback
		//   function as-is. if result is an object, data passed in callback will be attached to
		//	 to the object. So, it is possible that the result is a string and data could not
		//   be returned to callback function. However, it is the default behavior and
		//   it could not be changed.
		//   if "return_object_always" is set, an object {response:actual_result, data: data_from_callback}
		//   will be returned
		get:function(url, callback, options) {
			options = (typeof options == "object" ? options : {});
			// Request should fail after [timeout] number of seconds
			options.timeout = (typeof options.timeout == "number" ? options.timeout : 18000);
			options.cors = (typeof options.cors == "boolean" ? options.cors : false);

			if (url.match(/pssession=/)) {
				var parts = sanitizePSSessionQueryString({url: url});
				options.wwwFormDataFormat = true;

				return xsQuery.post(parts.url, parts.data, callback, options);
			}

			// generate requestId and create script tag for the request
			var requestId = getId();

			requests[requestId] = {
				method: "get",
				time: (new Date()).getTime(),
				canceled: false,
				callback: callback,
				options: options,
				timer: null
			};

			// Cors requests use createCORSRequest instead
			var xhr;
			if (options.cors && (xhr = createCORSRequest("GET", url)) !== null) {
				if (isBoolean(options.withCredentials)) {
					xhr.withCredentials = options.withCredentials; // need to not pass wildcard Access-Control-Allow-Origin in order for it to work
				}

				xhr.addEventListener("progress", function(e) {
					//console.log("--progress", e);
				});
				xhr.addEventListener("loadend", function(e) {
					// If JSON return JSON
					var responseData = this.responseText;
					try {
						responseData = JSON.parse(responseData);
					} catch(e) {
					}

					xsQuery.completedRequest(requestId, {
						result: {
							status: xhr.status,
							data: responseData
						}
					});
				});
				xhr.addEventListener("load", function(e) {
					//console.log("--load", e);
				});
				xhr.addEventListener("error", function(e) {
					failedRequest(requestId, e);
				});
				xhr.addEventListener("abort", function() {
					//console.log("--abort");
				});

				xhr.send();

				return requestId;
			}

			url += ((url.indexOf("?") >= 0) ? "&" : "?") + "requestId=" + requestId;

			// dont know why this timeout is needed for 7968
			if (typeof browser == "object") {
				if (browser.browser == "msie" && browser.version < 8) {
					setTimeout(function(){
						createScriptTag(url, requestId);
						// Put this in because success/failure function was firing at the same time in IE sometimes
						if (options.timeout > 0) {
							requests[requestId].timer = setTimeout(function() {
								failedRequest(requestId);
							}, options.timeout);
						}
					}, 1);
					return requestId;
				}
			}

			createScriptTag(url, requestId);
			// set up a timer. if time is up but completedRequest() is not called, it will be treated as failure
			if (options.timeout > 0) {
				requests[requestId].timer = setTimeout(function() {
					failedRequest(requestId);
				}, options.timeout);
			}

			return requestId;
		},

		// this method uses server sided session to allow posting data to
		// server and getting the result back. however, it requires three
		// requests. If you dont care about the return value, use
		// postOnly() instead.
		//
		// first request: http://server/get.php?res=SS_XS&request_id=REQUEST_ID&op=start
		//                where http://server is extracted from url
		// second request: url&ss_id=SSID, url from argument
		//                (assuming this script stored result to server session with ID returned from first request)
		// third request: http://server/get.php?res=SS_XS&request_id=REQUEST_ID&ss_id=SSID&op=get
		//
		// url to post data.
		// postData object of data to be posted
		// callback: object with
		//     {
		//        successFunc,   // called when request completed successfully
		//        failureFunc,   // called when request failed
		//        context,       // value passed to callback function
		//		  data			 // data returned in the response object
		//     }
		post:function(url, postData, callback, options) {
			options = (typeof(options) != "object") ? {} : options;
			options.timeout = (typeof options.timeout == "number" ? options.timeout : 18000);
			// New data format allows you to send array data with comments in it and not exploding the world
			options.wwwFormDataFormat = (typeof(options.wwwFormDataFormat) != "boolean") ? false : options.wwwFormDataFormat;
			// Allows you to re-write data to an existing SSID
			options.ssid = (typeof(options.ssid) != "undefined") ? options.ssid : null;
			options.cors = (typeof options.cors == "boolean" ? options.cors : false);
			// generate requestId and create script tag for the request
			var requestId = getId(),
			server_url = /^([a-z]+:\/\/[^\/]+)(.*)$/.exec(url)[1];

			requests[requestId] = {
				method: "post",
				time: (new Date()).getTime(),
				url: url,
				protocol: /^([a-z]+:\/\/)/.exec(url)[1],
				ssidUrl: server_url + "/get.php?res=SS_XS",
				uri: /^([a-z]+:\/\/[^\/]+)(.*)$/.exec(url)[2],
				canceled: false,
				postData: postData,
				callback: callback,
				options: options,					// match get() method
				timer: null
			};

			// Cors requests use createCORSRequest instead
			var xhr;
			if (options.cors && (xhr = createCORSRequest("POST", url)) !== null) {
				if (isBoolean(options.withCredentials)) {
					xhr.withCredentials = options.withCredentials; // need to not pass wildcard Access-Control-Allow-Origin in order for it to work
				}

				xhr.setRequestHeader('Content-Type',  'application/x-www-form-urlencoded; charset=UTF-8');
				if (options.headers) {
					postData = (typeof(postData) === "object")? postData : {};
					postData["_headers"] = options.headers;

					for (var key in options.headers) {
						// I don't feel like battling Neil in unblocking the OPTIONS verb, so just fake the headers
						// xhr.setRequestHeader(key, options.headers[key]);
						if (key.match(/^Content-Type$/i) === 'Content-Type' && (options.headers[key] || "").match(/application\/x-www-form-urlencoded/i) === null) {
							options.wwwFormDataFormat = false;
						}
					}
				}

				xhr.addEventListener("progress", function(e) {
					//console.log("--progress", e);
				});
				xhr.addEventListener("loadend", function(e) {
					var responseData = this.responseText;
					try {
						responseData = JSON.parse(responseData);
					} catch(e) {
					}

					xsQuery.completedRequest(requestId, {
						result: {
							status: xhr.status,
							data: responseData
						}
					});
				});
				xhr.addEventListener("load", function(e) {
					//console.log("--load", e);
				});
				xhr.addEventListener("error", function(e) {
					failedRequest(requestId, e);
				});
				xhr.addEventListener("abort", function() {
					//console.log("--abort");
				});

				if (options.wwwFormDataFormat && isObject(postData) && global.PSLIB.Request) {
					postData = global.PSLIB.Request.param(postData);
				}
				xhr.send(postData);

				return requestId;
			}

			// No existing SSID passed
			if (!options.ssid) {
				// fetch ssid
				url = requests[requestId].ssidUrl + "&op=start";

				this.get(url, {
					scope: this,
					successFunc: postGetSsidSuccess, // This function fails in safari
					failureFunc: postGetSsidFailed,
					data: requestId
				}, {
					timeout: options.timeout
				});
			}
			// We've been passed an existing SSID - overwrite any data stored there
			else {
				postGetSsidSuccess({
					server_name: server_url.replace(/http[s]?:\/\//, ''),
					ssid: options.ssid,
					requestId: requestId,
					data: requestId
				});
			}

			return requestId;
		},

		// this method post data to url and you will know the submission
		// is successful or not from callback, but you wont be able to
		// get anything from server. this method is faster than post()
		// method.
		postOnly:function(url, postData, callback) {
			// generate requestId and create script tag for the request
			var requestId = getId();

			requests[requestId] = {
				method: "post",
				time: (new Date()).getTime(),
				url: url,
				canceled: false,
				postData: postData,
				callback: callback,
				options: {},					// match get() method
				timer: null
				};

			postGetSsidSuccess({data:requestId});

			return requestId;
		},

		// this method dispatch the call to get(), post() and postOnly()
		// depending on method.
		// method - "GET", "POST", "POSTONLY"
		request:function(method, url, callback, postData, options) {
			switch (method) {
			case "GET":
				// we can append postData to url here ... but I dont need it and therefore leaving it out for now
				return this.get(url, callback, options);
			case "POST":
				return this.post(url, postData, callback, options);
			case "POSTONLY":
				return this.postOnly(url, postData, callback, options);
			}
			return false;
		},

		cleanUpRequest:function(requestId) {
			if (typeof requests[requestId] != "undefined") {
				if (requests[requestId].timer) {
					global.clearTimeout(requests[requestId].timer);
					requests[requestId].timer = null;
				}

				delete requests[requestId];

				// remove the script tag
				// we may still in the context of the script tag call stack.
				// remove the script tag using setTimeoutf
				setTimeout(function() {
					var o = document.getElementById(requestId);
					if (o) {
						o.parentNode.removeChild(o);
					}
					}, 1);
			}
		},

		// called by the return code from the script
		completedRequest:function(requestId, data) {
			if (typeof requests[requestId] != "undefined") {
				if (requests[requestId].options.return_object_always) {
					data = {response: data};
				}
				execCallback(requests[requestId].callback, true, requestId, data);
				this.cleanUpRequest(requestId);
			}
		}
	};
})();

export default xsQuery;