
// description:
//   implements a simple cache mechanism. able to limit items in cache.
//   if cache has more items in cache, LRU will be removed.
//
// dependency:
//    namequeue.js

import namedQueue from './namedQueue';
import callParentConstructor from './callParentConstructor';
import inherits from './inherits';
import object from './object';

const cache = function (properties) {
    callParentConstructor(object);
	this._queue = new namedQueue();
	this.properties["max_items"] = 500;
	if (typeof properties != "undefined") {
		if (typeof properties["max_items"] != "undefined") {
			this.properties["max_items"] = properties["max_items"];
		}
	}
}
inherits(object, cache);

// get an item from cache. if not available, null is return.
// if item is in queue, it will be moved to end of queue and therefore it will be less
// likely to be removed if cache is full.
cache.prototype.getItem = function (name) {
	name = "" + name;	// name must be string and otherwise namedqueue will not work properly
	var obj = this._queue.getByName(name);
	if (obj) {
		this._queue.removeAt(obj.index);
		this._queue.addToTail(name, obj.value);
		return obj.value;
	}
	else {
		return null;
	}
}

// add an item to cache. existing item with the same name will be removed.
// if cache has items more than allowed (property 'max_items'), oldest entry will be removed.
cache.prototype.addItem = function (name, data) {
	name = "" + name;	// name must be string and otherwise namedqueue will not work properly
	this.removeItem(name);
	this._queue.addToTail(name, data);
	if (this._queue.getLength() > this.getProperty("max_items")) {
		this._queue.removeHead();
	}
}

// remove named item
cache.prototype.removeItem = function (name) {
	name = "" + name;	// name must be string and otherwise namedqueue will not work properly
	this._queue.removeByName(name);
}

// remove all item
cache.prototype.removeAll = function (name) {
	this._queue.removeAll();
}

export default cache;