import callParentConstructor from '../callParentConstructor';
import object from '../object';
import inherits from '../inherits';
import globalsettings from '../globalsettings';
import dom from '../dom/index';
import attr from '../attr';
import browser from '../browser';

const layer = function(id, props) {
	callParentConstructor(object, this);

	var tp = this.properties,N=null,UD="undefined";

	this.id = id;

    tp["global"] = global;
	tp["x"] = 0;
	tp["y"] = 0;
	tp["width"] = 250;
	tp["height"] = N;
	tp["visible"] = true;
	tp["zIndex"] = 100;
	tp["disableIframe"] = false;
	tp["layerType"] = "popup"; // popup or div, div ignore x, y, width, height,
	if (props) {
		tp["id"] = id;
		tp["x"] = (typeof props["x"] == UD) ? tp["x"] : props["x"];
		tp["y"] = (typeof props["y"] == UD) ? tp["y"] : props["y"];
		tp["width"] = (typeof props["width"] == UD) ? tp["width"] : props["width"];
		tp["height"] = (typeof props["height"] == UD) ? tp["height"] : props["height"];
		tp["visible"] = (typeof props["visible"] == UD) ? tp["visible"] : props["visible"];
		tp["zIndex"] = (typeof props["zIndex"] == UD) ? tp["zIndex"] : props["zIndex"];
		tp["disableIframe"] = (typeof props["disableIframe"] == UD) ? tp["disableIframe"] : props["disableIframe"];

        tp["global"] = (typeof props["global"] != "object") ? tp["global"] : props["global"];
		tp["parentNodePosition"] = (typeof(props["parentNodePosition"]) == UD) ? "append" : props["parentNodePosition"];
		tp["parentNode"] = (typeof(props["parentNode"]) == UD)? this._("global").document.body : PS$(props["parentNode"]);
		tp["anchorElement"] = (typeof(props["anchorElement"]) == UD)? null : PS$(props["anchorElement"]);
		tp["layerType"] = (typeof(props["layerType"]) == UD)? "popup" : props["layerType"];

		// the wrapper code
		if( typeof(props.templates) != UD &&
			typeof(props.templates.wrapper) != UD ) {

			var wrapper = dom.getElement( props.templates.wrapper ),
				obj = PS$(tp["id"]);

			var attributes = {};
			// IE only function
			if (typeof(wrapper.mergeAttributes) != "undefined" ) {
				// className gets clobbered
				attributes["class"] = wrapper.className;
				attributes["id"] = attr(wrapper, 'id');
				wrapper.mergeAttributes(obj);
			}
			else if( obj.attributes ) {

				for(var i = 0; i < obj.attributes.length; i++) {
					attributes[obj.attributes[i].nodeName] = obj.attributes[i].nodeValue;
				}
			}

			for(var k in attributes) {
				// Classes are added to, not overwritten
				if( k == "class" ) {
					dom.addClass(wrapper, attributes[k]);
				}
				else {
					attr(wrapper, k, attributes[k]);
				}
			}

			var targetNode = PS$$(props.selector,wrapper);
			for(var i = 0; i < targetNode.length; i++) {
				targetNode[i].innerHTML = obj.innerHTML;
			}
			if( obj.parentNode ) {
				obj.parentNode.replaceChild(wrapper, obj);
			}

			dom.setStyle(wrapper, 'display', 'none');

			this.id = wrapper;
		}

	}
	this.element = N;
	this._iframe = N;
};
inherits(object, layer);

layer.prototype.setProperty = function(name, value) {
	var done = true, elem = this.element;

	if (!elem) {
		switch (name) {
			case "visible":
			case "x":
			case "y":
			case "zIndex":
			case "width":
			case "height":
				return false;
		}
	}

	if (this._("layerType") == "div") {
		switch (name) {
			case "x":
			case "y":
			case "zIndex":
			case "width":
			case "height":
				return false;
		}
	}

	// execute nececssary tasks before calling parent
	switch (name) {
		case "visible":
			value = value ? true : false;
			dom.setStyle(elem, "display", value ? "block" : "none");
			this._s("height", this.properties["height"]);
			break;
		case "x":
			var val = value;
			// only assign px if no unit assigned (like %)
			if ((value || (value+"").length > 0)) {
				value = value+"";
				value = value+((!value.match(/(px|%)/))? "px" : "");
			}
			else {
				value = "";
			}
			dom.setStyle(elem, "left", value);
			value = val;
			break;
		case "y":
			var val = value;
			// only assign px if no unit assigned (like %)
			if ((value || (value+"").length > 0)) {
				value = value+"";
				value = value+((!value.match(/(px|%)/))? "px" : "");
			}
			else {
				value = "";
			}
			dom.setStyle(elem, "top", value);
			value = val;
			break;
		case "zIndex":
			dom.setStyle(elem, "zIndex", ((value || (value+"").length > 0)? value : ""));
			break;
		case "width":
			dom.setStyle(elem, "width", value + "px");
			break;
		case "height":
			if (this.properties["height"] != null) {
				var body = this.getBody(),
					header = this.getHeader(),
					footer = this.getFooter();
				if (body) {
					var height = value;
					if (header) {
						height -= header.offsetHeight;
					}
					if (footer > 0) {
						height -= footer.offsetHeight;
					}
					// take care about the padding under compliant mode
					var bodyHeight = 0;
					if (dom.getStyle(body, "height")) {
						bodyHeight = parseInt(dom.getStyle(body, "height"));
					}
					height -= (body.offsetHeight - bodyHeight);
					dom.setStyle(body, "height", height + "px");
				}
			}
			break;
		default:
			done = false;
			break;
	}

	object.prototype.setProperty.call(this, name, value);
	if (done) {
		this._updateIframe();
		return true;
	}
	else {
		return false;
	}
};

layer.prototype._updateLayer = function (elem, xOffset, yOffset, zIndexOffset) {
	var em=this.element, xy = dom.getXY(em);
	if (!xy) {
		xy = [0,0];
	}
	dom.setXY(elem, [xy[0] + xOffset, xy[1] + yOffset]);
	dom.setStyle(elem, {
		zIndex:parseInt(dom.getStyle(em, "zIndex")) + zIndexOffset,
		width:em.offsetWidth + "px",
		height:em.offsetHeight + "px",
		display:this._("visible") ? "block" : "none"});
};

// update iframe according to the layer
layer.prototype._updateIframe = function() {
	if (this._iframe) {
		var obj = this,
			func = function () {
					obj._updateLayer(obj._iframe, 0, 0, -1);
				};
		if (browser.browser == "msie") {
			// setting style.left and style.top wont be reflected right away
			// in offsetleft and offsettop. so, delay a little bit.
			setTimeout(func, 0);
		}
		else {
			func();
		}
	}
};

layer.prototype.render = function() {
	this.element = PS$(this.id, this._("global"));

	if (!this.element) {
		return false;
	}

	var D=this._("global").document,prop=this.properties,body,em=this.element,br=browser;

	if (this._("layerType") == "popup") {
		if (em.parentNode) {
			em.parentNode.removeChild(em);
		}

		dom.setStyle(em, {
			display:"none",
			position:"absolute"});

		if (prop["parentNodePosition"] == "append") {
			prop["parentNode"].appendChild(em);
		}
		else if (prop["parentNodePosition"] == "prepend") {
			dom.prependChild(prop["parentNode"],em);
		}
	}

	body = this.getBody();
	if (prop["height"] != null && body) {
		if (this._("layerType") == "popup") {
			dom.setStyle(body, "height", "100px"); // set the height now. useful to find out padding to calculate correct height.
		}
	}

	this._s("x", prop["x"]);
	this._s("y", prop["y"]);
	this._s("width", prop["width"]);
	this._s("zIndex", prop["zIndex"]);
	this._s("visible", prop["visible"]);

	// create iframe to put it under div to get around problem of div covered by select drop down problem
	if (this._("layerType") == "popup" && br.browser == "msie" && br.version <= 6 && !this._("disableIframe")) {
		this._iframe = D.createElement("iframe");
		this._iframe.src = globalsettings.iframeUrl;
		dom.setStyle(this._iframe, {
			position:"absolute",
			"border-width":"0px",
			opacity:0.01});			// set opacity on iframe and therefore we could fade in/out the layer.
		this._iframe.frameBorder = 0;

		if (prop["parentNodePosition"] == "append") {
			prop["parentNode"].appendChild(this._iframe);
		}
		else if (prop["parentNodePosition"] == "prepend") {
			dom.prependChild(prop["parentNode"],this._iframe);
		}
		this._updateIframe();
	}

	return true;
};

layer.prototype.getElement = function() { return this.element; }

layer.prototype.getHeader = function() {
	if (this.element) {
		var hdr = PS$$("div.psHeader", this.element);
		return hdr.length > 0 ? hdr[0] : null;
	}
	return null;
};

layer.prototype.getBody = function() {
	if (this.element) {
		var bdy = PS$$("div.psBody", this.element);
		return bdy.length > 0 ? bdy[0] : null;
	}
	return null;
};

layer.prototype.getFooter = function() {
	if (this.element) {
		var ftr = PS$$("div.psFooter", this.element);
		return ftr.length > 0 ? ftr[0] : null;
	}
	return null;
};

export default layer;