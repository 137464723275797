/**
 * Truncates a text string
 *
 * str	the string you wish to truncate
 * args	typeof is number
 * 			see simpleTruncate
 *
 *  	typeof is object
 *  		length		- Desired length of truncated string
 *  		postfix		- when truncation occurs, what would you like appended to the string to indicate truncation?
 *  		boundary	- If specified, will obey this word boundary
 *  		ignoreClass - Ignores any node that have the class
 *  		position    - [start,middle,end] where to perform the truncation
 *  		stripEmpty
 *  			"all"		- Trips all empty tags from the string
 *  			"truncated" - Only strips empty tags if truncation has made them empty, leaves tags that were originally blank alone
 *  			else leaves empty tags alone
 *
 * truncation
 * 		see simpleTruncate
 */

import dom from '../dom/index';
import ObjectAssign from './ObjectAssign';
import simpleTruncate from './simpleTruncate';
import isArray from './isArray';

 const truncate =  (function() {
    var ZERO_WIDTH_SPACE = '\u200B',
    entity = function(str,stupefy){
        var e= global.document.createElement("div");
        e.innerHTML=String(str);

        if (stupefy) {
            return '&#'+e.innerHTML.charCodeAt(0)+';';
        }
        return e.innerHTML;
    };

    var _safeSetHtml = function(domNode, str) {
        if (typeof(dom) != "undefined" && typeof(dom.safeSetHtml) == "function") {
            try {
                dom.safeSetHtml(domNode, str);
            } catch(e) {
            }
        }
        else {
            // This can crash in IE6 if the tag doesnt support innerHTML
            try {
                domNode.innerHTML = str;
            } catch(e) {
            }
        }
    }

    /**
     * Processes a node that has no more children (text node, <br/> node etc)
     *
     * @param object  The DOM node to process
     * @param object
     **/
    var process_no_children_node = function(node, args) {
        var truncateTo = args.truncateTo,
        currentLength = args.currentLength,
        maxLength = (truncateTo - currentLength),
        bounardy = (args.boundary || "");

        // Process non-text node (usually a <br/> tag)
        if (typeof(node.tagName) != "undefined") {
            var nodeContent = (node.textContent || node.innerText || node.innerHTML || ""),
            nodeEmpty = (nodeContent.length == 0) || ((node.tagName || "").toLowerCase() == "br");

            var clonedNode = node.cloneNode(false);

            _safeSetHtml(clonedNode, '');

            return {
                node: clonedNode,
                original_length: 0,
                new_length: 0
            };
        }

        // Process a text node
        var text = (node.nodeValue || ""), original_length = text.length;

        // Text longer than we want
        if (original_length > maxLength) {
            // Flip it
            if (args.position === "start") {
                text = text.split("").reverse().join("");
                bounardy = bounardy.split("").reverse().join("");
            }

            if (maxLength > 0 && bounardy && text.substring(maxLength,maxLength+1) != bounardy) {
                text = text.substr(0,maxLength);
                if (text.lastIndexOf(bounardy) > -1) {
                    text = text.substr(0,text.lastIndexOf(bounardy));
                }
                // Nothin - remove it
                else {
                    text = '';
                }
            }
            // We don't care about word boundary
            else if (maxLength > 0) {
                // TODO, make this use args.position[start,middle,end]
                text = text.substr(0, maxLength);
            }
            // We're throwing this text out
            else {
                text = '';
            }

            // Now flip it back
            if (args.position === "start") {
                text = text.split("").reverse().join("");
                bounardy = bounardy.split("").reverse().join("");
            }
        }

        // This is the new, truncated textnode
        return {
            node: document.createTextNode(text),
            new_length: text.length,
            original_length: original_length
        };
    }

    /**
     * Truncates a DOM element
     *
     * @param object  The DOM node to process
     * @param object
     **/
    var dom_truncate = function(node, args) {
        args.depth = args.depth || 0;
        args.currentLength = args.currentLength || 0;
        args.hasAppliedFix = (typeof(args.hasAppliedFix) !== "undefined")? args.hasAppliedFix : false;

        var currentLength = args.currentLength,
        clonedNode = node.cloneNode(false);

        // This can crash in IE6 if the tag doesnt support innerHTML
        _safeSetHtml(clonedNode, '');

        var i = ((args.position === 'start')? node.childNodes.length-1 : 0),
        childNode, childNodeContent, processedNode, processedNodeContent, process_result;

        while (
                ((args.position === 'start') && i >= 0) ||
                ((args.position === 'end') && i < node.childNodes.length)
            ) {

            childNode = node.childNodes[i];
            if (typeof(args.replace) === "function") {
                childNode = args.replace(childNode);
            }

            // We're ignoring this node entirely
            if (args.ignoreClass && childNode.className === args.ignoreClass) {
                clonedNode.appendChild(childNode.cloneNode(true));

                // Go backwards
                if (args.position === 'start') {
                    i--;
                }
                // Go forwards
                else {
                    i++;
                }
                continue;
            }

            childNodeContent = (childNode.textContent || childNode.innerText || childNode.innerHTML || "");

            // We've hit an empty node!
            if (childNode.childNodes.length === 0) {
                process_result = process_no_children_node(childNode, ObjectAssign({}, args, { currentLength: currentLength, truncateTo: args.length, depth: args.depth }));
                processedNode = process_result.node;

                // Truncation of a non-zero length happened to this text
                // This means it was trimmed, but not blown away.  This means this is our "fix" point.
                if (!args.hasAppliedFix && process_result.new_length != process_result.original_length) {
                    var fix = entity(((args.position === 'end') ? args.postfix : args.prefix));

                    if (args.position === 'end') {
                        processedNode = document.createTextNode((processedNode.nodeValue || "")+fix);
                    }
                    else if (args.position === 'start') {
                        processedNode = document.createTextNode(fix+(processedNode.nodeValue || ""));
                    }
                    args.hasAppliedFix = true;
                }

                currentLength += process_result.original_length;
            }
            // More nodes inside this node to process
            else {
                process_result = dom_truncate(childNode, ObjectAssign({}, args, { currentLength: currentLength, length: args.length, depth: args.depth+1, hasAppliedFix: args.hasAppliedFix }));
                args.hasAppliedFix = args.hasAppliedFix || process_result.hasAppliedFix;
                processedNode = process_result.node;

                currentLength = process_result.currentLength;
            }

            processedNodeContent = (processedNode.textContent || processedNode.innerText || processedNode.innerHTML || "");

            if (
                // Adding this content doesn't push us over a local threshold
                // If we're not stripping all empty tags
                !(processedNodeContent.length === 0 && args.stripEmpty === "all") &&
                // If This tag was truncated to 0 length, and we're not truncated tags
                !(processedNodeContent.length === 0 && args.stripEmpty === "truncated" && processedNodeContent != childNodeContent)
            ) {
                if (args.position === 'start') {
                    dom.prependChild(clonedNode, processedNode);
                }
                else if (args.position === 'end') {
                    clonedNode.appendChild(processedNode);
                }
            }

            // Go backwards
            if (args.position === 'start') {
                i--;
            }
            // Go forwards
            else {
                i++;
            }
        }

        return {
            node: clonedNode,
            currentLength: currentLength,
            hasAppliedFix: args.hasAppliedFix
        };
    };

    return function(str, args, truncation) {
        // This is a simple truncation
        if (typeof(str) == "string" && typeof(args) == "number" && typeof(simpleTruncate) == "function") {
            return simpleTruncate(str, args, truncation);
        }

        args = (typeof(args) != "object")? {} : args;
        args.postfix = (typeof(args.postfix) != "string")? "" : args.postfix;
        args.prefix = (typeof(args.prefix) != "string")? "" : args.prefix;
        args.infix = (typeof(args.infix) != "string")? "" : args.infix;
        args.stripEmpty = (typeof(args.stripEmpty) != "string")? null : args.stripEmpty;
        args.boundary = (typeof(args.boundary) != "string")? null : args.boundary;
        args.ignoreClass = (typeof(args.ignoreClass) != "string")? null : args.ignoreClass;
        args.position = (typeof(args.position) != "string")? "end" : args.position;
        args.length = (typeof(args.length) != "undefined")? args.length : str.length; // Array for infix/position[middle]

        // Calculate total expected string length, see if its over plaintext content length
        var expected_length = 0, div = global.document.createElement('div'), processedStr;
        if (isArray(args.length)) {
            for(var i = 0; i < args.length.length; i++) {
                expected_length += args.length[i];
            }
        }
        else {
            expected_length = args.length;
        }
        _safeSetHtml(div, str);
        processedStr = (div.textContent || div.innerText || div.innerHTML || "");

        const shouldTruncate = processedStr.length > expected_length;

        // No truncation needed.
        if (!shouldTruncate && !args.replace) {
            return str;
        }

        if (args.position === 'middle' && !shouldTruncate) {
            args.position = "end";
        }

        // Middle truncation is a special case
        // Truncate at start/end with half length and join
        if (args.position === 'middle' && shouldTruncate) {
            var beginningLength = (isArray(args.length) ? args.length[0] : Math.floor(args.length/2)),
            endLength = (isArray(args.length) ? args.length[1] : Math.ceil(args.length/2));

            var notruncation = truncate(str, ObjectAssign({}, args, {
                length: str.length,
                prefix: "",
                postfix: "",
                position: "end"
            })),
            beginning = truncate(str, ObjectAssign({}, args, {
                length: beginningLength,
                prefix: "",
                postfix: "",
                position: "end"
            })),
            end = truncate(str, ObjectAssign({}, args, {
                length: endLength,
                prefix: "",
                postfix: "",
                position: "start"
            }));

            // Actual truncation was performed
            if (beginning.length < notruncation.length) {
                // Now figure out what the wrapping tag is (if there is one)
                div = global.document.createElement('div');
                _safeSetHtml(div, str);

                // There was a wrapping node
                // And its not a textNode
                // Get it, remove it from the beginning/end
                if (div.childNodes.length === 1 && typeof(div.childNodes[0].tagName) !== "undefined") {
                    var clonedNode = div.childNodes[0].cloneNode(false);
                    _safeSetHtml(div, beginning);
                    beginning = div.childNodes[0].innerHTML;
                    _safeSetHtml(div, end);
                    end = div.childNodes[0].innerHTML;

                    _safeSetHtml(clonedNode, beginning+((args.infix.length>0)? args.infix : '')+end);
                    _safeSetHtml(div, '');
                    div.appendChild(clonedNode);

                    return div.innerHTML;
                }
                return beginning+((args.infix.length>0)? args.infix : '')+end;
            }
            return notruncation;
        }

        var div = global.document.createElement('div');
        _safeSetHtml(div, str);

        try {
            return dom_truncate(div, args).node.innerHTML;
        }
        catch(e) {
            console.log(e);
        }
        return str;
    };
})();

export default truncate;