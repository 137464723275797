// Used for encoding the selector component in CSS, e.g. #MySelectorComponentWithUnicodeCharacters
// Encodes non alphanumeric characters into exactly 6 hexadecimal digits
// to be used in injected CSS
// e.g. @ -> \000040
// Arguments:
//		str - string to be encoded
//		escapeNonAlphanumeric - whether non-alphanumeric

import leftPad from './leftPad';

export default function(str) {
    // Match all non-unicode characters and numbers
    var s = new String(str);
    return s.replace(/[^\u00BF-\u1FFF\u2C00-\uD7FF\w0-9]/gi, function(match, contents, offset, s) {
        var e = encodeURIComponent(match);
        // Was actually encoded to something that needs to be padded
        if (e.length > 1 && e.match(/^%/)) {
            e = '\\'+leftPad(e.replace(/^%/, ''), 6);
        }
        // No padding, but still escape
        else if (e == '\\') {
            e = '\\\\';
        }
        else {
            e = '\\'+e;
        }

        return e;
    });
};