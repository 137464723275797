
// http://phrogz.net/JS/Classes/OOPinJS.html
// http://www.coolpage.com/developer/javascript/Correct%20OOP%20for%20Javascript.html

// call parent constructor
export default function(parent, obj) {
    // Apply parent's constructor to this object
    if( arguments.length > 2 ) {
        // Note: 'arguments' is an Object, not an Array
        parent.apply( obj, Array.prototype.slice.call( arguments, 2 ) );
    }
    else {
        parent.call( obj );
    }
}