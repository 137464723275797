import util from './util/index';
import browser from './browser';

const connection = {
	pendingResponseCount:0,

	// fetch content using XMLHttpRequest
	// method: GET or POST
	// url: url of remote script
	// postdata: data passed to post request. if headers["Content-Type"] is not specified, postdata is {NAME1:VALUE1,NAME2:VALUE2,...}. otherwise, it should be a string.
	// headers: {"Content-Type":"application/soap+xml; charset=utf-8"}
	// callback: object with
	//     {
	//        successFunc,   // called when request completed successfully
	//        failureFunc,   // called when request failed
	//        context,       // value passed to callback function
	//		  data			 // data returned in the response object
	//     }
	// options - object of options. support "parse_json"
	// where successFunc and failureFunc are callback function with one argument 'response'.
	// response is an object contains the following fields:
	//        responseText,  // response text
	//        responseXML,   // response XML object
	//        status,        // response status code
	//        statusText,    // response status text
	//        headers,       // object of response headers
	//        context        // context passed when calling the get method
	// return a handle useful for abort request
	get:function (method, url, callback, postdata, headers, options) {
		var UD = "undefined", contentTypeSpecified = false, ENC=encodeURIComponent;

		method = method.toUpperCase();
		if (method != "GET" &&
			method != "POST" &&
			method != "PUT" &&
			method != "DELETE") {
			this._debug("connection.get(): unknown method '" + method + "'");
			return false;
		}

		if (typeof callback.context == UD) {
			if (typeof callback.scope != UD) {
				callback.context = callback.scope;
			}
			else {
				callback.context = null;
			}
		}

		if (typeof callback != "object" ||
			typeof callback.successFunc == UD ||
			typeof callback.failureFunc == UD ||
			typeof callback.context == UD) {
			return false;
		}

		var req = this._createRequest();
		if (!req) {
			this._debug("connection.get(): unable to create request object");
			return false;
		}

		var handle = {
			req: req,
			url: url,
			method: method,
			callback: callback,
			options: (typeof options == UD ? {} : options)
		}, key;

		req.open(method, url, true);
		req.onreadystatechange = function() {
			connection._onResponseStateChange(handle);
		}

		if (headers) {
			for (key in headers) {
				req.setRequestHeader(key, headers[key]);
				if (key == "Content-Type") {
					contentTypeSpecified = true;
				}
			}
		}

		var func = function() {
			if (method == "GET") {
				req.send(null);
			}
			else {
				if (!contentTypeSpecified) {
					var body = "";
					if (util.isObject(postdata)) {
						for (key in postdata) {
							var value = postdata[key], v2, i;
							if (util.isArray(value)) {
								for (i = 0; i < value.length; i ++) {
									if (body.length > 0)body += "&";
									body += ENC(key) + "=" + ENC(value[i]);
								}
							}
							else {
								if (body.length > 0)body += "&";
								body += ENC(key) + "=" + ENC(value);
							}
						}
					}
					req.setRequestHeader('Content-Type',  'application/x-www-form-urlencoded; charset=UTF-8');
					req.send(body);
				}
				else {
					req.send(postdata);
				}
			}
		};

		// IE6 executes callback right away if the script is available, unlike all other scripts
		// that wait until current thread finished. Add a timer to force consistent behavior.
		// #8554
		if (browser.browser == "msie" && browser.version < 8) {
			setTimeout(func, 1);
		}
		else {
			func();
		}

		this.pendingResponseCount ++;
		return handle;
	},

	// abort request. handle is the value returned by get()
	abort:function(handle) {
		if (handle && handle.req && handle.req.abort) {
			handle.req.abort();
			handle.req = null;
		}
	},

	// private method. it is called when XMLHttpRequest readyState changed

	_onResponseStateChange:function(handle) {
		var req = handle.req, e;

		// nothing to process until everything loaded
		if (req.readyState < 4) {
			return;
		}

		// prepare the response structure for callback
		var response = {
			responseText:req.responseText,
			responseXML:req.responseXML,
			status:req.status,
			statusText:"",
			headersText:"",
			headers:{},
			context:handle.callback.context,
			data:handle.callback.data,
			result:null,

			getText:function(){return this.responseText;},
			getXML:function(){return this.responseXML;},
			getJSON:function(){return util.parseJson(this.responseText);}
		};

		// following may fail because of broken connection and therefore put in try-catch block
		try {
			response.statusText = req.statusText;
			response.headersText = req.getAllResponseHeaders();
			response.headers = this._createHeaderMap(req.getAllResponseHeaders());
		}
		catch (e) {}

		// calling the callback function now
		if (req.status == 200) {
			if (handle.options.parse_json) {
				try {
					response.result = response.getJSON();
				}
				catch (e) {}
			}
			this.execCallback(handle.callback, true, response);
		}
		else {
			this.execCallback(handle.callback, false, response);
		}

		this.pendingResponseCount --;
		handle = null;
	},

	_createHeaderMap:function(headersText) {
		var
			extractedHeaders = headersText ? headersText.split("\n") : [],
			headerMap = {},
			i,
			head,
			fieldNameEnding,
			field,
			value;
		delete extractedHeaders[extractedHeaders.length]; // Del blank line at end
		for (i=0; i<extractedHeaders.length-2; i++) {
			head = extractedHeaders[i];
			fieldNameEnding = head.indexOf(":");
			field = head.substring(0, fieldNameEnding);
			value = head.substring(fieldNameEnding + 2, head.length);
			value = value.replace(/\s$/, "");
			headerMap[field] = value;
		}
		return headerMap;
	},

	_createRequest:function() {
		var req = null,e;

		// IE7, Firefox, Safari, Opera
		try {
			req = new XMLHttpRequest();
		}
		catch (e) {};

		// IE < 7
		if (!req) {
			try {
				req = new ActiveXObject("Msxml2.XMLHTTP");
			}
			catch (e) {};
		}

		if (!req) {
			try {
				req = new ActiveXObject("Microsoft.XMLHTTP");
			}
			catch (e) {};
		}

		return req;
	},

	_debug:function(msg) {
		if (typeof console != "undefined") {
			if (console.log) {
				console.log(msg);
			}
		}
	},

	execCallback:function(callback, success, response) {
		var func = success ? callback.successFunc : callback.failureFunc,
			scope = callback.context ? callback.context : callback.scope;
		if (func) {
			if (scope) {
				return func.call(scope, response);
			}
			else {
				return func(response);
			}
		}
	},

	_callServiceSuccess:function(response) {
		if (response.data.callback.successFunc) {
			var re = new RegExp('<'+response.data.action+'Result.*>([\\s\\S]*)</'+response.data.action+"Result>", "im"),
				matches = response.responseText.match(re),
				callback = response.data.callback;
			if (matches) {
				response.responseText = util.unHtmlSpecialChars(matches[1]);
				response.data = response.data.callback.data;
				this.execCallback(callback, true, response);
			}
			else {
				this._callServiceFailed(response);
			}
		}
	},

	_callServiceFailed:function(response) {
		this.execCallback(response.data.callback, false, response);
	},

	callService:function(url, action, ns, params, callback) {
		return this.get(
			"POST",
				url,
				{
				context: this,
				data: {
					url:url,
					callback:callback,
					action:action,
					ns:ns,
					params:params},
				successFunc: this._callServiceSuccess,
				failureFunc: this._callServiceFailed
				},
				this._createSoapEnvelope(action, ns, params),
				{"Content-Type":"application/soap+xml; charset=utf-8"},
				callback);
	},

	_createSoapEnvelope: function(action, ns, params) {
		var soap = '<?xml version="1.0" encoding="utf-8"?>\n' +
			'<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">\n' +
			'<soap12:Body>\n';
		soap += '<' + action + ' xmlns="' + ns + '">\n';
		soap += this._parseParameters(params);
		soap += '</' + action + '>\n</soap12:Body>\n</soap12:Envelope>';
		return soap;
	},

	_parseParameters: function(parameters) {
		var params = "", HSC = util.htmlSpecialChars, i;
		if (typeof parameters == 'object') {
			// check if we were provided an array or an object
			if (typeof parameters.push == 'function') {
				for (i = 0, length = parameters.length; i < length; i += 2) {
					params += "<" + parameters[i] + ">" + HSC(parameters[i + 1]) +
				"</" + parameters[i] + ">";
				}
			}
			else {
				for (i in parameters) {
					if (typeof parameters[i] != "function") {
						params += "  <" + i + ">" + HSC(parameters[i]) + "</" + i + ">\n";
					}
				}
			}
		}
		return params;
	}
};

export default connection;