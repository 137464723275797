import browser from '../../browser';
import dom from '../../dom/index';
import fx from '../../fx';
import event from '../../event/index';

import PS$$ from '../../PS$$';

const panel = {
	objects: {},
	constant : {
		panelZIndex: 210,
		lightBoxZIndex: 100,
		lightBoxBackgroundColor: "#7f7f7f",
		lightBoxOpacity: 0.7,
		animationOff: ((browser.mobile)? true : false),
		animationDuration: 250,
		centerUseParentFrameId: false,
		centerUseMessage: false,
		centerXOnly: false
	},
	numModalPanel: 0,
	lightBoxDiv: null,
	selectElems: [],
	visible: false,
	hasListenersOnParent: false,
	parentDimension: null,

	updateLightBoxDiv : function () {
		var obj,
			C = this.constant,
			isIE6 = (browser.browser == "msie" && browser.version <= 6),
			UD = "undefined",
			D = global.document,
			i;

		// create lightbox if not created yet
		if (!this.lightBoxDiv) {
			this.lightBoxDiv = D.createElement("DIV");
			obj = this.lightBoxDiv;
			dom.setStyle(obj, {
				position:"absolute",
				backgroundColor:C.lightBoxBackgroundColor,
				opacity:C.lightBoxOpacity,
				zIndex:C.lightBoxZIndex});
			D.body.appendChild(obj);
			event.bind(global, {
				resize: {context:this,callback:this.updateLightBoxDiv},
				scroll: {context:this,callback:this.updateLightBoxDiv}
				});
		}

		obj = this.lightBoxDiv;

		// show it
		if (this.numModalPanel > 0) {
			var func = function() {
				var	vpW, vpH, scrollXY;

				// The mobile viewport needs to be calculated differently
				// basically a light box to cover everything.
				// actually we try not to use document width height for
				// lightbox since it will affect calculation.
				if (browser.mobile) {
					vpW = dom.getDocumentWidth();
					vpH = dom.getDocumentHeight();
					scrollXY = [0,0];
				}
				else {
					vpW = dom.getViewportWidth();
					vpH = dom.getViewportHeight();
					scrollXY = dom.getScrollXY();
				}

				dom.setStyle(obj, {
					width:vpW + "px",
					height:vpH + "px",
					left:scrollXY[0] + "px",
					top:scrollXY[1] + "px"});
			};

			// update it now
			func();

			// first call may affect scrollbars. call it second time.
			func();

			// currently not visible? show it
			if (!this.visible) {
				// hide all select boxes in the page
				if (isIE6) {
					this.selectElems = [];
					var elems = PS$$("select"), em;

					for (i = 0; i < elems.length; i ++) {
						em = elems[i];
						if (!dom.hasClass(em, "ps-in-panel") && em.style.visibility != "hidden") {
							em.style.visibility = "hidden";
							this.selectElems[this.selectElems.length] = em;
						}
					}
				}

				if (!this.constant.animationOff) {
					dom.setStyle(obj, {opacity:0,display:"block"});
					fx.clearQueue("panel_LightBox");
					fx.animate(
						obj,
						[{type:"alpha", start:0, end:C.lightBoxOpacity, easing:"easeout"}],
						{duation:C.animationDuration,queue:"panel_LightBox"}
						);
				}
				else {
					dom.setStyle(obj, "display", "block");
				}
				this.visible = true;
			}
		}
		// hide it
		else {
			// currently visible? hide it
			if (this.visible) {
				// show select boxes that were hidden before
				if (isIE6) {
					for (i = 0; i < this.selectElems.length; i ++) {
						this.selectElems[i].style.visibility = "visible";
					}
					this.selectElems = [];
				}

				if (!this.constant.animationOff) {
					fx.clearQueue("panel_LightBox");
					fx.animate(
						obj,
						[{type:"alpha", start:C.lightBoxOpacity, end:0, easing:"easeout"}],
						{
							duation:C.animationDuration,queue:"panel_LightBox",
							complete:function() {
								dom.setStyle(obj, {
									width:"1px",
									height:"1px",
									left:"0px",
									top:"0px",
									display:"none"});
							}
						}
						);
				}
				else {
					dom.setStyle(obj, {
						width:"1px",
						height:"1px",
						left:"0px",
						top:"0px",
						display:"none"});
				}

				this.visible = false;
			}
		}
	},

	updatePanels : function (eventType) {
		var id, objs = this.objects;
		for (id in objs) {
			if (objs[id]._) {
				if (objs[id]._("centerOnViewport") &&
					(eventType != "scroll" || !browser.mobile)	// dont scroll for touch devices
					)
				{
					objs[id].center();
				}
			}
		}
	},

	updatePanelsOnResize : function() {
		this.updatePanels("resize");
	},

	updatePanelsOnScroll : function() {
		this.updatePanels("scroll");
	},

	addResizeScrollListener : function(obj) {
		event.bind(
			obj,
			{
				resize:{context:panel,callback:panel.updatePanelsOnResize},
				scroll:{context:panel,callback:panel.updatePanelsOnScroll}
			}
		);
	}
};

panel.addResizeScrollListener(global);

export default panel;