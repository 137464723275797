
// this function is used to pre-populate namespace

var namespace = function(name, v)  {
    var names = name.split("."), o = global.PSLIB, i;
    if (names[0] != "PSLIB") {
        return;
    }
    for (i = 1; i < names.length; i ++) {
    	if (i + 1 == names.length && typeof v != "undefined") {
    		o[names[i]] = v;
    	}
        else if (typeof o[names[i]] == "undefined") {
            o[names[i]] = {};
        }
        o = o[names[i]];
    }
    return o;
};

export default namespace;