// array_values in php

export default function(obj) {
    var a = [], i;
    if (typeof a == "object") {
        for (i in obj) {
            a.push(obj[i]);
        }
    }
    return a;
};