/**
 * Finds text nodes under the root element (el) without using createTreeWalker.
 * This method will not return text nodes under elements that would not be
 * rendered, such as STYLE and SCRIPT tags.
 * Test: http://YOURVM/tests/buster/index.php under test-utils.js, testcase getTextNodesUnder
 * @param Object el - root node element
 * @returns array of found text nodes
 */

const getTextNodesUnderWithoutTreeWalker = function (el) {
    var n, all=[], a=[], walk;
    for (el=el.firstChild;el;el=el.nextSibling){
        if (el.nodeType==3) {
			if (el.parentNode.tagName != "STYLE" &&
				el.parentNode.tagName != "SCRIPT") {
				all.push(el);
			}
		}
        else all = all.concat(getTextNodesUnderWithoutTreeWalker(el));
    }
    return all;
};

export default getTextNodesUnderWithoutTreeWalker;