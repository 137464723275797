import callParentConstructor from './callParentConstructor';
import object from './object';
import inherits from './inherits';

const scriptEngine = function (props) {
	callParentConstructor(object, this);

	this.fetchData = {};
	if (props) {
		var tp = this.properties, UD = "undefined";
		tp["leftDelimiter"] = (typeof props["leftDelimiter"] != UD) ? props["leftDelimiter"] : "[%";
		tp["rightDelimiter"] = (typeof props["rightDelimiter"] != UD) ? props["rightDelimiter"] : "%]";
		tp["template"] = (typeof props["template"] != UD) ? props["template"] : "[[ ERROR: property 'template Not Defined' ]]";
		this._parseTemplate(tp["template"]);
	}
}
inherits(object, scriptEngine);

scriptEngine.prototype.fetch = function (jst) {
	var e;
	this.fetchData = jst;
	try {
		return this._fetch(jst);
	}
	catch (e) {
		if (global.console && global.console.log) {
			global.console.log(e);
			global.console.log(jst);
		}
		return "[[ ERROR: template execution failed - " + e.message + " ]]";
	}
}

scriptEngine.prototype.addSlashes = function (str) {
    var m = new String(str);
    m=m.replace(/\"/g,"\\\"");
    m=m.replace(/\'/g,"\\\'");
    m=m.replace(/\t/g,"\\t");
    m=m.replace(/\r/g,"\\r");
    m=m.replace(/\n/g,"\\n");
    return m;
}

scriptEngine.prototype._parseTemplate = function (str) {
	var pos = 0,
		output = ["this._fetch = function (jst) {\nvar _vv,_output = [];\n"],
		tp = this.properties,
		v,
		e,
		lTrim = function (str) {
			var s=new String(str);
			return s.replace(/^\s*/,"");
		},
		rTrim = function (str) {
			var s=new String(str);
			return s.replace(/\s*$/,"");
		},
		trim = function (str) {
			return lTrim(rTrim(str));
		};

	do {
		// look for start delimiter of the tag
		var nextPos = str.indexOf(tp["leftDelimiter"], pos);

		// output everything as-is before the tag
		if (nextPos < 0) {
			if (str.length > pos) {
				output.push("_output.push(\"" + this.addSlashes(str.substring(pos)) + "\");\n");
			}
			break;
		}
		else if (nextPos > pos) {
			output.push("_output.push(\"" + this.addSlashes(str.substring(pos, nextPos)) + "\");\n");
		}

		// look for end delimiter of the tag
		pos = nextPos;
		nextPos = str.indexOf(tp["rightDelimiter"], pos);

		if (nextPos < 0) {
			this._fetch = function (jpt) {
				return "[[ ERROR: template compilation failed - missing tag closing delimiter ]]";
			}
			return false;
		}

		var tag = str.substring(pos, nextPos + 2);

		if (tag.charAt(2) == "=") {
			// trying to fix [%= locale.getHumanTimeFromNow( jst.record.last_timestamp ); %]
			// that is working before when using += syntax but failing using array string buffer
			v = trim(tag.substring(3, tag.length - 3));
			output.push("_vv="+v+";_output.push(_vv);\n");
		}
		else {
			output.push(tag.substring(2, tag.length - 2) + "\n");
		}
		pos = nextPos + 2;
	} while (pos < str.length);

	output.push("return _output.join('');\n}");
	output = output.join("");

	try {
		eval(output);
	}
	catch (e) {
		if (global.console && global.console.log) {
			global.console.log(e);
			global.console.log(str);
			global.console.log(output);
		}
		this._fetch = function (jpt) {
			return "[[ ERROR: template compilation failed - " + e.message + " ]]";
		}
	}

	return true;
}

scriptEngine.prototype.setProperty = function(name, value) {
	var result = object.prototype.setProperty.call(this, name, value);
	if (name == "template") {
		return this._parseTemplate(value);
	}
	return result;
}

scriptEngine.prototype.getFetchData = function() {
	return this.fetchData;
};

export default scriptEngine;