var parseJson = function (text) {
    var response = false, e;
    try {
        // JSON object is supported by FF3.5+, IE8+, Opera 10.5+ and webkit based browsers.
        // Or using http://www.json.org/json2.js library that we included in our /javascript directory
        response = JSON.parse(text);
    }
    catch (e) {
        try {
            // checks whether text contains invalid character. If not, eval it.
            response = !(/[^,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]/.test(
                    text.replace(/"(\\.|[^"\\])*"/g, ''))) &&
                    eval('(' + text + ')');
        }
        catch (e) {}
    }
    return response;
};

export default parseJson;