
// object class. base class for PSLIB classes

import callParentConstructor from './callParentConstructor';
import simpleObj from './simpleObj';
import inherits from './inherits';

const object = function () {
    callParentConstructor(simpleObj, this);

    if (this != global) { // for some reasons, callParentConstructor will pass window here ... we dont want to create properties on window
        var e;
        try {
            this.properties = {};
        }
        catch (e) {}
    }
}
inherits(simpleObj, object);

object.prototype.getProperty = function(name) {
    return this.properties[name];
}
object.prototype._ = function(name) {
    return this.getProperty(name);
}
object.prototype.setProperty = function(name, value) {
    this.properties[name] = value;
}
object.prototype._s = function(name, value) {
    this.setProperty(name, value);
}

export default object;