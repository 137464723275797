import browser from '../browser';
import eventData from '../eventData';
import attr from '../attr';
import util from '../util/index';

import preventDefault from './preventDefault';
import stopPropagation from './stopPropagation';

const event = new function () {

    return {
        // used by ondomready handling. may use onload as fall back but this var indicate whether DOMContentLoaded event handled already
        _domReadyDone:false,
        _domReadyTimer:null,
        _domReadyCallback:[],
        _domReadySetup:false,

        // event cache to handle memory leak under <= IE6
        _eventCache:[],

        // cache events and they will be cleared upon page unload to deal with memory leak
        // two modes of operations:
        // 1. obj.onsomething = func; - event.cacheEvent(obj, "onsomething", null);
        // 2. eventListener; - event.cacheEvent(obj, "something", func);
        cacheEvent:function(elem, eventName, callbackFunc) {
            this._eventCache[this._eventCache.length] = [elem, eventName, callbackFunc];
        },
        stopEvent: function(ev) {
            ev.stopPropagation();
            ev.preventDefault();
        },
        getTarget: function(ev, resolveTextNode) {
            var t = ev.target || ev.srcElement;
            return PSLIB.event.resolveTextNode(t);
        },
        resolveTextNode: function(node) {
            if (node && 3 == node.nodeType) {
                return node.parentNode;
            } else {
                return node;
            }
        },
        // call in unload
        flushEvents:function() {
            var i, eC = this._eventCache, obj;
            for (i = eC.length - 1; i >= 0; i --) {
                obj = event.getElement(eC[i][0]);
                if (!obj) {
                    continue;
                }
                if (eC[i][0] == global && eC[i][1] == "unload") {
                    eC[i][2]();
                }
                else {
                    if (eC[i][2]) {
                        this.removeListener(eC[i][0], eC[i][1], eC[i][2]);
                    }
                    else {
                        if (obj && typeof obj.setAttribute === "function") {
                            obj.setAttribute(eC[i][1], null);
                        }
                    }
                }
            }
        },

        _domReadyHandler:function(evtData) {
            if (!event._domReadyDone) {
                event._domReadyDone = true;
                for (var i = 0; i < event._domReadyCallback.length; i ++) {
                    event.callHandler(event._domReadyCallback[i], evtData);
                }
            }
        },

        addListener:function(elem, eventName, callbackFunc) {
            var obj = event.getElement(elem), attachedArr = [], attached = false, i, r;

            if (util.isArray(obj)) {
                for (i = 0; i < obj.length; i ++) {
                    attached = this.addListener(obj[i], eventName, callbackFunc);
                    attachedArr.push(attached);
                }
                return attachedArr;
            }
            else if (util.isNull(elem)) {
                return false;
            }
            else if (util.isObject(eventName)) {
                r = true;
                for (i in eventName) {
                    attached = this.addListener(elem, i, eventName[i]);
                    attachedArr.push(attached);
                }
                return attachedArr;
            }
            else if (eventName.toLowerCase() == "domready") {
                this._domReadyCallback.push(callbackFunc);

                if (!this._domReadySetup) {
                    this._domReadySetup = true;
                    var func = function(evt) {
                            event.callHandler(event._domReadyHandler, new eventData(obj, evt));
                        };

                    if (obj.addEventListener) { // all other DOM compatible browsers and IE9
                        attached = obj.addEventListener("DOMContentLoaded", func, false);
                        this.cacheEvent(obj, "DOMContentLoaded", attached);
                    }
                    // idea from http://www.javascriptkit.com/dhtmltutors/domready.shtml
                    // also from http://snipplr.com/view/2337/ondomready/
                    else if (obj.attachEvent){ // IE
                        document.write('<script type="text/javascript" id="PSLIB_contentloadtag" defer="defer" src="//javascript:void(0)"><\/script>');
                        var contentloadtag= global.document.getElementById("PSLIB_contentloadtag");
                        contentloadtag.onreadystatechange=function(evt){
                            if (this.readyState=="complete"){
                                event.callHandler(event._domReadyHandler, new eventData(obj, global.event));
                            }
                        }
                        this.cacheEvent(contentloadtag, "onreadystatechange", null);
                    }

                    // use onload handler in case browser does not support DOMContentLoaded
                    this.__addListenerSub(obj, "load", func);
                }
            }
            else if (eventName.toLowerCase() == "transitionstart" ||
                        eventName.toLowerCase() == "transitionend" ||
                        eventName.toLowerCase() == "animationstart" ||
                        eventName.toLowerCase() == "animationend") {
                eventName = eventName.toLowerCase();
                var el = global.document.createElement('fakeelement'),
                props = {
                    transitionstart: {
                        'transition':'transitionstart',
                        'OTransition':'oTransitionStart',
                        'MozTransition':'transitionstart',
                        'WebkitTransition':'webkitTransitionStart'
                    },
                    transitionend: {
                        'transition':'transitionend',
                        'OTransition':'oTransitionEnd',
                        'MozTransition':'transitionend',
                        'WebkitTransition':'webkitTransitionEnd'
                    },
                    animationstart: {
                        'transition':'animationstart',
                        'OTransition':'oAnimationStart',
                        'MozTransition':'animationstart',
                        'WebkitTransition':'webkitAnimationStart'
                    },
                    animationend: {
                        'transition':'animationend',
                        'OTransition':'oAnimationEnd',
                        'MozTransition':'animationend',
                        'WebkitTransition':'webkitAnimationEnd'
                    }
                };

                for(var t in props[eventName]){
                    if( el.style[t] !== undefined ){
                        eventName = props[eventName][t];
                        // TEMP FIX FOR TRANSITIONS
                        // For some reason __addListenerSub further wraps the callback in yet another anonymous function
                        // This makes it unreasonably difficult/impossible to use removeEventListener - bypass for these
                        return elem.addEventListener(eventName, callbackFunc, false);
                    }
                }
                return this.__addListenerSub(elem, eventName, callbackFunc, false);
            }
            // Paste event support
            // Adapted from
            // https://github.com/spicyj/jquery-splendid-textchange/blob/master/jquery.splendid.textchange.js
            else if (eventName.toLowerCase() == "input") {
                var testNode = global.document.createElement("input"),
                isFullInputSupported =  (("oninput" in testNode) &&
                                    (!("documentMode" in document) || document.documentMode > 9));

                // Native support
                if (isFullInputSupported) {
                    return this.__addListenerSub(elem, eventName, callbackFunc, true);
                }

                return this.__emulateInputEvent.call(this, elem, eventName, callbackFunc);
            }
            // Support bubbling change events
            else if (!event.__support.changeBubbles && eventName.toLowerCase() == "change") {
                this.__emulateChangeEvent.call(this, elem, eventName, callbackFunc);
            }
            // Support bubbling focusin / focusout events
            else if (!event.__support.focusinBubbles &&
                        (eventName.toLowerCase() == "focusin" || eventName.toLowerCase() == "focusout")) {

                // Only want one bind for simulating the events
                if (!this._initedSimulatingFocusinFocusOut) {
                    var events = {
                        'focus': 'focusin',
                        'blur': 'focusout'
                    };

                    for (var i in events) {
                        (function(realEvent, emulatedEvent) {
                            document.addEventListener(realEvent, function(e) {
                                event.simulateEvent.call(this, {
                                    target: e.target,
                                    registeredTarget: elem
                                }, emulatedEvent);
                            }, true);
                        })(i, events[i]);
                    }
                    this._initedSimulatingFocusinFocusOut = true;
                }

                this.__addListenerSub(elem, eventName, function(e) {
                    // HACK - Stop chrome from firing the event twice
                    if (e.domEvent.eventName) {
                        callbackFunc(e);
                    }
                }, true);
            }
            else {
                return this.__addListenerSub(elem, eventName, callbackFunc, true);
            }
        },
        _addEventMouseEnter:function(_fn) {
            return function(_evt) {
                var relTarget = _evt.relatedTarget;
                if (this === relTarget || event.isAChildOf(this, relTarget)) {
                    return;
                }
                _fn.call(this, _evt);
            }
        },
        isAChildOf:function(_parent, _child) {
            if (_parent === _child) { return false; }
                while (_child && _child !== _parent)
            { _child = _child.parentNode; }
            return _child === _parent;
        },
        __emulateChangeEvent: function(elem, eventName, callbackFunc) {
            var that = this,
            rformElems = /^(?:input|select|textarea)$/i;

            // We don't want to emulate change events for these elements - use regular
            if (rformElems.test(elem.nodeName)) {
                // IE doesn't fire change on a check/radio until blur; trigger it on click
                // This still fires onchange a second time for check/radio after blur.
                if (elem.type === "checkbox" || elem.type === "radio") {
                    that.__addListenerSub(elem, "propertychange", function(e) {
                        if (e.domEvent.propertyName === "checked") {
                            elem._just_changed = true;
                        }
                    }, true);
                    that.__addListenerSub(elem, "click", function(e) {
                        if (elem._just_changed && !e.domEvent['simulatedTarget']) {
                            elem._just_changed = false;
                        }
                        event.simulateEvent.call(e, {
                            target: e.target,
                            registeredTarget: e.target
                        }, 'change');
                    }, true);
                }

                // Add regular sublistener
                that.__addListenerSub(elem, eventName, callbackFunc, true);

                return false;
            }

            event.bind(elem, "beforeactivate", function(e) {
                // Cancels checkbox/radio default so blur doesn't fire
                event.returnValue = !(e.target && (e.target.type === "checkbox" || e.target.type === "radio"));

                if (rformElems.test(e.target.nodeName) && !e.target.onChangeBound) {
                    e.target.onChangeBound = true;
                    event.bind(e.target, "change", function(event) {
                        if (event.target.parentNode && !event.domEvent['simulatedTarget']) {
                            // Simulate change event on the original bound target
                            event.simulateEvent.call(event, {
                                target: e.target,
                                registeredTarget: e.target
                            }, 'change');
                        }
                        // This is our simulated change event - fire callback
                        else if (event.domEvent['simulatedTarget']) {
                            event.registeredTarget = elem;
                            callbackFunc.call(that, event);
                        }
                    });
                }
            });
        },
        __emulateInputEvent: function(elem, eventName, callbackFunc) {
            (function(that, elem, eventName, callbackFunc) {

            var currVal = "",
            currElem = null,
            testNode = global.document.createElement("input"),
            isInputSupported = ("oninput" in testNode),
            handleOnPropertyChange = function(e) {
                if (e.propertyName === "value") {
                    var elemVal = attr(e.srcElement, 'value');
                    if (currVal !== elemVal) {
                        currVal = elemVal;

                        (function(pslibEvent) {
                        setTimeout(function() {
                            pslibEvent.registeredTarget = elem;
                            callbackFunc(pslibEvent);
                        }, 0);
                        })(new eventData(e.srcElement, e));
                    }
                }
            },
            handleSelectionChange = function(e) {
                var elemVal = attr(currElem, 'value');
                if (currVal !== elemVal) {
                    currVal = elemVal;
                    callbackFunc(new eventData(currElem, e));
                }
            };

            // Probably IE9
            if (isInputSupported) {
                that.__addListenerSub(elem, "input", function(e) {
                    currElem = e.target;
                    var elemVal = attr(currElem, 'value');
                    if (currVal !== elemVal) {
                        currVal = elemVal;

                        callbackFunc(e);
                    }
                }, true);

                event.bind(elem, "focusin", function(e) {
                    currElem = e.target;
                    // IE9 sucks - it doesn't fire for backspace/cut events
                    document.addEventListener("selectionchange", handleSelectionChange, false);
                }, false);

                event.bind(elem, "focusout", function(e) {
                    // IE9 sucks - it doesn't fire for backspace/cut events
                    document.removeEventListener("selectionchange", handleSelectionChange);
                    curElement = null;
                }, false);
            }
            // Old browsers
            else {
                event.bind(elem, "focusin", function(e) {
                    currElem = e.target;
                    e.target.attachEvent("onpropertychange", handleOnPropertyChange);
                }, false);

                event.bind(elem, "focusout", function(e) {
                    e.target.detachEvent("onpropertychange", handleOnPropertyChange);
                    curElement = null;
                }, false);
            }

            })(this, elem, eventName, callbackFunc);
        },
        __addListenerSub:function(elem, eventName, callbackFunc, cacheWindowUnloads) {
            var obj = event.getElement(elem), attached = false;
            if (!obj) {
                return false;
            }

            var wrapfunc = function(evt) {
                var realEvent = evt || global.event;
                return event.callHandler(callbackFunc, new eventData(obj, realEvent));
            },
            userAgent = ((typeof(global) !== "undefined" && typeof(global.navigator) !== "undefined" && typeof(global.navigator.userAgent) !== "undefined")? global.navigator.userAgent : ""),
            mousewheelevt=(/Firefox/i.test(userAgent))? "DOMMouseScroll" : "mousewheel";

            if (obj.addEventListener) { // DOM browsers and IE9

                if (eventName.toLowerCase() == "mousewheel") {
                    attached = obj.addEventListener(mousewheelevt, event._addEventMouseEnter(callbackFunc), false);
                }
                if (eventName === "mouseenter") {
                    attached = obj.addEventListener('mouseover', event._addEventMouseEnter(callbackFunc), false);
                }
                else if (eventName === "mouseleave") {
                    attached = obj.addEventListener('mouseout', event._addEventMouseEnter(callbackFunc), false);
                }
                else if (obj == global && eventName == "unload") {
                    if (cacheWindowUnloads) {
                        this.cacheEvent(obj, eventName, wrapfunc);
                    }
                    else {
                        obj.addEventListener(eventName, wrapfunc, false);
                    }
                }
                else {
                    attached = obj.addEventListener(eventName, wrapfunc, false);
                    this.cacheEvent(obj, eventName, wrapfunc);
                }
                return attached;
            }
            else if(obj.attachEvent){ // older IE
                if (eventName.toLowerCase() == "mousewheel") {
                    obj.attachEvent("on"+mousewheelevt, wrapfunc);
                }
                else if (obj == global && eventName == "unload") {
                    if (cacheWindowUnloads) {
                        this.cacheEvent(obj, eventName, wrapfunc);
                    }
                    else {
                        obj.attachEvent("on"+eventName, wrapfunc);
                    }
                }
                else {
                    obj.attachEvent("on"+eventName, wrapfunc);
                    this.cacheEvent(obj, eventName, wrapfunc);
                }
                return true;
            }
            return false;
        },

        bind:function(elem, eventName, callbackFunc) {
            return this.addListener(elem, eventName, callbackFunc);
        },

        ready:function(callbackFunc) {
            return this.addListener(global, "domready", callbackFunc);
        },

        click:function(elem, callbackFunc) {
            return this.addListener(elem, "click", callbackFunc);
        },

        removeListener:function(elem, eventName, callbackFunc) {
            var o = event.getElement(elem);

            if (!o) {
                return false;
            }

            if(o.removeEventListener){
                eventName = eventName.toLowerCase();
                var props = {
                    transitionstart: {
                        'transition':'transitionstart',
                        'OTransition':'oTransitionStart',
                        'MozTransition':'transitionstart',
                        'WebkitTransition':'webkitTransitionStart'
                    },
                    transitionend: {
                        'transition':'transitionend',
                        'OTransition':'oTransitionEnd',
                        'MozTransition':'transitionend',
                        'WebkitTransition':'webkitTransitionEnd'
                    },
                    animationstart: {
                        'transition':'animationstart',
                        'OTransition':'oAnimationStart',
                        'MozTransition':'animationstart',
                        'WebkitTransition':'webkitAnimationStart'
                    },
                    animationend: {
                        'transition':'animationend',
                        'OTransition':'oAnimationEnd',
                        'MozTransition':'animationend',
                        'WebkitTransition':'webkitAnimationEnd'
                    }
                };

                for(var t in props[eventName]){
                    if( o.style[t] !== undefined ){
                        eventName = props[eventName][t];
                    }
                }

                return o.removeEventListener(eventName, callbackFunc, false);
            }
            else if(o.detachEvent){
                return o.detachEvent("on"+eventName, callbackFunc);
            };

            return false;
            // should remove from event cache
        },

        relatedTarget:function (ed) {
            if (ed.domEvent) {
                return (ed.domEvent.relatedTarget ? ed.domEvent.relatedTarget : ed.domEvent.toElement);
            }
        },

        preventDefault: preventDefault,
        stopPropagation: stopPropagation,

        stopImmediatePropagation:function (ed) {
            if (!ed.domEvent.stopImmediatePropagation) {
                ed.domEvent.isImmediatePropagationEnabled = false;
                ed.domEvent.cancelBubble = true;
            }
            else {
                ed.domEvent.stopImmediatePropagation();
            }
        },
        getElement: function(em) {
            var o,arr,i,D= global.document,N=null,UD="undefined";
            // return if em contains nothing
            if (!em) {
                return N;
            }
            // is em a string? if so, look up the element by ID
            if (util.isString(em)) {
                return D.getElementById(em);
            }
            // is em an array or nodelist? if so, look up each array element
            // okay. under IE, global object has both item and length property and so we check some properties that
            // for sure wont be in nodelist
            else if (util.isArray(em) ||
                (typeof em.tagName == UD && typeof em.document == UD && typeof em.item != UD && typeof em.length != UD)) {
                arr = [];
                for (i = 0; i < em.length; i ++) {
                    o = PS$(em[i]);
                    if (o) {
                        arr.push(o);
                    }
                }
                return arr;
            }
            // nothing else? assuming it is an element reference
            else {
                return em;
            }
            return N;
        },
        callHandler : function(callback, handlerData) {
            if (util.isFunction(callback)) {
                return callback(handlerData);
            }
            else if (util.isObject(callback)) {
                if (callback.callback) {
                    var scope = callback.scope ? callback.scope : (callback.context ? callback.context : (callback.obj ? callback.obj : null));
                    if (util.isNull(handlerData)) {
                        handlerData = typeof callback.data == "undefined" ? null : callback.data;
                    }
                    else {
                        if (typeof handlerData == "undefined") {
                            handlerData = {};
                        }
                        if (util.isObject(handlerData) && callback.data) {
                            handlerData.data = callback.data;
                        }
                    }

                    if (scope) {
                        return callback.callback.call(scope, handlerData);
                    }
                    else {
                        return callback.callback(handlerData);
                    }
                }
            }
            return false;
        }
    }
}
if (typeof(global) !== "undefined") {
    event.__addListenerSub(global, "unload", function() { event.flushEvents(); }, false);
}

// Check event for browser support for focusin focusout bubbling
event.__support = (function() {
    var support = {
        submitBubbles: true,
        changeBubbles: true,
        focusinBubbles: false
    },
    div = (typeof(global) !== "undefined" && typeof(global.document) !== "undefined"? global.document.createElement("div") : null), i, eventName, isSupported;

    if (div && div.attachEvent) {
        for (i in {
            submit: 1,
            change: 1,
            focusin: 1
        }) {
            eventName = "on" + i;
            isSupported = ( eventName in div );
            if ( !isSupported ) {
                div.setAttribute( eventName, "return;" );
                isSupported = ( typeof div[ eventName ] === "function" );
            }
            support[ i + "Bubbles" ] = isSupported;
        }
    }

    return support;
})();

/**
 * Simulate an event that isn't natively supported
 *
 * e - The original event
 * eventName - the name of the new event
 */
event.simulateEvent = function(originalEvent, emulatedEventName) {
    var event;
    try {

    if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(emulatedEventName, true, true);
    } else {
        event = document.createEventObject();
        event.eventType = emulatedEventName;
    }
    if (originalEvent.target) {
        event['simulatedTarget'] = originalEvent.target;
    }

    event.eventName = emulatedEventName;

    if (document.createEvent) {
        originalEvent.registeredTarget.dispatchEvent(event);
    } else {
        originalEvent.registeredTarget.fireEvent("on" + event.eventType, event);
    }

    } catch(e) {
    }
};

export default event;