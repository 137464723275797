import namespace from './namespace';
import inherits from './inherits';
import object from './object';
import classLoader from './classLoader';
import util from './util/index';
import dom from './dom/index';
import attr from './attr';
import validate from './validate';
import date from './date';
import event from './event/index';
import cache from './cache';
import customEvent from './customEvent';
import namedQueue from './namedQueue';
import callParentConstructor from './callParentConstructor';
import scriptEngine from './scriptEngine';
import browserUtils from './browserUtils';
import browser from './browser';
import loadScript from './loadScript';
import loadScripts from './loadScripts';
import connection from './connection';
import animation from './animation';
import fx from './fx';
import xsQuery from './xsQuery';

import widget from './widget/index';
import placeholder from './placeholder';
import growTextarea from './growTextarea';
import growTextareas from './growTextareas';
import captionlessInput from './captionlessInput';
import accessibility from './accessibility';
import copyToClipboard from './copyToClipboard';

import globalsettings from './globalsettings';
import _global from './_global/index';
import appdata from './appdata';

export default {
    _global,
    globalsettings,
    namespace,
    inherits,
    object,
    callParentConstructor,
    classLoader,
    scriptEngine,
    util,
    dom,
    attr,
    validate,
    date,
    event,
    cache,
    customEvent,
    namedQueue,
    browserUtils,
    browser,
    loadScript,
    loadScripts,
    connection,
    animation,
    fx,
    xsQuery,
    widget,
    placeholder,
    growTextarea,
    growTextareas,
    accessibility,
    captionlessInput,
    copyToClipboard,
    appdata,
};
