
let appdata = (global.PSLIB && global.PSLIB.appdata)? global.PSLIB.appdata : {};

appdata.loadedLibs = (appdata.loadedLibs)? appdata.loadedLibs : {};
appdata.config = (appdata.config)? appdata.config : {
    useGzip: false,
    realVersion: null,
    homesite: "//vote.pollstream.com",
    developmentMode: false,
    debugMode: false,
};

export default appdata;