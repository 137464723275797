import namespace from './namespace';

const
// similar to namespace but this function wont create the empty object if not exist
_namespace = function(name)  {
	if (typeof(name) != "string") {
		return false;
	}

	var names = name.split("."), o = global.PSLIB, i;
	if (names[0] != "PSLIB") {
		return;
	}
	for (i = 1; i < names.length; i ++) {
		if (typeof o[names[i]] == "undefined") {
				return null;
		}
		o = o[names[i]];
	}
	return o;
},
isFunction = function(a) {
	return typeof a == 'function';
},
isObject = function(a) {
	return (a && typeof a == 'object') || isFunction(a);
},
// isEmptyObj(o) returns true if o is an object containing no enumerable members.
isEmptyObj = function(o) {
	var i, v;
	if (isObject(o)) {
		for (i in o) {
			return false;
		}
	}
	return true;
},
// this one check whether all required classes are loaded.
hasAllDependClasses = function(dependClasses) {
	var n;
	for (var i = 0; i < dependClasses.length; i ++) {
		n = _namespace(dependClasses[i]);
		if (n === null || (isEmptyObj(n) && !isFunction(n))) {
			return false;
		}
	}
	return true;
};

let classes = [];

const classLoader = function(curClass, dependClasses, execFunc) {
	let tmpClasses = [],
	added = true;

	// create or access the requested namespace. then determine whether it is empty.
	// return if nothing to do.
	var obj = _namespace(curClass);
	if (obj !== null && (!isEmptyObj(obj) || isFunction(obj))) {
		return;
	}

	// push the new class code to queue. if required classes do not exist,
	// it will stay there and otherwise it will be removed
	classes.push({
		PSLIB: global.PSLIB,
		curClass: curClass,
		dependClasses: dependClasses,
		execFunc: execFunc,
	});

	// go through the queue one by one and execute whatever possible.
	// if any class processed, whole queue will be gone through again
	// since required class may be ready.
	while (added && classes.length > 0) {
		added = false;
		tmpClasses = [];
		for (var i = 0; i < classes.length; i ++) {
			if (hasAllDependClasses(classes[i].dependClasses)) {
				if (classes[i].curClass) {
					namespace(classes[i].curClass);
				}
				classes[i].execFunc.call(global);
				added = true;
			}
			else {
				tmpClasses.push(classes[i]);
			}
		}
		classes = tmpClasses;
	}
};

export default classLoader;