import isString from '../util/isString';
import isArray from '../util/isArray';

// Get HTML element reference
// elem: a string (id of element), an element, or an array of IDs
// return HTML element reference or array of references if found, null if not found.
var get = function(em, win) {
    if (!win) {
        win = global;
    }

    var o,arr,i,D=win.document,N=null,UD="undefined";
    // return if em contains nothing
    if (!em) {
        return N;
    }
    // is em a string? if so, look up the element by ID
    if (isString(em)) {
        return D.getElementById(em);
    }
    // is em an array or nodelist? if so, look up each array element
    // okay. under IE, global object has both item and length property and so we check some properties that
    // for sure wont be in nodelist
    else if (isArray(em) ||
        (typeof em.tagName == UD && typeof em.document == UD && typeof em.item != UD && typeof em.length != UD)) {
        arr = [];
        for (i = 0; i < em.length; i ++) {
            o = get(em[i]);
            if (o) {
                arr.push(o);
            }
        }
        return arr;
    }
    // nothing else? assuming it is an element reference
    else {
        return em;
    }
    return N;
};

export default get;