if (typeof(global.JSON) === "undefined") {
    require('./src/json3');
}

import PSLIBCore from "./src/index";

import PS$ from './src/PS$';
import PS$$ from './src/PS$$';

global.PS$ = global.PS$ || PS$;
global.PS$$ = global.PS$$ || PS$$;

global.PSLIB = global.PSLIB || {};
global.PSLIB._global = global.PSLIB._global || PSLIBCore._global;
global.PSLIB.global = global.PSLIB.global || PSLIBCore.globalsettings;
global.PSLIB.namespace = global.PSLIB.namespace || PSLIBCore.namespace;
global.PSLIB.inherits = global.PSLIB.inherits || PSLIBCore.inherits;
global.PSLIB.object = global.PSLIB.object || PSLIBCore.object;
global.PSLIB.callParentConstructor = global.PSLIB.callParentConstructor || PSLIBCore.callParentConstructor;
global.PSLIB.classLoader = global.PSLIB.classLoader || PSLIBCore.classLoader;
global.PSLIB.scriptEngine = global.PSLIB.scriptEngine || PSLIBCore.scriptEngine;
global.PSLIB.util = global.PSLIB.util || PSLIBCore.util;
global.PSLIB.dom = global.PSLIB.dom || PSLIBCore.dom;
global.PSLIB.attr = global.PSLIB.attr || PSLIBCore.attr;
global.PSLIB.validate = global.PSLIB.validate || PSLIBCore.validate;
global.PSLIB.date = global.PSLIB.date || PSLIBCore.date;
global.PSLIB.event = global.PSLIB.event || PSLIBCore.event;
global.PSLIB.cache = global.PSLIB.cache || PSLIBCore.cache;
global.PSLIB.customEvent = global.PSLIB.customEvent || PSLIBCore.customEvent;
global.PSLIB.namedQueue = global.PSLIB.namedQueue || PSLIBCore.namedQueue;
global.PSLIB.browserUtils = global.PSLIB.browserUtils || PSLIBCore.browserUtils;
global.PSLIB.browser = global.PSLIB.browser || PSLIBCore.browser;
global.PSLIB.loadScript = global.PSLIB.loadScript || PSLIBCore.loadScript;
global.PSLIB.loadScripts = global.PSLIB.loadScripts || PSLIBCore.loadScripts;
global.PSLIB.connection = global.PSLIB.connection || PSLIBCore.connection;
global.PSLIB.animation = global.PSLIB.animation || PSLIBCore.animation;
global.PSLIB.fx = global.PSLIB.fx || PSLIBCore.fx;
global.PSLIB.xsQuery = global.PSLIB.xsQuery || PSLIBCore.xsQuery;
global.PSLIB.widget = global.PSLIB.widget || PSLIBCore.widget;
global.PSLIB.placeholder = global.PSLIB.placeholder || PSLIBCore.placeholder;
global.PSLIB.growTextarea = global.PSLIB.growTextarea || PSLIBCore.growTextarea;
global.PSLIB.growTextareas = global.PSLIB.growTextareas || PSLIBCore.growTextareas;
global.PSLIB.accessibility = global.PSLIB.accessibility || PSLIBCore.accessibility;
global.PSLIB.captionlessInput = global.PSLIB.captionlessInput || PSLIBCore.captionlessInput;
global.PSLIB.copyToClipboard = global.PSLIB.copyToClipboard || PSLIBCore.copyToClipboard;
global.PSLIB.appdata = global.PSLIB.appdata || PSLIBCore.appdata;

export default PSLIBCore;