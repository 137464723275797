/**
 * Escapes emojis
 *
 * Turns \ud83d\udc69 into 👩
 */

const regexp = /\\u([\d\w]{4})/gi;

export default function (str) {
    str = str.replace(regexp, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
    });
    return str;
};