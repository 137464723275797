


const date = {

	// Convert date_code into [from unix timestamp, to unix timestamp] or undefined if error.
	// Returned timestamps are inclusive.
	//    date_code examples:
	//
	//    [-7D:N]  -> Last 7 Days
	//    [-1D:0D) -> Yesterday
	//    [0W:N]   -> This Week
	//    [-1W:0W) -> Last Week
	//    [1W:2W)  -> Next Week
	//    [0M:N]   -> This Month
	//    [-1M:0M) -> Last Month
	//    [-2M:N]  -> 3 Months
	//    [0Y:N]    -> This Year
	//    [-1Y:0Y)  -> Last Year
	//
	//    '[' or ']' : inclusive
	//    '(' or ')' : exclusive
	//    N : Now, H : Hour, D : Day, W : Week, M : Month, Y : Year
    dateCode2UTS : function (dateCode) {

		var format = /^([\[(])([-]?\d*)([HDWMYN]):([-]?\d*)([HDWMYN])([\])])$/,
			parse = format.exec(dateCode),
			fromTS = 0,
			toTS   = 0;
		var calcTS = function (num, unit) {
					var date = new Date();
					var weekStart = 1;      // Monday
					// allign the unit start
					switch (unit) {
						case 'Y': date.setMonth(0);
						case 'M': date.setDate(1);
						case 'W':
						case 'D': date.setHours(0);
						case 'H': date.setMinutes(0);
								  date.setSeconds(0);
						case 'N': date.setMilliseconds(0);
					}
					if (unit == 'W') {
						date.setDate(date.getDate()-date.getDay()+weekStart);
					}
					// apply the num of units
					switch (unit) {
						case 'Y': date.setFullYear(date.getFullYear()+num);
								  break;
						case 'M': date.setMonth(date.getMonth()+num);
								  break;
						case 'W': date.setDate(date.getDate()+num*7);
								  break;
						case 'D': date.setDate(date.getDate()+num);
								  break;
						case 'H': date.setHours(date.getHours()+num);
								  break;
					}
					return date.getTime() / 1000;
			};

		if (parse !== null  &&  parse.length == 7) {
			var from = {inclusive: (parse[1] == '['), num: parseInt(parse[2]), unit: parse[3] },
				to   = {inclusive: (parse[6] == ']'), num: parseInt(parse[4]), unit: parse[5] };

			if ((!isNaN(from.num) || from.unit == 'N') &&  (!isNaN(to.num) || to.unit == 'N')) {
				fromTS = calcTS(from.num, from.unit) + (from.inclusive ? 0 : 1);
				  toTS = calcTS(  to.num,   to.unit) - (  to.inclusive ? 0 : 1);
			}
		}
		return (fromTS && toTS ? [fromTS, toTS] : undefined);
    }
};

export default date;