/**
 * Strips emojis
 *
 * Turns 👩  into \ud83d\udc69
 */

var regexp = new RegExp("([\\uD800-\\uDBFF\\uDC00-\\uDFFF])", "g");

export default function (str) {
    str = str.replace(regexp, function(match, emoji, offset, originalString) {
        return "";
    });

    return str;
};