/**
 * Finds text nodes under the root element (el).
 * It uses createTreeWalker() if available (faster) but with grateful fallback.
 * This method will not return text nodes under elements that would not be
 * rendered, such as STYLE and SCRIPT tags.
 * Test: http://YOURVM/tests/buster/index.php under test-utils.js, testcase getTextNodesUnder
 * @param Object el - root node element
 * @returns array of found text nodes
 */

import getTextNodesUnderWithoutTreeWalker from './getTextNodesUnderWithoutTreeWalker';

export default function (el) {
    var n, all=[], a=[], walk;
    if (document.createTreeWalker) {
        walk= global.document.createTreeWalker(el,NodeFilter.SHOW_TEXT,null,false);
        while(n=walk.nextNode()) {
			if (n.parentNode.tagName != "STYLE" &&
				n.parentNode.tagName != "SCRIPT") {
				a.push(n);
			}
		}
        return a;
    }
    else {
        return getTextNodesUnderWithoutTreeWalker(el);
    }
};