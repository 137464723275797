

// this shouldn't be here, but we don't have that generic "pslib_plugins" file
// and I don't care to create an additional file right now

/*
	this one handles single textarea resizing.
	to use:

		growTextarea(textarea, 2, 10, "direct");

	algo - either copy or direct, this specifies the algorithm to use when doing resize operations
*/

import growTextarea from './growTextarea';

import attr from './attr';
import customEvent from './customEvent';
import event from './event/index';
import dom from './dom/index';

const growTextareas = function(el) {
	// update height to all elements in scope
	var customEventInstance = new customEvent();

	this.resizeEvent = customEventInstance;

	this.resize = function(target) {
		growTextarea.resizeHeight(
			target,
			undefined,
			attr(target, "data-min-rows"),
			attr(target, "data-max-rows"),
			customEventInstance);
	};

	this.init = function() {
		var els = PS$$(".ps-grow-textarea", el),
			i,
			target;
		for (i = 0; i < els.length; i ++) {
			target = els[i];
			this.resize(target);
		}
	};

	// resize textareas in scope
	this.init();

	// bind the events
	event.bind(
		el, "keyup",
		function(e) {
			var target = e.target;
			if (dom.hasClass(target, "ps-grow-textarea")) {
				growTextarea.resizeHeight(
					target,
					e,
					attr(target, "data-min-rows"),
					attr(target, "data-max-rows"),
					customEventInstance);
			}
		}
	);

	event.bind(
		el, "mouseup",
		function(e) {
			var target = e.target;
			if (dom.hasClass(target, "ps-grow-textarea")) {
				growTextarea.resizeHeight(
					target,
					undefined,
					attr(target, "data-min-rows"),
					attr(target, "data-max-rows"),
					customEventInstance);
			}
		}
	);
}

export default growTextareas;