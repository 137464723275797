// merge array and remove duplicated items

import isArray from './isArray';
import uniqueArray from './uniqueArray';

var mergeArray = function (a, b) {
    // for array like but not actual array merging
    // eg. HTMLCollection or Nodelist
    if (!isArray(a) ||
        !isArray(b)
        ) {
        var i, j;
        o:for (j = 0; j < b.length; j ++) {
            for (i = 0; i < a.length; i ++) {
                if (a[i] == b[j]) { continue o; }
            }
            a.push(b[j]);
        }
        return a;
    }
    else {
        return uniqueArray(a.concat(b));
    }
};

export default mergeArray;