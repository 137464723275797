// extract search string into parts
// eg. test "Transit Number":"02146" date_range:"this month"
// there is a busterJS test

export default function(query) {
    var q = query + "",
        displayCriteria = {},
        matches,
        name, value,
        trim = function(str) {return str.replace(/^\s+|\s+$/g, '');};

    while (q.length > 0) {
        // match everything like key value pairs and will be extracted out from search string
        if (matches = q.match(/^(.*?)([A-Za-z0-9\-_\(\)]+|"[^"]*"):("[^"]*"|[^ ]+)(.*?)$/m)) {
            name = matches[2];
            value = matches[3];
            if (name.charAt(0) == "\"") {
                name = name.substr(1, name.length - 2);
            }
            if (value.charAt(0) == "\"") {
                value = value.substr(1, value.length - 2);
            }
            value = trim(value);
            q = trim(matches[1]) + " " + trim(matches[4]);
            displayCriteria[name] = value;
        }
        else {
            // what left should be for simple search
            q = trim(q);
            if (q.length > 0) {
                displayCriteria["text"] = q;
            }
            break; // we are done.
        }
    }
    return displayCriteria;
};