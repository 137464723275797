import htmlSpecialChars from './htmlSpecialChars';
import linkify from './linkify';

export default function (str, doLinkify){
    doLinkify = ((typeof(doLinkify) != "boolean")? false : doLinkify);

    var m = htmlSpecialChars(str);
    m = ((doLinkify)? linkify(m) : m);
    m=m.replace(/\r\n/g,"<br />");
    m=m.replace(/\n/g,"<br />");
    m=m.replace(/\r/g,"<br />");
    m=m.replace(/  /g," &nbsp;");
    m=m.replace(/\t/g," &nbsp; &nbsp;");

    return m;
};