// return intersection of two arrays

var arrayIntersect = function(arr1, arr2) {
    var i, results = [];

    for (i = 0; i < arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) !== -1) {
            results.push(arr1[i]);
        }
    }

    return results;
};

export default arrayIntersect;