import isObject from './isObject';
import isFunction from './isFunction';

export default function (a) {
    var s, k;
    if (isObject(a)) {
        s = "";
        for (k in a) {
            if (s.length > 0) {
                s += ", ";
            }
            if (!isFunction(a[k])) {
                s += k + ":" + a[k];
            }
            else {
                s += k + ":FUNCTION";
            }
        }
        return "{" + s + "}";
    }
    else {
        return a;
    }
};