// Description:
//      determine whether input string looks like HTML code
// Arguments:
//      str - string to be determined
// Return:
//      boolean
//          - true: look like HTML
//          - false: does not look like HTML

export default function (str) {
    var m = new String(str), re;
    re=/<(p|h1|h2|h3|h4|h5|h6|table|td|tr|ul|ol|li|b|i|u|strong|em|strike|super|sup|big|small|body|html|br|hr|font|blockquote|pre|tt|script|object|embed)/i;
    if(re.test(m)) {
        return true;
    }
    re=/(&[a-zA-Z]{2,5};|&#[0-9]{1,5};)/i;
    return re.test(m);
};