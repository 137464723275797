import isDOMElement from './isDOMElement';
import isArray from './isArray';
import isFunction from './isFunction';
import toJson from '../dom/toJson';

/**
* Turns JSON data into query string parameters - jQuery style
*
* E.g. 	Request.param({
*			a: 1,
*			b: {
*				 c: [1,2,3]
*			}
*		})
*	--> a=1&b%5Bc%5D%5B%5D=1&b%5Bc%5D%5B%5D=2&b%5Bc%5D%5B%5D=3
*/
var param = (function() {
	var r20 = /%20/g,
	rbracket = /\[\]$/,
	buildParams = function(prefix, obj, add, attr) {
		if (isDOMElement(obj)) {
			obj = toJson(obj, attr);
		}
		var name, v;
		if (isArray( obj )) {
			// Serialize array item.
			for(var i = 0; i < obj.length; i ++) {
				v = obj[i];
				if (rbracket.test( prefix )) {
					add( prefix, v );
				} else {
					buildParams( prefix + "[" + ( typeof v === "object" ? i : "" ) + "]", v, add, attr );
				}
			}
		}
		else if (typeof(obj) === "object") {
			// Serialize object item.
			for ( name in obj ) {
				buildParams( prefix + "[" + name + "]", obj[ name ], add, attr );
			}
		}
		else {
			// Serialize scalar item.
			add( prefix, obj );
		}
	};

	return function(a, opts) {
		if (typeof opts != "object") {
			opts = {};
		}
		// Sorts the query string parameters alphabetically
		opts.sort = ((typeof(opts.sort) == "boolean") ? opts.sort : false);

		if (isDOMElement(a)) {
			a = toJson(a, opts.attr);
		}

		var prefix, s = [],
		add = function( key, value ) {
			// If value is a function, invoke it and return its value
			try {
				value = isFunction( value ) ? value() : ( value == null ? "" : value );
			} catch(e) {
				value = null;
			}

			s[ s.length ] = encodeURIComponent( key ) + "=" + encodeURIComponent( value );
		};

		if (typeof opts.no_replace_spaces == "undefined") {
			opts.no_replace_spaces = false;
		}
		if (!isArray(a)) {
			var data = [];

			for (var key in a) {
				data.push({key: key, value: a[key]});
			}
			if (opts.sort) {
				data.sort(function(a,b) {
					var A = a.key.toLowerCase(),
					B = b.key.toLowerCase();

					if (A < B){
						return -1;
					}else if (A > B){
						return  1;
					}else{
						return 0;
					}
				});
			}

			for (var i = 0; i < data.length; i++) {
				buildParams(data[i].key, data[i].value, add, opts.attr);
			}
		}
		// Return the resulting serialization

		return (opts.no_replace_spaces ? s.join("&") : s.join("&").replace(r20, "+")); // fix 11764
	};
})();

export default param;