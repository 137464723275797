/**
 * Applies linkify processing to all text nodes of inputText unless the nodeName of the parentNode of the text node is specified in parentNodeNamesToIgnore parameter (defaults to "A")
 * @param html string inputText to linkify
 * @param Object option - contains optional params like parentNodeNamesToIgnore, linkifyW3FollowedByDigit and addTargetAttr
 * @returns html string processed via linkify
 * Unit Test: htmlLinkify under tests/buster/pslib_core/test-utils.js
 */

import dom from '../dom/index';
import inArray from './inArray';
import htmlSpecialChars from './htmlSpecialChars';
import getTextNodesUnder from './getTextNodesUnder';

export default function(inputText, option) {
    var parentNodeNamesToIgnore = (option && option.parentNodeNamesToIgnore?option.parentNodeNamesToIgnore:false) || ["A"],//nodeName 'A' is a link element
    d = global.document.createElement('div'), textNodes, linkifyW3FollowedByDigit = true, addTargetAttr = false, linkifyLinkAttrs = false,
    truncateLink = (typeof option === "undefined"? false : (typeof option.truncateLink !== "undefined" ? option.truncateLink : false)),
    truncateToLength  = (typeof option === "undefined"? false : (typeof option.truncateToLength !== "undefined" ? option.truncateToLength : 0)),

    //checks if the passed in text node should be linkified based on parentNodeNamesToIgnore param (ie, ["DIV","SPAN"])
    shouldLinkifyNode = function(node, parentNodeNamesToIgnore) {
        var parent = node.parentNode;
        while (parent) {
            if (parent.nodeType === 4) {//checking for CDATA even though it is for XML not for HTML documents
                return false;
            }
            if (inArray(parent.nodeName,parentNodeNamesToIgnore)) {
                return false;
            }
            else {
                parent = parent.parentNode;
            }
        }
        return true;
    },
    //linkifies the passed in text node and returns document fragment containing the relevant nodes
    linkifyNode = function(node) {
        // the "?:" allow skiping what in the bracket in final matched result
        var regexp_findMatches, regexp_replace, result, frag, a, att, value;

        //URLs starting with http://, https://, or ftp://
        if (linkifyW3FollowedByDigit) {
            regexp_findMatches = /^([\s\S]*?)(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])([\s\S]*?)$/gim;
            regexp_replace = /(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        }
        else {
            regexp_findMatches = /^([\s\S]*?)(?!.*www\d)(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])([\s\S]*?)$/gim;
            regexp_replace = /(?!.*www\d)(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        }

        // Track how much "new string" has been processed
        var new_totalProcessedLength = 0,
        // Track how much "old string" has been processed
        old_totalProcessedLength = 0,
        processMatch = function (str, start, match, end) {
            old_totalProcessedLength += str.length;

            var str = "";
            str = "<a href=\"" + htmlSpecialChars(match) + "\"";
            if (addTargetAttr) {
                str += " target=\"_blank\"";
            }
            if (linkifyLinkAttrs) {
                for (var k in linkifyLinkAttrs) {
                    if (linkifyLinkAttrs.hasOwnProperty(k)) {
                        str += " " + htmlSpecialChars(k) + "=\"" + htmlSpecialChars(linkifyLinkAttrs[k]) + "\"";
                    }
                }
            }

            if (truncateLink && truncateToLength < match.length) {
                var truncate = 3, index = match.lastIndexOf(' ');

                if (index > 1) {//I have at least 2 whitespaces
                    while(match[truncateToLength-truncate] !== ' ') {
                        truncate--;
                    }
                }

                match = match.slice(0, truncateToLength-truncate);
                var strLeft = match.slice(0, match.length * 0.5);
                var strRight = match.slice(match.length * 0.5, match.length);
                match = strLeft + " ... " + strRight;
            }
            str += ">" + htmlSpecialChars(match) + "</a>";

            // Start and end fragments may contain links as well that weren't matched because they were at the end
            // Recursively process the start/end if it's necessary
            start = (regexp_replace.exec(start) !== null)?  start.replace(regexp_findMatches, processMatch) : htmlSpecialChars(start);
            end = (regexp_replace.exec(end) !== null)? end.replace(regexp_findMatches, processMatch) : htmlSpecialChars(end);

            str = start+str+end;

            new_totalProcessedLength += str.length;

            return str;
        };

        value = node.nodeValue;
        value = value.replace(regexp_findMatches, processMatch);

        if (value != node.nodeValue) {
            // Append and process any unprocessed tail of the "old string" onto the processed parts of the "new string"
            value = value.substring(0, new_totalProcessedLength);
            value = value+htmlSpecialChars(node.nodeValue.substring(old_totalProcessedLength, node.nodeValue.length));

            var div = global.document.createElement("div"), em;
            // cant set html directly in fragement.
            // so, set it to a div and move all children over.
            frag = document.createDocumentFragment();
            div.innerHTML = value;
            while (em = div.firstChild) {
                div.removeChild(em);
                frag.appendChild(em);
            }

            // replace node with frag. only children of frag will be replacing the node, not frag itself.
            node.parentNode.replaceChild(frag, node);
        }

    };

    //process optional arguments
    if (option && typeof option.linkifyW3FollowedByDigit !== 'undefined') {
        linkifyW3FollowedByDigit = option.linkifyW3FollowedByDigit;
    }
    if (option && typeof option.addTargetAttr !== 'undefined') {
        addTargetAttr = option.addTargetAttr;
    }
    if (option && typeof option.linkifyLinkAttrs !== 'undefined') {
        linkifyLinkAttrs = option.linkifyLinkAttrs;
    }

    //construct div node containing our initial input text to linkify
    dom.safeSetHtml(d, inputText);
    textNodes = getTextNodesUnder(d);
    for (var i = 0; i < textNodes.length; i ++) {
        if (shouldLinkifyNode(textNodes[i],parentNodeNamesToIgnore)) {
            linkifyNode(textNodes[i]);
        }
    }

    //return linkified html text
    return d.innerHTML;
};