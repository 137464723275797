// isEmptyObj(o) returns true if o is an object containing no enumerable members.

import isObject from './isObject';

export default function(o) {
    var i, v;
    if (isObject(o)) {
        for (i in o) {
            return false;
        }
    }
    return true;
};